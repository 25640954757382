<template>
    <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
        <div class="content-card">
            <div class="page-header-content">
                <div class="row mb-3">
                    <div class="col-sm mb-2 mb-sm-0">
                        <div class="d-flex gap-2 align-items-center mb-3">
                            <h1 class="page-header-title mb-0">{{ 'تعديل دور المستخدم' }}</h1>
                        </div>
                        <p class="page-header-text">{{ 'يمكنك البدء فى تعديل دور المستخدم من هنا.' }}</p>
                    </div>
                </div>
                <!-- End Row -->
                <div class="step-content">
                    <div>
                        <div class="mt-5">
                            <div class="row justify-content-start">
                                <div class="mb-3 col-md-4 col-6">
                                    <label for="roleName" class="form-label">{{ 'أسم دور المستخدم' }}</label>
                                    <input type="text" placeholder='أسم دور المستخدم' value="مسؤول النظام"
                                        class="form-control form-control-sm" id="roleName" disabled>
                                </div>
                            </div>
                            <div class="row justify-content-start">
                                <div class="mb-3 col-md-12 col-12">
                                    الصلاحيات <span style="color: red;"> * </span>
                                </div>
                            </div>
                            <div class="permissions-page p-3">
                                <b-container>

                                    <!-- User Role Form -->
                                    <b-form>


                                        <b-row>
                                            <b-col md="6" lg="4" v-for="(section, index) in sections" :key="index">
                                                <b-card class="mb-4" :border-variant="section.border"
                                                    :header-bg-variant="section.bg" :header-text-variant="section.text"
                                                    :header="section.header">
                                                    <b-form-checkbox class="mb-2 custom-checkbox"
                                                        v-model="section.permissions.selectAll"
                                                        @change="selectAll(section.key)">
                                                        اختيار الكل
                                                    </b-form-checkbox>
                                                    <div class="mt-2 body-check">
                                                        <b-form-checkbox
                                                            v-for="(permission, pIndex) in section.permissions.list"
                                                            :key="pIndex"
                                                            class="custom-checkbox" v-model="permission.value">
                                                            {{ permission.label }}
                                                        </b-form-checkbox>
                                                    </div>
                                                </b-card>
                                            </b-col>
                                        </b-row>

                                        <!-- Save Button -->
                                        <b-row class="mt-4">
                                            <b-col>
                                                <div class="action-content">

                                                    <div class="mt-5">
                                                        <button class="btn btn-logo" @click="savePermissions">{{
        $t('message.save') }}</button>
                                                    </div>

                                                    <div class="mt-5">
                                                        <a class="back-btn" @click="goBack"><b-icon icon="arrow-left" aria-hidden="true"></b-icon></a>
                                                    </div>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                </b-container>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';



export default {
    props: ['id'],
    components: {
        vSelect
    },
    data() {
        return {
            sections: [
                {
                    key: 'accounts',
                    header: 'الحسابات',
                    border: 'logo-border',
                    bg: 'logo-bg',
                    text: 'white',
                    permissions: {
                        selectAll: false,
                        list: [
                            { label: 'عرض الحسابات', value: false },
                            { label: 'إضافة الحسابات', value: true },
                            { label: 'تعديل الحسابات', value: false },
                            { label: 'تعديل الحسابات', value: false },
                            { label: 'تعديل الحسابات', value: false },
                            { label: 'تعديل الحسابات', value: false },
                            { label: 'تعديل الحسابات', value: false },
                            { label: 'مشاهدة ملف الحسابات', value: true }
                        ]
                    }
                },
                {
                    key: 'subscriptions',
                    header: 'أنواع الاشتراكات',
                    border: 'logo-border',
                    bg: 'logo-bg',
                    text: 'white',
                    permissions: {
                        selectAll: false,
                        list: [
                            { label: 'عرض أنواع الاشتراكات', value: false },
                            { label: 'إضافة أنواع الاشتراكات', value: false },
                            { label: 'تعديل أنواع الاشتراكات', value: false },
                            { label: 'حذف أنواع الاشتراكات', value: false }
                        ]
                    }
                },
                {
                    key: 'packages',
                    header: 'الباقات',
                    border: 'logo-border',
                    bg: 'logo-bg',
                    text: 'white',
                    permissions: {
                        selectAll: false,
                        list: [
                            { label: 'عرض الباقات', value: false },
                            { label: 'إضافة الباقات', value: false },
                            { label: 'تعديل الباقات', value: false }
                        ]
                    }
                }
            ]
        };
    },
    mounted() {

    },
    computed: {
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
        direction() {
            return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
        },

    },
    watch: {

    },
    methods: {

        goBack() {
            window.history.back();
        },
        toggleEditMode() {
            this.enableInput = !this.enableInput;

        },
        handleSubmit() {

        },

        selectAll(sectionKey) {
            const section = this.sections.find(s => s.key === sectionKey);
            const allChecked = section.permissions.selectAll;
            section.permissions.list.forEach(permission => {
                permission.value = allChecked;
            });
        },
        savePermissions() {
            // Save permissions logic
            alert('Permissions saved successfully!');
        },



        // validateField(field) {
        //     if (!this.form[field]) {
        //         this.$set(this.errors, field, this.$t('message.errorHandle'));
        //     }
        // },
        // clearError(field) {
        //     this.$delete(this.errors, field);
        // },


        // sendForm(){
        //     this.$nextTick(() => {
        //     this.$router.push('/' + this.$i18n.locale + '/properties/' + this.associationId);
        //     });
        // }


    },
}
</script>
<style scoped>


.page-header-content {
    padding-right: 20px;
    padding-left: 20px;
}

.page-header-title {
    margin-bottom: .5rem;
}

.page-header-text {
    margin-bottom: .25rem;
    color: #677788;
    font-size: 14px
}

.addUserBtn {
    margin-top: 28px;
    width: -webkit-fill-available;
}


#main-content {
    /* max-height: 1200px; */
    min-height: 1100px;
}

.content {
    padding-top: 20px;
    /* padding-left: 10px; */
    /* padding-right: 10px; */
}

.content-rtl {
    margin-right: 17.2rem;
}

.content-ltr {
    margin-left: 17.2rem;
}

.full-content-rtl {
    margin-right: 90px;
    /* Adjusted for closed sidebar */
}

.full-content-ltr {
    margin-left: 85px;
    /* Adjusted for closed sidebar */
}

.stepTwoContent {
    border: 1px solid #ddd;
    border-radius: .3125rem;
    padding: .6125rem 1rem;
    margin-bottom: 15px;
}

.action-content {
    display: flex;
    justify-content: end;
}

.action-content div {
    margin: 5px;
}

.permissions-page {
    /* background-color: #f8f9fa; */
    /* border-radius: 0.75rem; */
}

b-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
}

.card {
    min-height: 220px !important;
    max-height: 220px !important;
    height: 220px !important;
}

b-card .card-title {
    font-weight: bold;
    color: #333;
}

b-form-checkbox {
    margin-bottom: 0.75rem;
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: #4b7ab8 !important;
}

.border-primary {
    --bs-border-opacity: 1;
    border-color: #4b7ab8 !important;
}

.body-check .custom-checkbox {
    margin-bottom: 5px;
}
.permissions-page .card-body{
    overflow: auto;
    padding-bottom: 0px;
    margin-bottom: 5px;
}

/* Style the scrollbar for webkit browsers (Chrome, Safari, etc.) */
.permissions-page .card-body::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  background-color: #f1f1f1; /* Scrollbar background */
}

.permissions-page .card-body::-webkit-scrollbar-thumb {
  background-color: #15364d; /* Color of the scroll thumb */
  border-radius: 10px; /* Rounded edges of the scroll thumb */
}

.permissions-page .card-body::-webkit-scrollbar-thumb:hover {
  background-color: #15364d; /* Color when hovering over the scroll thumb */
}

/* For Firefox */
.permissions-page .card-body {
  scrollbar-width: thin; /* Make the scrollbar thinner */
  scrollbar-color: #15364d #f1f1f1; /* Thumb color and track color */
}
.border-logo-border{
        border-color: #15364d !important;
}
.bg-logo-bg{
    background-color: #15364d !important;
}


</style>
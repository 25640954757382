<template>
    <div>
      <userForm :createNewUser="parsedUnitId" />
    </div>
  </template>

<script>

import userForm from '../../components/userForm.vue';



export default {
    components: {
        userForm
    },
    data() {
        return {
           
        };
    },
    computed: {
        // parsedUnitId() {
        //     return parseInt(this.id);
        // }
    }
}
</script>
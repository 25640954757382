<template>
    <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
        <div class="content-card" v-if="!this.noData">
            <div class="page-header-content">
                <div class="row mb-3">
                    <div class="col-sm mb-2 mb-sm-0">
                        <div class="d-flex gap-2 align-items-center justify-content-between mb-3">

                            <div>
                                <h1 v-if="isRTL" class="page-header-title mb-0"><b-icon class="title-icon"
                                        icon="chevron-right" aria-hidden="true"></b-icon>{{ 'بيانات الوحدة العقارية' + ' ' + this.id }}
                                </h1>
                                <h1 v-if="!isRTL" class="page-header-title mb-0"><b-icon class="title-icon"
                                        icon="chevron-right" aria-hidden="true"></b-icon>{{ 'بيانات الوحدة العقارية' + ' ' + this.id }}
                                </h1>
                            </div>
                            <b-button variant="logo" @click="handleEditClick()">
                                <b-icon icon="box-arrow-in-down-left"></b-icon> تعديل البيانات
                            </b-button>
                            <!-- <a class="edit-btn" @click="goBack"><b-icon icon="box-arrow-in-down-left" aria-hidden="true"></b-icon></a> -->

                        </div>
                    </div>
                </div>
                <h5 class="mb-0 section-header">{{ $t('message.basicInformationAboutRealEstateUnit') }}</h5>
                <div class="section-content mt-4">
                    <div class="row">
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.associationName') }}</h5>
                                <p class="value">{{ form.selectedAssociationId.label }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.propertyNumber') }}</h5>
                                <p class="value">{{ form.propertyNumberSelect }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ 'رقم الوحدة العقارية' }}</h5>
                                <p class="value">{{ form.unitNumber }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ 'وصف الوحدة العقارية' }}</h5>
                                <p class="value">{{ form.descriptionUnit }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.area') }}</h5>
                                <p class="value">{{ form.space }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ 'كود الوحدة' }}</h5>
                                <p class="value">{{ form.unitCode }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ 'رقم الطابق' }}</h5>
                                <p class="value">{{ form.FloorNumber }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ 'عدد الحمامات' }}</h5>
                                <p class="value">{{ form.bathroomsNumber }}</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="info-box">
                                <h5 class="label">{{ 'عدد غرف النوم' }}</h5>
                                <p class="value">{{ form.bedroomsNumber }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.status') }}</h5>
                                <span :class="form.status === 'نشط' ? 'badge bg-success' : 'badge bg-danger'">
                                    <p class="value">{{ form.status }}</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <h5 class="mt-4 mb-0 section-header">{{ 'الموقع الجرافي' }}</h5>
                <div class="section-content mt-4">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.northernBoundary') }}</h5>
                                <p class="value">{{ form.northBoundary }}</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.southernBoundary') }}</h5>
                                <p class="value">{{ form.southBoundary }}</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.eastBoundary') }}</h5>
                                <p class="value">{{ form.eastBoundary }}</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.westBoundary') }}</h5>
                                <p class="value">{{ form.westBoundary }}</p>
                            </div>
                        </div>
                    </div>
                </div>



                <h5 class="mt-4 mb-0 section-header">{{ $t('message.ownersManagement') }}</h5>
                <div v-if="this.owners.length > 0" class="row mt-4">
                    <div v-for="owner in owners" :key="owner.id" class="col-md-4 mb-3">
                        <div class="attachment-item p-2"
                            style="border: 1px solid #F1F1F2; border-radius: 10px;min-height:66px;max-height: 66px; position: relative;">
                            <div class="d-flex align-items-center" style="padding: 10px;">
                                
                                <img class="icon-user" alt="Attachment Preview" :src="require(`../../assets/edarat-user.png`)" />

                                <div style="width: max-content;">
                                    <a class="attachment-link" style="font-size: 14px; font-weight: bold;">
                                        {{ owner.name }}
                                    </a>
                                    <p class="text-muted mb-0" style="font-size: 12px;">
                                        نسبة التملك : {{ owner.percentage }}%
                                    </p>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>



                <h5 v-if="this.files.length > 0" class="mt-4 mb-0 section-header">{{ $t('message.attachments') }}</h5>
                <div v-if="this.files.length > 0" class="row mt-4">
                    <div v-for="file in files" :key="file.id" class="col-md-4 mb-3">
                        <div class="attachment-item p-2"
                            style="border: 1px solid #F1F1F2; border-radius: 10px;min-height:66px;max-height: 66px; position: relative;">
                            <div class="d-flex align-items-center" style="padding: 10px;"
                                v-if="file && file.mime_type && file.mime_type.includes('image')">

                                <img :src="file.url" alt="Attachment Preview" class="attachment-icon"
                                    @click="openInNewTab(file.url, 'image')" />

                                <div style="width: max-content;">
                                    <a :href="file.url" target="_blank" class="attachment-link" v-b-tooltip.hover
                                        :title="file.file_name">
                                        {{ truncateFileName(file.file_name) }}
                                    </a>
                                    <p class="file-size text-muted mb-0" style="font-size: 12px;">
                                        {{ formatFileSize(file.size) }}
                                    </p>
                                </div>
                                <div>
                                    <b-icon class="download-icon" @click="downloadFile(file.url, file.file_name)"
                                        icon="download" aria-hidden="true"></b-icon>
                                </div>
                            </div>
                            <div class="d-flex align-items-center" v-else>
                                <img class="attachment-icon"
                                    :src="require(`../../assets/${getIconNameForFileType(file.mime_type)}`)"
                                    alt="File Icon" @click="openInNewTab(file.url, file.mime_type)" />
                                <div style="width: max-content;">
                                    <a :href="file.url" target="_blank" class="attachment-link" v-b-tooltip.hover
                                        :title="file.file_name">
                                        {{ truncateFileName(file.file_name) }}
                                    </a>
                                    <p class="file-size text-muted mb-0" style="font-size: 12px;">
                                        {{ formatFileSize(file.size) }}
                                    </p>
                                </div>
                                <div>
                                    <b-icon class="download-icon" @click="downloadFile(file.url, file.file_name)"
                                        icon="download" aria-hidden="true"></b-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <div v-else>
            <!-- <div class="content-card">
                <div class="not-found">
                    <img class="" style="width: 400px; height: 300px;" :src="require(`../../assets/404-Error-Page.png`)" />
                    <h1> {{ 'خطأ 404' }} </h1>
                    <h4> {{ 'الصفحة غير موجودة' }} </h4>
                    <h5> {{ 'لم يتم العثور على عقار برقم التعريف: ' + this.id }} </h5>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>

import axiosInstance from '../../axios-config';



export default {
    name: 'addAssociations',
    props: ['id'],

    data() {
        return {
            ownershipPercentages: [],
            form: {
                unitNumber: '',
                descriptionUnit: '',
                space: '',
                legalOwnership: [],
                selectedAssociationId: {},
                propertyNumberSelect: '',
                unitCode: '',
                // ownerName1: '',
                bathroomsNumber: '',
                FloorNumber: '',
                bedroomsNumber: '',
                // ownershipercentage: '',
                // ownedSpace: '',
                northBoundary: '',
                southBoundary: '',
                eastBoundary: '',
                westBoundary: '',
                status: '',

            },
            files: [],
            selectedAssociationManagement: {},
            noData: false,
            managers: [],
            associations: [],
            properties: [],
            fields: [
                {
                    key: 'owner',
                    label: 'اسم المالك',
                    // textAlign: 'center'
                    thStyle: { width: '50%' },
                    tdStyle: { width: '50%'},
                },
                {
                    key: 'percentage',
                    label: 'نسبة التملك',
                    thStyle: { width: '50%'},
                    tdStyle: { width: '50%'},
                },
            ],
            isBusy: false,
            filter: "",
            items: [],
            owners: [],

        }
    },
    computed: {
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
        filteredItems() {
            if (!this.filter) return this.form.legalOwnership; // إذا كان الفلتر فارغًا، اعرض جميع العناصر
            return this.form.legalOwnership.filter(item => {
                // التصفية بناءً على الاسم
                return item.label.toLowerCase().includes(this.filter.toLowerCase());
            });
        }
    },
    created() {
        this.getusersRole();
        this.getAssociationsId();
    },
    methods: {
        customFilter(item, filter) {

            if (!filter) return true;

            const searchTerm = filter.toLowerCase();
            const ownerData = this.form.legalOwnership.find(
                (ownership) => `${ownership.value}` === `${item.owner}`
            );

            const ownerLabel = ownerData ? ownerData.label : '';
            const ownerPercentage = this.ownershipPercentages.find(
                (ownership) => ownership.owner === item.owner
            )?.percentage || '';

            const ownerLabelMatch = ownerLabel.toLowerCase().includes(searchTerm);
            const ownerPercentageMatch = ownerPercentage.toString().includes(searchTerm);


            return ownerLabelMatch || ownerPercentageMatch;
        },










        async getAssociationsId() {
            try {
                const response = await axiosInstance.get('associations');



                // تخزين البيانات الراجعة في rolesList
                this.associations = response.data.data.map(association => ({
                    label: association.name, // وضع الاسم كاسم العرض
                    value: association.id // استخدام المعرف كالقيمة
                }));

                if (this.associations.length !== 0) {
                    this.getData();
                }



            } catch (error) {
                console.error('error:', error);
                this.$izitoast.error({
                    title: 'Error',
                    message: 'An error occurred while trying to get associations id',
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        async getData() {
            this.isBusy = true;
            try {
                let url = 'units';

                // إذا كان id موجودًا، نعدل المسار
                if (this.id) {
                    url += `/${this.id}`;
                }
                const response = await axiosInstance.get(url);

                // Search for the association name based on association_id
                const association = this.associations.find(
                    assoc => assoc.value === response.data.data.property.association_id
                );

                this.form.selectedAssociationId = association ? { label: association.label, value: association.value } : { label: '', value: '' };

                if (this.form.selectedAssociationId.value) {

                    // this.getPropertiesId(this.form.selectedAssociationId.value);
                }

                this.form.propertyNumberSelect = response.data.data.property_id;
                this.form.unitNumber = response.data.data.number;
                this.form.descriptionUnit = response.data.data.description;
                this.form.space = response.data.data.area;
                this.form.unitCode = response.data.data.code;
                this.form.FloorNumber = response.data.data.floor;
                this.form.bathroomsNumber = response.data.data.bathrooms;
                this.form.bedroomsNumber = response.data.data.rooms;
                this.form.northBoundary = response.data.data.northern_border;
                this.form.southBoundary = response.data.data.southern_border;
                this.form.eastBoundary = response.data.data.eastern_border;
                this.form.westBoundary = response.data.data.western_border;
                // Extract owners' data
                this.form.legalOwnership = response.data.data.owners.map(owner => ({
                    label: owner.name,  // وضع الاسم كاسم العرض
                    value: owner.id     // استخدام المعرف كالقيمة
                }));

                // Extract ownership percentages
                this.ownershipPercentages = response.data.data.owners.map(owner => ({
                    owner: owner.id,
                    percentage: owner.percentage // يجب أن يكون هناك حقل `percentage` لكل مالك
                }));
                this.owners = response.data.data.owners;
                this.form.status = response.data.data.status === 'active' ? 'نشط' : 'غير نشط';
                this.files = response.data.data.files;
                this.isBusy = false;

                // this.$izitoast.success({
                //     title: 'Success',
                //     message: 'successfully get data',
                //     position: 'bottomLeft',
                //     timeout: 5000, // يمكنك تخصيص مدة العرض
                // });


            } catch (error) {
                this.noData = true;
                const errorMessage = error.response?.data?.message || 'An error occurred while trying to get data';
                this.$router.push('/' + this.$i18n.locale + '/NotFound');
                this.isBusy = false
                console.error('error:', error);
                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        async getusersRole() {
            try {
                const response = await axiosInstance.get('users', {
                    params: {
                        role: 'user',
                    }
                });



                // تخزين البيانات الراجعة في rolesList
                this.managers = response.data.data.map(role => ({
                    label: role.name, // وضع الاسم كاسم العرض
                    value: role.id // استخدام المعرف كالقيمة
                }));



            } catch (error) {
                console.error('error:', error);
                this.$izitoast.error({
                    title: 'Error',
                    message: 'An error occurred while trying to get roles',
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        formatFileSize(size) {
            const kb = size / 1024;
            if (kb < 1024) return `${kb.toFixed(2)} KB`;
            return `${(kb / 1024).toFixed(2)} MB`;
        },
        getIconNameForFileType(mimeType) {
            const type = mimeType.split('/')[1];

            if (type === 'pdf') {
                return 'pdf.png'; // أيقونة ملفات PDF
            } else if (type.includes('wordprocessingml.document')) {
                return 'doc.png'; // أيقونة ملفات Word
            } else if (type.includes('spreadsheetml.sheet')) {
                return 'xls.png'; // أيقونة ملفات Excel
            } else if (type.includes('video')) {
                return 'mp4.png'; // أيقونة ملفات الفيديو
            } else if (type.includes('audio')) {
                return 'mp3.png'; // أيقونة ملفات الصوت
            } else if (type === 'css') {
                return 'css.png'; // أيقونة ملفات CSS
            } else if (type === 'csv') {
                return 'csv.png'; // أيقونة ملفات CSV
            } else if (type === 'dbf') {
                return 'dbf.png'; // أيقونة ملفات DBF
            } else if (type === 'dwg') {
                return 'dwg.png'; // أيقونة ملفات DWG
            } else if (type === 'exe') {
                return 'exe.png'; // أيقونة ملفات EXE
            } else if (type === 'fla') {
                return 'fla.png'; // أيقونة ملفات FLA
            } else if (type === 'html') {
                return 'html.png'; // أيقونة ملفات HTML
            } else if (type === 'iso') {
                return 'iso.png'; // أيقونة ملفات ISO
            } else if (type.includes('javascript')) {
                return 'javascript.png'; // أيقونة ملفات JavaScript
            } else if (type === 'jpeg' || type === 'jpg') {
                return 'jpg.png'; // أيقونة ملفات JPEG
            } else if (type === 'json') {
                return 'json.png'; // أيقونة ملفات JSON
            } else if (type === 'png') {
                return 'png.png'; // أيقونة ملفات PNG
            } else if (type === 'ppt' || type.includes('presentation')) {
                return 'ppt.png'; // أيقونة ملفات PowerPoint
            } else if (type === 'psd') {
                return 'psd.png'; // أيقونة ملفات Photoshop
            } else if (type === 'rtf') {
                return 'rtf.png'; // أيقونة ملفات RTF
            } else if (type === 'svg') {
                return 'svg.png'; // أيقونة ملفات SVG
            } else if (type === 'txt') {
                return 'txt.png'; // أيقونة ملفات TXT
            } else if (type === 'xls' || type.includes('excel')) {
                return 'xls.png'; // أيقونة ملفات Excel
            } else if (type === 'xml') {
                return 'xml.png'; // أيقونة ملفات XML
            } else if (type === 'zip') {
                return 'zip.png'; // أيقونة ملفات ZIP
            }
        },
        openInNewTab(url, type) {
            

            window.open(url, '_blank');

        },
        handleEditClick() {
            this.$nextTick(() => {
                this.$router.push('/' + this.$i18n.locale + '/units/edit/' + this.id);
            });
        },
        updateLatLngFromMarker(latLng) {
            this.lat = latLng.lat;
            this.lng = latLng.lng;
            this.markerPosition = [this.lat, this.lng];
        },
        updateLatLngFromMap(center) {
            this.center = center;
        },
        // updateMarkerPosition() {
        //     this.markerPosition = [parseFloat(this.lat), parseFloat(this.lng)];
        //     this.center = [...this.markerPosition]; // لتحديث المركز عند تحديث marker
        // },
        truncateFileName(fileName) {
            const maxLength = 28; // يمكنك تعديل الحد الأقصى للطول حسب الحاجة
            if (fileName.length > maxLength) {
                return fileName.substring(0, maxLength) + '...';
            }
            return fileName;
        },
        downloadFile(url, fileName) {
            // إنشاء طلب لجلب الملف
            fetch(url)
                .then(response => response.blob()) // تحويل الاستجابة إلى Blob
                .then(blob => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob); // إنشاء رابط URL مؤقت للملف
                    link.download = fileName; // تعيين اسم الملف للتنزيل
                    document.body.appendChild(link); // إضافة الرابط إلى DOM
                    link.click(); // النقر على الرابط لتنزيل الملف
                    document.body.removeChild(link); // إزالة الرابط بعد التنزيل
                    // عرض رسالة النجاح
                    this.$izitoast.success({
                        title: 'Success',
                        message: 'File downloaded successfully',
                        position: 'bottomLeft',
                        timeout: 5000,
                    });
                })
                .catch(error => {
                    // عرض رسالة الخطأ
                    this.$izitoast.error({
                        title: 'Error',
                        message: 'An error occurred while trying to download the file',
                        position: 'bottomLeft',
                        timeout: 5000,
                    });
                    console.error('Error downloading the file:', error);
                });
        }
    }
}


</script>

<style scoped>
.content-card {
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .0);
    margin-top: 80px;
    padding: 15px;
    background-color: #fff;
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: 0.75rem;
    margin-right: 15px;
    margin-left: 15px;
}

.page-header-content {
    padding-right: 20px;
    padding-left: 20px;
}

.page-header-title {
    margin-bottom: .5rem;
}

.title-icon {
    font-size: 15px;
    margin-left: 5px
}

.section-header {
    color: #007AFF;
}

.section-content {
    border: 1px solid #F1F1F1;
    padding: 40px;
    border-radius: 10px;
}

.map-section {
    padding-right: 12px;
    padding-left: 12px;
}

p {
    margin-bottom: 0px !important;
}

.space {
    margin-bottom: 50px;
}

.attachment-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */

}

.attachment-item:hover {
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */
}

.attachment-link {
    color: #161313;
    text-decoration: none;
    width: 30%;
}

.attachment-link:hover {
    text-decoration: none
}

.attachment-icon {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    cursor: pointer;
}
.icon-user{
    margin-left: 10px;
    width: 45px;
    height: 45px;
}

.download-icon {
    position: absolute;
    left: 11px;
    top: 24px;
    color: #FF0000;
    font-size: medium;
    cursor: pointer;
}

.bg-light {
    background-color: rgba(249, 250, 252, 1) !important;
    min-height: 100%;
}

.jadda-pattern-dark {
    background-image: url(../../assets/pattren-dark.svg);
    background-size: 150px;
}

.card-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
    /* color: var(--bs-card-cap-color); */
    /* background-color: var(--bs-card-cap-bg); */
    border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;
}

.card-header:first-child {
    border-radius: 0.6875rem 0.6875rem 0 0;
}
.not-found{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
</style>
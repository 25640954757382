<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <!-- <div class="container-fluid"> -->
    <div class="container">
      <a class="navbar-brand" :class="[{ 'navbar-brand-rtl': isRTL, 'navbar-brand-ltr': !isRTL }]" href="#">
        <img :src="require('@/assets/logo-edarat365.png')" width="110" height="40" alt="Logo"
          class="d-inline-block align-text-top">
      </a>
      <div class="navbar-nav-wrap-content-start d-flex align-items-center"
        :class="[{ 'navbar-nav-wrap-content-start-rtl': isRTL, 'navbar-nav-wrap-content-start-ltr': !isRTL }]">
        <h5 class="page-header-title mb-0 me-3">
          <span v-if="user" :class="[{ 'navbar-role-rtl': isRTL, 'navbar-role-ltr': !isRTL }]" style="width:max-content;"> {{ $t('message.welcome') }},  {{ user.name }}</span>
          <span v-if="user" class="badge bg-soft-success text-success header__user__role__name">{{ user.role }}</span>
          <span class="header__user__last__date d-block">{{ $t('message.lastEntry') }} السبت، 14/10/2023 في 1:26:27
            م</span>
        </h5>
      </div>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown"
        :class="[{ ' navbar-collapse-rtl': isRTL, ' navbar-collapse-ltr': !isRTL }]">
        <!-- <div class="dropdpwn-data"> -->
          <div class="date-section">
            <div class="navbar-text header-date">
              <lord-icon target=".header__date" src="https://cdn.lordicon.com/inlmkmqf.json" trigger="hover"
                style="width:15px;height:15px">
              </lord-icon>
              <span>{{ this.currentDateTime }}</span>
            </div>
          </div>

          <li class="header-fast-links nav-item d-none d-sm-inline-block me-2">
            <div class="dropdown">
              <button type="button" class="btn btn-soft-info btn-sm quick-action" id="navbarAppsDropdown"
                data-bs-toggle="dropdown" aria-expanded="false" data-bs-dropdown-animation="">
                <lord-icon target=".btn" src="https://cdn.lordicon.com/zopinftn.json" trigger="hover"
                  style="width:15px;height:15px"> </lord-icon>
                {{ $t('message.FastAction') }}
              </button>

              <div
                class="dropdown-menu dropdown-menu-start dropdown-card navbar-dropdown-menu navbar-dropdown-menu-borderless"
                aria-labelledby="navbarAppsDropdown"
                style="width: 25rem;opacity: 1;transform: translateY(10px) translateY(-10px);transition: transform 300ms ease 0s, opacity 300ms ease 0s;">
                <div class="card card-fast-link" :class="[{ 'card-fast-link-rtl': isRTL, 'card-fast-link-ltr': !isRTL }]">
                  <div class="card-header">
                    <h5 class="card-title">{{ $t('message.setQuickActions') }}</h5>
                  </div>
          <li>
            <hr class="dropdown-divider">
          </li>
          <div class="card-body card-body-height custom-scroll">
            <a class="dropdown-item" href="#">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 text-truncate">
                  <h6 class="mb-0">{{ $t('message.createAccount') }} <span
                      class="badge bg-soft-primary text-primary ms-1">مجرد
                      نص</span></h6>
                  <p class="card-text text-body">مجرد نص هنا لوصف الاجراء</p>
                </div>
              </div>
            </a>

            <a class="dropdown-item" href="#">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 text-truncate">
                  <h6 class="mb-0">{{ $t('message.createAdminAccount') }}</h6>
                  <p class="card-text text-body">مجرد نص هنا لوصف الاجراء</p>
                </div>
              </div>
            </a>

            <a class="dropdown-item" href="#">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 text-truncate">
                  <h6 class="mb-0">{{ $t('message.createInvoice') }}</h6>
                  <p class="card-text text-body">مجرد نص هنا لوصف الاجراء</p>
                </div>
              </div>
            </a>

            <a class="dropdown-item" href="#">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 text-truncate">
                  <h6 class="mb-0">{{ $t('message.billingManagement') }} <span
                      class="badge bg-soft-danger text-danger ms-1">اجراء
                      مطلوب</span></h6>
                  <p class="card-text text-body">مجرد نص هنا لوصف الاجراء</p>
                </div>
              </div>
            </a>

          </div>

        </div>
      </div>
      </div>
          </li>

          <div class="search-container">
            <div class="input-group search-input" style="align-items: center">
              <!-- <span class="input-group-text"> -->
              <div class="search-icon" :class="[{ 'search-icon-rtl': isRTL, 'search-icon-ltr': !isRTL }]">
                <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="hover"
                  style="width:20px;height:20px"></lord-icon>
              </div>
              <!-- </span> -->
              <input style="box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.075) !important;border-radius:6px;" type="text" class="form-control" :class="[{ 'search-input-rtl': isRTL, 'search-input-ltr': !isRTL }]"
                :placeholder="$t('message.searchin')" v-model="searchQuery" @input="handleInput" />
              <div v-if="searchQuery" class="position-absolute top-50 translate-middle-y delete-icon"
                :class="[{ 'delete-icon-rtl': isRTL, 'delete-icon-ltr': !isRTL }]" @click="clearSearch">
                <lord-icon src="https://cdn.lordicon.com/jmkrnisz.json" trigger="hover"
                  style="width:20px;height:20px"></lord-icon>
              </div>
            </div>
          </div>

          <ul class="navbar-nav side-header-nav">
            <li class="nav-item " style="margin-right: .5rem;">
              <div class="dropdown">
                <button type="button" class="btn btn-ghost-secondary btn-icon rounded-circle lang-btn"
                  id="selectLanguageDropdown" data-bs-toggle="dropdown" aria-expanded="false" data-bs-dropdown-animation="">
                  <img class="avatar avatar-xss avatar-circle"
                    :src="currentLanguage === 'ar' ? require('../assets/ar.svg') : require('../assets/us.svg')"
                    :alt="currentLanguage === 'ar' ? 'Saudi Arabia flag' : 'US flag'">
                </button>
                <div class="dropdown-menu language-content navbar-dropdown-menu-borderless"
                  :class="[{ 'language-content-rtl': isRTL, 'language-content-ltr': !isRTL }]"
                  aria-labelledby="selectLanguageDropdown">
                  <span class="dropdown-header">{{ $t('message.selectLang') }}</span>
                  <a @click="switchLanguage('ar')" class="dropdown-item" :class="[{ 'active': isRTL }]" href="javascript:;">
                    <img class="avatar avatar-xss avatar-circle me-2" src="../assets/ar.svg" alt="flag">
                    <span class="text-truncate" title="arabic">العربية</span>
                  </a>
                  <a @click="switchLanguage('en')" class="dropdown-item" :class="[{ 'active': !isRTL }]" href="javascript:;">
                    <img class="avatar avatar-xss avatar-circle me-2" src="../assets/us.svg" alt="Flag">
                    <span class="text-truncate" title="English">English (US)</span>
                  </a>
                </div>
              </div>
            </li>


            <li class="nav-item d-none d-md-inline-block m-0">
              <div class="dropdown">
                <button type="button" class="btn btn-ghost-secondary btn-icon rounded-circle" id="navbarNotificationsDropdown"
                  aria-expanded="false" data-bs-auto-close="outside" data-bs-dropdown-animation="">
                  <lord-icon class="sidebar__icon" src="https://cdn.lordicon.com/tdrtiskw.json" trigger="hover"
                    colors="primary:#121331,secondary:#edd100" style="width:25px;height:25px"></lord-icon>
                  <span class="btn-status btn-sm-status btn-status-danger"></span>
                </button>
              </div>
            </li>


            <li class="nav-item d-none d-md-inline-block">
              <!-- Account -->
              <div class="dropdown">
                <a class="navbar-dropdown-account-wrapper" href="javascript:;" id="accountNavbarDropdown"
                  data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-dropdown-animation="">
                  <div class="avatar avatar-sm avatar-circle">
                    <img class="avatar-img" src='../assets/img6.jpg' alt="Image Description">
                    <span class="avatar-status avatar-sm-status avatar-status-success"></span>
                  </div>
                </a>

                <div
                  class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                  :class="[{ 'dropdown-menu-end-rtl': isRTL, 'dropdown-menu-end-ltr': !isRTL }]"
                  aria-labelledby="accountNavbarDropdown"
                  style="width: 16rem;opacity: 1;transform: translateY(10px) translateY(-10px);transition: transform 300ms ease 0s, opacity 300ms ease 0s;">
                  <div class="dropdown-item-text">
                    <div class="d-flex align-items-center">
                      <div class="avatar avatar-sm avatar-circle">
                        <img class="avatar-img" src='../assets/img6.jpg' alt="Image Description">
                      </div>
                      <div class="flex-grow-1 ms-3">
                        <h5 class="mb-0">{{ user.name }}</h5>
                        <p class="card-text text-body">{{ user.role }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="dropdown-divider"></div>

                  <!-- Dropdown -->
                  <div class="dropdown">
                    <a class="navbar-dropdown-submenu-item dropdown-item dropdown-toggle"
                      :class="[{ 'dropdown-toggle-rtl': isRTL, 'dropdown-toggle-ltr': !isRTL }]" href="javascript:;"
                      id="navSubmenuPagesAccountDropdown1" data-bs-toggle="dropdown" aria-expanded="false">{{
              $t('message.status') }}</a>

                    <div
                      class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-sub-menu"
                      :class="[{ 'dropdown-menu-end-rtl navbar-dropdown-sub-menu-rtl': isRTL, 'dropdown-menu-end-ltr navbar-dropdown-sub-menu-ltr': !isRTL }]"
                      aria-labelledby="navSubmenuPagesAccountDropdown1">
                      <a class="dropdown-item" href="#">
                        <span class="legend-indicator bg-success me-1"></span> متصل
                      </a>
                      <a class="dropdown-item" href="#">
                        <span class="legend-indicator bg-danger me-1"></span> مشغول
                      </a>
                      <a class="dropdown-item" href="#">
                        <span class="legend-indicator bg-warning me-1"></span> بعيد
                      </a>
                    </div>
                  </div>
                  <!-- End Dropdown -->

                  <a class="dropdown-item" href="#">{{ $t('message.profile') }}</a>
                  <a class="dropdown-item" href="#">{{ $t('message.setting') }}</a>

                  <div class="dropdown-divider"></div>


                  <a v-if="isAuthenticated" @click="logout" class="dropdown-item" >{{ $t('message.logout') }}</a>
                </div>
              </div>
              <!-- End Account -->
            </li>

          </ul>
        <!-- </div> -->
    </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Navbar',
  data() {
    return {
      currentDateTime: '',
      searchQuery: "",
      currentLanguage: this.$i18n.locale,
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale === 'ar';
    },
    ...mapGetters(['isAuthenticated', 'user'])

  },
  mounted() {
    this.updateDateTime();
    setInterval(this.updateDateTime, 1000);
  },
  methods: {
    ...mapActions(['logout']),
    async logout() {
      try {
        await this.$store.dispatch('logout'); // حذف بيانات المستخدم من Vuex

        this.$izitoast.success({
          title: 'success',
          message: 'You have successfully logged out',
          position: 'bottomLeft',
          timeout: 5000,
        });
                
        // setTimeout(() => {
          this.$router.push('/' + this.$i18n.locale + '/login');
        // }, 2000);
      } catch (error) {
        console.error('Error logging out:', error);
      }
    },
    updateDateTime() {
      const now = new Date();
      const lang = this.$i18n.locale;
      
      // Define custom options to avoid unsupported properties
      const options = lang === 'ar' 
        ? { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' } 
        : { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', localeMatcher: 'best fit' };

      this.currentDateTime = now.toLocaleString(lang, options);
    },
    clearSearch() {
      this.searchQuery = "";
    },
    handleInput() {
      // Add any additional input handling logic here if necessary
    },
    switchLanguage(language) {
      this.currentLanguage = language;
      this.$i18n.locale = language;
      const currentPath = this.$route.path;
      const newPath = currentPath.replace(/^\/(ar|en)/, `/${language}`);
      this.$router.push(newPath);
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('navbar-vertical-aside-mini-mode');
      body.classList.add('no-transition');
      setTimeout(() => {
        body.classList.remove('no-transition');
        // إعادة تحميل الصفحة
        window.location.reload();
      }, 100);
    },
  }
}
</script>

<style scoped>
.navbar {
  /* background-color: #f8f9fa; */
}

.navbar-collapse-rtl {
  margin-left: 0px;
  margin-right: 93px;
}

.navbar-collapse-ltr {
  margin-right: 0px;
  margin-left: 93px;
}

h5 {
  font-size: .875rem;
  color: #1E2022;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #1e2022;
}

.navbar-brand {
  width: 100%;
  min-width: 4rem;
  max-width: 4rem;
}


.navbar-nav-wrap-content-start-rtl {
  padding-right: 2rem;
  padding-left: 0;
}

.navbar-nav-wrap-content-start-ltr {
  padding-left: 2rem;
  padding-right: 0;
}

.me-3 {
  margin-left: 1rem !important;
}

.header__user__role__name,
.header__user__last__date {
  font-size: 9px;
  font-weight: 400;
}

.badge {
  line-height: normal;
}

.bg-soft-success {
  background-color: rgba(0, 201, 167, .1) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(0, 201, 167, var(--bs-text-opacity)) !important;
}

.dropdown-menu {
  background-color: #ffffff;
  border: 1px solid #ddd;
}

.dropdown-item {
  color: #000;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-item.text-danger {
  color: #dc3545;
}

.header-date {
  background: #e1f2f5;
  color: #09a5be;
  border-radius: 6px;
  font-size: 12px;
  /* font-size: 11px; */
  padding: 0.4rem 0.3rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 5px;
  min-width: 250px;
  justify-content: center;
}

.date-section {
  /* display: flex; */
  /* flex-direction: row; */
}

.header-fast-links {
  margin-right: .5rem;
  margin-left: .5rem;
  width: max-content;

}

.navbar-expand-lg .dropdown-menu {
  margin-top: .75rem;
  border-top-width: 0;
  border-radius: .5rem;

}

.quick-action {
  /* color: #fff; */
  /* background-color: #09a5be; */
  border-color: transparent;
  padding: 5px 14px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: rgb(9, 165, 190);
  background: rgb(225, 242, 245);
  border-radius: 6px;
}

.quick-action:hover {
  color: #fff;
  background-color: #09a5be;
}

.card-fast-link {
  box-shadow: none;
  border: none;
}

.card-fast-link-rtl {
  text-align: right;
}

.card-fast-link-ltr {
  text-align: left
}

.card-header {
  /* padding-top: 1rem; */
  /* padding-bottom: 1rem; */
  /* padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x); */
  margin-bottom: 0;
  background-color: transparent;
}

.card-title {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
  opacity: 1;
}

.card-body-height {
  height: 13.25rem;
  overflow: hidden;
  overflow-y: auto;
}

.custom-scroll::-webkit-scrollbar {
  width: 7px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: #d7dce3;
  border-radius: 0px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dropdown-item:not(:last-child) {
  margin-bottom: .25rem;
}

.dropdown-menu .dropdown-item {
  font-size: .8125rem;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #1e2022;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.mb-0 {
  margin-bottom: 0 !important;
}

h6 {
  font-size: .765625rem;
  margin-top: 0;
  font-weight: 600;
  line-height: 1.2;
  color: #1e2022;
}

.ms-1 {
  margin-right: .25rem !important;
}

.bg-soft-primary {
  background-color: rgba(55, 125, 255, .1) !important;
}

.text-primary {
  /* color: rgba(55, 125, 255), 1 !important; */
  color: rgba(55, 125, 255), 1 !important;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  /* font-size: 0.75em; */
  /* font-weight: 700; */
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3125rem;
  line-height: normal;
  font-size: 9px;
  font-weight: 400;
}

.card-text:last-child {
  margin-bottom: 0;
}

.text-body {
  color: rgba(103, 119, 136, 1) !important;
}

.bg-soft-danger {
  background-color: rgba(237, 76, 120, .1) !important;
}

.text-danger {
  color: rgba(237, 76, 120, 1) !important;
}

.search-container {
  width: 350px;
  max-width: 500px;
  /* margin: auto; */
  /* padding: 20px 0; */
  height: 29px;
}

.search-icon-rtl {
  position: absolute;
  top: 5px;
  margin-right: 3px;
  margin-left: 3px;
  z-index: 1000;
  left: auto;
  right: 0;
}

.search-icon-ltr {
  position: absolute;
  top: 5px;
  margin-right: 3px;
  margin-left: 3px;
  z-index: 1000;
  left: 0;
  right: auto;
}

.search-input {
  /* border-top-left-radius: 0.3125rem; */
  border-radius: 0.3125rem;
}

.lang-btn {
  cursor: pointer;
}

element.style {}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.btn-icon {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: .875rem;
  font-weight: 600;
  width: 2.625rem;
  height: 2.625rem;
  padding: 0;
  border: none !important;
}

.btn-ghost-secondary {
  color: #71869d;
  background-color: transparent;
}

.rounded-circle {
  border-radius: 50% !important;
}

.avatar-xss,
.avatar-xss .avatar-initials {
  width: 1rem;
  height: 1rem;
}

.avatar-circle {
  border-radius: 50%;
}

.avatar {
  position: relative;
  display: inline-block;
}

img,
svg {
  vertical-align: middle;
}

.btn-check:focus+.btn-ghost-secondary,
.btn-ghost-secondary:focus,
.btn-ghost-secondary:hover {
  color: #377dff;
  border: none;
}

.btn-ghost-secondary:focus,
.btn-ghost-secondary:hover,
.show>.btn-ghost-secondary.dropdown-toggle {
  border-color: transparent;
  background-color: rgba(55, 125, 255, .1);
}

.btn:focus {
  /* color: var(--bs-btn-hover-color); */
  background-color: var(--bs-btn-hover-bg) !important;
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:focus+.btn-soft-info,
.btn-soft-info:focus,
.btn-soft-info:hover {
  color: #fff;
  background-color: #09a5be !important;
  border-color: transparent;
}

.language-content {
  opacity: 1;
  transform: translateY(10px) translateY(-10px);
  transition: transform 300ms ease 0s, opacity 300ms ease 0s;
  border-radius: .5rem;
  margin-top: .75rem;
  box-shadow: 0 .6125rem 2.5rem .6125rem rgba(140, 152, 164, .175);
}

.language-content-rtl {
  text-align: right
}

.language-content-ltr {
  text-align: left;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  /* text-transform: uppercase; */
  letter-spacing: .03125rem;
  font-size: .65625rem;
  font-weight: 500;
  padding: .5rem 1rem;
  display: block;
  margin-bottom: 0;
  white-space: nowrap;
  color: #8c98a4
}

.dropdown-item.active:active:focus,
.dropdown-item.active:not(:focus):not(:active) {
  color: #1e2022;
  background-color: rgba(189, 197, 209, .2);
  text-decoration: none;
}

.dropdown-item:not(:last-child) {
  margin-bottom: .25rem;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  white-space: nowrap;
  border: 0;
  border-radius: .3125rem;
}

.dropdown-menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0.5rem;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.625rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: #677788;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.5rem;
  --bs-dropdown-border-width: 0;
  --bs-dropdown-inner-border-radius: 0.5rem;
  --bs-dropdown-divider-bg: rgba(231, 234, 243, 0.7);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  --bs-dropdown-link-color: #1e2022;
  --bs-dropdown-link-hover-color: #1b1d1f;
  --bs-dropdown-link-hover-bg: rgba(189, 197, 209, 0.2);
  --bs-dropdown-link-active-color: #1e2022;
  --bs-dropdown-link-active-bg: rgba(189, 197, 209, 0.2);
  --bs-dropdown-link-disabled-color: #97a4af;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.5rem;
  --bs-dropdown-header-color: #8c98a4;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.me-2 {
  margin-left: .5rem !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn.rounded-circle .btn-sm-status {
  left: -0.15625rem;
  right: auto;
}

.btn.rounded-circle .btn-sm-status {
  top: -.15625rem;
}

.btn-status-danger {
  background-color: #ed4c78;
}

.btn-sm-status {
  font-size: .65625rem;
  width: 12px;
  height: 12px;
}

.btn-status {
  position: absolute;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border: .125rem solid #fff;
  padding: 0;
  border-radius: 50%;
}

.navbar-dropdown-account-wrapper {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  color: #1e2022;
  background-color: transparent;
  padding: .25rem;
  border-radius: 50rem;
  transition: .3s;
}

.avatar:not(img) {
  background-color: #fff;
}

.avatar-sm,
.avatar-sm .avatar-initials {
  width: 1.9rem;
  height: 1.9rem;
}

.avatar-circle {
  border-radius: 50%;
}

.avatar {
  position: relative;
  display: inline-block;
}

.avatar-sm .avatar-img {
  width: 1.9rem;
}

.avatar-circle .avatar,
.avatar-circle .avatar-img,
.avatar-circle .avatar-initials {
  border-radius: 50%;
}

.avatar-img {
  max-width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  /* border-radius: .5rem; */
}

img,
svg {
  vertical-align: middle;
}

li {
  text-align: -webkit-match-parent;
  list-style: none !important;
}

.avatar-sm.avatar-circle .avatar-sm-status {
  bottom: -.08203125rem;
  right: -.08203125rem;
}

.avatar-status-success {
  color: #fff;
  background-color: #00c9a7;
}

.avatar-sm-status {
  width: .8203125rem;
  height: .8203125rem;
  font-size: .4375rem;
}

.avatar-status {
  position: absolute;

  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  vertical-align: baseline;
  border: .125rem solid #fff;
  line-height: 1;
  border-radius: 50%;
}

.navbar-dropdown-account-wrapper:focus,
.navbar-dropdown-account-wrapper:hover {
  background-color: rgba(55, 125, 255, .1);
}

:focus,
a:focus,
button:focus {
  outline-color: rgba(55, 125, 255, .5);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

@media (min-width: 992px) {
  .navbar-expand-lg:not(.navbar-vertical) .navbar-nav {
    -ms-flex-align: center;
    align-items: center;
    justify-content: flex-end;
  }
}

.navbar .navbar-nav-wrap-content-end .navbar-nav,
.navbar .navbar-nav-wrap-secondary-content .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}

.navbar-nav.side-header-nav {
  display: flex;
  flex-direction: row;
  gap: 10px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.navbar-nav {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(249, 250, 252, 1) !important;
}

.dropdown-menu-end-rtl {
  right: auto;
  left: 0;
  text-align: right;
}

.dropdown-menu-end-ltr {
  left: auto;
  right: 0;
  text-align: left
}

.delete-icon-rtl {
  z-index: 1000;
  left: 5px;
  right: auto;
  top: 17px !important;
  cursor: pointer;
}

.delete-icon-ltr {
  z-index: 1000;
  right: 5px;
  left: auto;
  top: 17px !important;
  cursor: pointer;
}

.search-input-rtl {
  padding: 5px 0.75rem;
  padding-right: 40px;
  border-width: 0;
  font-size: .8125rem;
}

.search-input-ltr {
  padding: 5px 0.75rem;
  padding-left: 40px;
  border-width: 0;
  font-size: .8125rem;
}

.dropdown-item-text {
  display: block;
  padding: 0.5rem 1rem;
  color: #1e2022;
}

.ms-3 {
  margin-right: 1rem !important;
}

.dropdown-menu {
  --bs-dropdown-font-size: 0.705rem;
  box-shadow: 0 .6125rem 2.5rem .6125rem rgba(140, 152, 164, .175);
}

.card-text:last-child {
  margin-bottom: 0;
}

.text-body {
  color: rgba(103, 119, 136, 1) !important;
}

@media (min-width: 992px) {
  .navbar-expand-lg .dropdown-menu {
    margin-top: .75rem;
  }
}

.navbar .navbar-dropdown-menu-borderless {
  border-top-width: 0;
  border-radius: .5rem;
}

.dropdown-item:not(:last-child) {
  margin-bottom: .25rem;
}

.dropdown-menu .dropdown-item {
  font-size: .8125rem;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #1b1d1f;
  background-color: rgba(189, 197, 209, 0.2);
}

:focus,
a:focus,
button:focus {
  outline-color: rgba(55, 125, 255, .5);
}

.dropdown-toggle {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.dropdown-item {
  border-radius: .3125rem;
}

.dropdown-menu .dropdown-item.dropdown-toggle-rtl::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.dropdown-menu .dropdown-item.dropdown-toggle-ltr::after {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.navbar .dropdown-menu .dropdown-toggle-rtl::after,
.navbar .nav-compact-icon .dropdown-toggle-rtl::after {
  margin-left: -0.625rem;
  margin-right: auto;
  border: 0px;

}

.navbar .dropdown-menu .dropdown-toggle-ltr::after,
.navbar .nav-compact-icon .dropdown-toggle-ltr::after {
  margin-right: -0.625rem;
  margin-left: auto;
  border: 0px;

}

.dropdown-toggle:not(.dropdown-toggle-empty)::after {
  margin-right: auto;
  margin-left: 0;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1rem 1rem;
  content: "";
  transition: .3s;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
}

.navbar-dropdown-sub-menu-rtl {
  left: calc(100% + 1rem);
  right: auto;
  top: 0;
}

.navbar-dropdown-sub-menu-ltr {
  right: calc(100% + 1rem);
  left: auto;
  top: 0;
}

.legend-indicator {
  /* margin-left: 0.1375rem; */
  margin-right: 0;
}

.me-1 {
  margin-left: .25rem !important;
}

.legend-indicator {
  display: inline-block;
  width: .5rem;
  height: .5rem;
  /* background-color: #bdc5d1; */
  border-radius: 50%;
  /* margin-right: .4375rem; */
}

.bg-success {
  background-color: rgba(0, 201, 167, 1) !important;
}

.bg-danger {
  background-color: rgba(237, 76, 120, 1) !important;
}

.bg-warning {
  background-color: rgba(245, 202, 153, 1) !important;
}

.navbar {
  padding: 7px;
}

.navbar-collapse {
  align-items: center !important;
}

.header__user__last__date {
  width: max-content;
}
.navbar-role-rtl{
  margin-left: 5px;
}
.navbar-role-ltr{
  margin-right: 5px;
}
</style>

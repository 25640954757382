<template>
    <div>
      <PropertyWithAssociationSelectionForm :editPropertyId="parsedPropertyId" />
    </div>
  </template>
  
  <script>

import PropertyWithAssociationSelectionForm from '../../components/PropertyWithAssociationSelectionForm.vue';  
  
export default {
    props: ['id'],
    components: {
      PropertyWithAssociationSelectionForm
    },
    data() {
      return {
        propertyData: {}
      };
    },
    computed: {
        parsedPropertyId() {
            return parseInt(this.id);
        }
    }
  };
  </script>
  
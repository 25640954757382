<template>
    <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
        <div class="content-card">
            <div class="page-header-content">
                <div class="row mb-3">
                    <div class="col-sm mb-2 mb-sm-0">
                        <div class="d-flex gap-2 align-items-center justify-content-between mb-3">
                            <h1 class="page-header-title mb-0">{{ this.editPropertyId ? $t('message.modifyPropertyData')
                                : $t('message.createNewProperty') }}</h1>
                             
                            <a class="back-btn" @click="goBack"><b-icon icon="arrow-left" aria-hidden="true"></b-icon></a>

                        </div>
                        <!-- <p class="page-header-text">{{ this.editPropertyId ? $t('message.modifyPropertyDataParagraph') :
                            $t('message.createNewPropertyParagraph') }}</p> -->
                    </div>
                </div>
                <!-- End Row -->
                <div class="step-content">
                    <div>
                        <div class="mt-5">
                            <div class="row justify-content-start">


                                <div class="mb-3 col-md-5 col-6">
                                    <div>

                                        <label for="associationSelect" class="form-label">{{
                                            $t('message.selectAssociationId') }}</label>
                                        <v-select :dir="direction" v-model="form.selectedAssociationId"
                                            :options="associations"
                                            :disabled="!enableInput"
                                            :class="{ 'is-invalid': errors.selectedAssociationId }"
                                            :placeholder="$t('message.selectAssociationId')"
                                            id="multiple-select-custom-field"
                                            @search:blur="validateField('selectedAssociationId')"
                                            @search:focus="clearError('selectedAssociationId')"></v-select>
                                        <div class="invalid-feedback" v-if="errors.selectedAssociationId">{{
                                            errors.selectedAssociationId
                                        }}</div>
                                    </div>
                                </div>


                                <div class="mb-3 col-md-5 col-6">
                                    <div>
                                        <label for="legalOwnership" class="form-label">{{ $t('message.legalOwnership')
                                            }}</label>
                                        <v-select :dir="direction" v-model="form.legalOwnership" :options="managers"
                                            :class="{ 'is-invalid': errors.legalOwnership }" multiple
                                            :placeholder="$t('message.selectLegalOwnership')"
                                            id="multiple-select-custom-field"
                                            :disabled="!enableInput"
                                            @search:blur="validateField('legalOwnership')"
                                            @search:focus="clearError('legalOwnership')"></v-select>
                                        <div class="invalid-feedback" v-if="errors.legalOwnership">{{
                                            errors.legalOwnership
                                        }}</div>
                                    </div>
                                </div>

                                <div class="mb-3 col-md-2 col-6">
                                    <button :disabled="!enableInput" type="button" class="btn btn-success addUserBtn btn-sm"
                                        data-bs-toggle="modal" data-bs-target="#addnewusermodel">
                                        {{ $t('message.AddNewOwner') }}
                                    </button>

                                    <div class="modal fade " id="addnewusermodel" tabindex="-1"
                                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered scrollable">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h4 class="modal-title" id="exampleModalLabel">
                                                        {{ $t('message.AddNewOwner') }}</h4>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">



                                                    <div class="mb-3 col-md-12 col-6">
                                                        <label for="idNumber" class="form-label">{{ 'رقم الهوية'
                                                            }}</label>
                                                        <input type="text" placeholder='رقم الهوية'
                                                            :class="{ 'is-invalid': errors.idNumber }"
                                                            class="form-control form-control-sm" id="idNumber"
                                                            v-model="form.idNumber" @blur="validateField('idNumber')"
                                                            @focus="clearError('idNumber')" required>
                                                        <div class="invalid-feedback" v-if="errors.idNumber">{{
                                                            errors.idNumber }}</div>
                                                    </div>

                                                    <div class="mb-3 col-md-12 col-6">
                                                        <label for="userName" class="form-label">{{ 'الاسم' }}</label>
                                                        <input type="text" placeholder='الاسم'
                                                            :class="{ 'is-invalid': errors.userName }"
                                                            class="form-control form-control-sm" id="userName"
                                                            v-model="form.userName" @blur="validateField('userName')"
                                                            @focus="clearError('userName')" required>
                                                        <div class="invalid-feedback" v-if="errors.userName">{{
                                                            errors.userName }}</div>
                                                    </div>


                                                    <div class="mb-3 col-md-12 col-6">
                                                        <label for="email" class="form-label">{{ 'البريد الإلكتروني'
                                                            }}</label>
                                                        <input type="email" :placeholder="'البريد الإلكتروني'"
                                                            :class="{ 'is-invalid': touched.email && errors.email }"
                                                            class="form-control form-control-sm" id="email"
                                                            v-model="form.email" @blur="validateField('email')"
                                                            @focus="clearError('email')" required>
                                                        <div class="invalid-feedback"
                                                            v-if="touched.email && errors.email">{{ errors.email
                                                            }}</div>
                                                    </div>

                                                    <div class="mb-3 col-md-12 col-6" style="position: relative;">
                                                        <label for="password" class="form-label">{{ 'الرقم السرى'
                                                            }}</label>
                                                        <b-form-input :type="passwordReveal ? 'text' : 'password'"
                                                            :placeholder="'الرقم السرى'"
                                                            :class="{ 'is-invalid': errors.password }"
                                                            class="form-control form-control-sm" id="password"
                                                            v-model="form.password" @blur="validateField('password')"
                                                            @focus="clearError('password')" required>
                                                        </b-form-input>
                                                        <b-icon class="toggle-password"
                                                            :class="[{ 'toggle-rtl': isRTL, 'toggle-ltr': !isRTL }]"
                                                            @click="togglePasswordVisibility"
                                                            :icon="passwordReveal ? 'eye-slash' : 'eye'"></b-icon>

                                                        <div class="invalid-feedback" v-if="errors.password">{{
                                                            errors.password
                                                        }}</div>
                                                    </div>

                                                    <div class="mb-3 col-md-12 col-6">
                                                        <label for="phoneNumber" class="form-label">{{ 'رقم الهاتف'
                                                            }}</label>
                                                        <input type="number" placeholder="رقم الهاتف"
                                                            :class="{ 'is-invalid': touched.phoneNumber && errors.phoneNumber }"
                                                            class="form-control form-control-sm" id="phoneNumber"
                                                            v-model="form.phoneNumber"
                                                            @blur="validateField('phoneNumber')"
                                                            @focus="clearError('phoneNumber')" required>
                                                        <div class="invalid-feedback"
                                                            v-if="touched.phoneNumber && errors.phoneNumber">{{
                                                                errors.phoneNumber }}</div>
                                                    </div>







                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-white"
                                                        data-bs-dismiss="modal">{{ $t('message.CancelButton')
                                                        }}</button>
                                                    <button type="button" @click="submitModal()"
                                                        :disabled="!isModalValid" class="btn btn-logo">{{
                                                            $t('message.saveAndInsert') }}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="street" class="form-label">{{ $t('message.street') }}</label>
                                    <input :disabled="!enableInput" type="text" :placeholder="$t('message.street')"
                                        :class="{ 'is-invalid': errors.street }" class="form-control form-control-sm"
                                        id="street" v-model="form.street" @blur="validateField('street')"
                                        @focus="clearError('street')" required>
                                    <div class="invalid-feedback" v-if="errors.street">{{ errors.street }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="space" class="form-label">{{ $t('message.area') }}</label>
                                    <input type="number" :disabled="!enableInput" :placeholder="$t('message.area')"
                                        :class="{ 'is-invalid': errors.space }" class="form-control form-control-sm"
                                        id="space" v-model="form.space" @blur="validateField('space')"
                                        @focus="clearError('space')" required>
                                    <div class="invalid-feedback" v-if="errors.space">{{ errors.space }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="flatArea" class="form-label">{{ $t('message.surfaceArea') }}</label>
                                    <input type="number" :disabled="!enableInput" :placeholder="$t('message.surfaceArea')"
                                        :class="{ 'is-invalid': errors.flatArea }" class="form-control form-control-sm"
                                        id="flatArea" v-model="form.flatArea" @blur="validateField('flatArea')"
                                        @focus="clearError('flatArea')" required>
                                    <div class="invalid-feedback" v-if="errors.flatArea">{{ errors.flatArea }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="plotNumber" class="form-label">{{ $t('message.plotNumber') }}</label>
                                    <input type="number" :disabled="!enableInput" :placeholder="$t('message.plotNumber')"
                                        :class="{ 'is-invalid': errors.plotNumber }"
                                        class="form-control form-control-sm" id="plotNumber" v-model="form.plotNumber"
                                        @blur="validateField('plotNumber')" @focus="clearError('plotNumber')" required>
                                    <div class="invalid-feedback" v-if="errors.plotNumber">{{ errors.plotNumber }}</div>
                                </div>

                                <!-- <div class="mb-3 col-md-3 col-6">
                                    <label for="bankNumber" class="form-label">{{ $t('message.bankAccountNumber') }}</label>
                                    <input type="number" :placeholder="$t('message.bankAccountNumber')"
                                        :class="{ 'is-invalid': errors.bankNumber }" class="form-control form-control-sm"
                                        id="bankNumber" v-model="form.bankNumber" @blur="validateField('bankNumber')"
                                        @focus="clearError('bankNumber')" required>
                                    <div class="invalid-feedback" v-if="errors.bankNumber">{{ errors.bankNumber }}</div>
                                </div> -->

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="instrumentNumber1" class="form-label">{{ $t('message.deedNumber')
                                        }}</label>
                                    <input type="number" :disabled="!enableInput" :placeholder="$t('message.deedNumber')"
                                        :class="{ 'is-invalid': errors.instrumentNumber1 }"
                                        class="form-control form-control-sm" id="instrumentNumber1"
                                        v-model="form.instrumentNumber1" @blur="validateField('instrumentNumber1')"
                                        @focus="clearError('instrumentNumber1')" required>
                                    <div class="invalid-feedback" v-if="errors.instrumentNumber1">
                                        {{ errors.instrumentNumber1 }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="sourceInstrument1" class="form-label">{{ $t('message.deedSource')
                                        }}</label>
                                    <input type="text" :disabled="!enableInput" :placeholder="$t('message.deedSource')"
                                        :class="{ 'is-invalid': errors.sourceInstrument1 }"
                                        class="form-control form-control-sm" id="sourceInstrument1"
                                        v-model="form.sourceInstrument1" @blur="validateField('sourceInstrument1')"
                                        @focus="clearError('sourceInstrument1')" required>
                                    <div class="invalid-feedback" v-if="errors.sourceInstrument1">
                                        {{ errors.sourceInstrument1 }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="floorCount" class="form-label">{{ $t('message.numberOfFloors')
                                        }}</label>
                                    <input type="number" :disabled="!enableInput" :placeholder="$t('message.numberOfFloors')"
                                        :class="{ 'is-invalid': errors.floorCount }"
                                        class="form-control form-control-sm" id="floorCount" v-model="form.floorCount"
                                        @blur="validateField('floorCount')" @focus="clearError('floorCount')" required>
                                    <div class="invalid-feedback" v-if="errors.floorCount">{{ errors.floorCount }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="elevatorCount" class="form-label">{{ $t('message.numberOfElevators')
                                        }}</label>
                                    <input type="number" :disabled="!enableInput" :placeholder="$t('message.numberOfElevators')"
                                        :class="{ 'is-invalid': errors.elevatorCount }"
                                        class="form-control form-control-sm" id="elevatorCount"
                                        v-model="form.elevatorCount" @blur="validateField('elevatorCount')"
                                        @focus="clearError('elevatorCount')" required>
                                    <div class="invalid-feedback" v-if="errors.elevatorCount">{{ errors.elevatorCount }}
                                    </div>
                                </div>


                                <div class="mb-3 col-md-3 col-6">
                                    <label for="constructionType" class="form-label">{{ $t('message.typeOfConstruction')
                                        }}</label>
                                    <input type="text" :disabled="!enableInput" :placeholder="$t('message.typeOfConstruction')"
                                        :class="{ 'is-invalid': errors.constructionType }"
                                        class="form-control form-control-sm" id="constructionType"
                                        v-model="form.constructionType" @blur="validateField('constructionType')"
                                        @focus="clearError('constructionType')" required>
                                    <div class="invalid-feedback" v-if="errors.constructionType">{{
                                        errors.constructionType
                                    }}</div>
                                </div>
                                <div class="mb-3 col-md-3 col-6">
                                    <label for="constructionYear" class="form-label">{{ $t('message.yearOfConstruction')
                                        }}</label>
                                    <DatePicker v-model="form.constructionYear" type="year" placeholder="أختر السنة"
                                        :class="{ 'is-invalid-datepicker': errors.constructionYear }"
                                        @blur="validateField('constructionYear')"
                                        :disabled="!enableInput"
                                        @focus="clearError('constructionYear')" required />
                                    <div class="invalid-feedback" v-if="errors.constructionYear">{{
                                        errors.constructionYear }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="northBoundary" class="form-label">{{ $t('message.northernBoundary')
                                        }}</label>
                                    <input type="text" :placeholder="$t('message.northernBoundary')"
                                        :class="{ 'is-invalid': errors.northBoundary }"
                                        class="form-control form-control-sm" id="northBoundary"
                                        :disabled="!enableInput"
                                        v-model="form.northBoundary" @blur="validateField('northBoundary')"
                                        @focus="clearError('northBoundary')" required>
                                    <div class="invalid-feedback" v-if="errors.northBoundary">{{ errors.northBoundary }}
                                    </div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="southBoundary" class="form-label">{{ $t('message.southernBoundary')
                                        }}</label>
                                    <input type="text" :disabled="!enableInput" :placeholder="$t('message.southernBoundary')"
                                        :class="{ 'is-invalid': errors.southBoundary }"
                                        class="form-control form-control-sm" id="southBoundary"
                                        v-model="form.southBoundary" @blur="validateField('southBoundary')"
                                        @focus="clearError('southBoundary')" required>
                                    <div class="invalid-feedback" v-if="errors.southBoundary">{{ errors.southBoundary }}
                                    </div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="eastBoundary" class="form-label">{{ $t('message.eastBoundary')
                                        }}</label>
                                    <input type="text" :disabled="!enableInput" :placeholder="$t('message.eastBoundary')"
                                        :class="{ 'is-invalid': errors.eastBoundary }"
                                        class="form-control form-control-sm" id="eastBoundary"
                                        v-model="form.eastBoundary" @blur="validateField('eastBoundary')"
                                        @focus="clearError('eastBoundary')" required>
                                    <div class="invalid-feedback" v-if="errors.eastBoundary">{{ errors.eastBoundary }}
                                    </div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="westBoundary" class="form-label">{{ $t('message.westBoundary')
                                        }}</label>
                                    <input type="text" :placeholder="$t('message.westBoundary')"
                                        :class="{ 'is-invalid': errors.westBoundary }"
                                        class="form-control form-control-sm" :disabled="!enableInput" id="westBoundary"
                                        v-model="form.westBoundary" @blur="validateField('westBoundary')"
                                        @focus="clearError('westBoundary')" required>
                                    <div class="invalid-feedback" v-if="errors.westBoundary">{{ errors.westBoundary }}
                                    </div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="electricityAccountNumber" class="form-label">{{
                                        $t('message.electricityAccountNumber') }}</label>
                                    <input type="number" :placeholder="$t('message.electricityAccountNumber')"
                                        :class="{ 'is-invalid': errors.electricityAccountNumber }"
                                        class="form-control form-control-sm" id="electricityAccountNumber"
                                        v-model="form.electricityAccountNumber" :disabled="!enableInput"
                                        @blur="validateField('electricityAccountNumber')"
                                        @focus="clearError('electricityAccountNumber')" required>
                                    <div class="invalid-feedback" v-if="errors.electricityAccountNumber">
                                        {{ errors.electricityAccountNumber }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="electricityNumber " class="form-label">{{
                                        $t('message.electricityNumber') }}</label>
                                    <input type="number" :placeholder="$t('message.electricityNumber')"
                                        :class="{ 'is-invalid': errors.electricityNumber }"
                                        class="form-control form-control-sm" id="electricityNumber"
                                        v-model="form.electricityNumber" :disabled="!enableInput" @blur="validateField('electricityNumber')"
                                        @focus="clearError('electricityNumber')" required>
                                    <div class="invalid-feedback" v-if="errors.electricityNumber">
                                        {{ errors.electricityNumber }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="subscriptionElectricityNumber" class="form-label">{{
                                        $t('message.subscriptionElectricityNumber') }}</label>
                                    <input type="number" :placeholder="$t('message.subscriptionElectricityNumber')"
                                        :class="{ 'is-invalid': errors.subscriptionElectricityNumber }"
                                        class="form-control form-control-sm" id="subscriptionElectricityNumber"
                                        v-model="form.subscriptionElectricityNumber" :disabled="!enableInput"
                                        @blur="validateField('subscriptionElectricityNumber')"
                                        @focus="clearError('subscriptionElectricityNumber')" required>
                                    <div class="invalid-feedback" v-if="errors.subscriptionElectricityNumber">
                                        {{ errors.subscriptionElectricityNumber }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="waterAccountNumber" class="form-label">{{
                                        $t('message.waterAccountNumber') }}</label>
                                    <input type="number" :placeholder="$t('message.waterAccountNumber')"
                                        :class="{ 'is-invalid': errors.waterAccountNumber }"
                                        class="form-control form-control-sm" id="waterAccountNumber"
                                        v-model="form.waterAccountNumber" :disabled="!enableInput" @blur="validateField('waterAccountNumber')"
                                        @focus="clearError('waterAccountNumber')" required>
                                    <div class="invalid-feedback" v-if="errors.waterAccountNumber">{{
                                        errors.waterAccountNumber }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="WaterNumber " class="form-label">{{ $t('message.WaterNumber') }}</label>
                                    <input type="number" :placeholder="$t('message.WaterNumber')"
                                        :class="{ 'is-invalid': errors.WaterNumber }"
                                        class="form-control form-control-sm" id="WaterNumber" v-model="form.WaterNumber"
                                        @blur="validateField('WaterNumber')" :disabled="!enableInput" @focus="clearError('WaterNumber')"
                                        required>
                                    <div class="invalid-feedback" v-if="errors.WaterNumber">{{ errors.WaterNumber }}
                                    </div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="status" class="form-label">{{ $t('message.status') }}</label>

                                    <toggle-button 
                                        style="display: block;"
                                        class="form-control form-control-sm"
                                        :disabled="!enableInput"
                                        :key="toggleKey"
                                        v-model="isActive"
                                    />
                         
                                </div>

                                <div v-if="!isActive" class="mb-3 col-md-3 col-6">
                                        <label for="deactivateReason" class="form-label">{{ 'سبب الإيقاف' }}</label>
                                        <textarea placeholder='سبب الإيقاف'
                                            :class="{ 'is-invalid': deactivateReasonError }"
                                            :disabled="!enableInput"
                                            class="form-control form-control-sm" id="deactivateReason"
                                            v-model="form.deactivateReason" @input="validateDeactivateReason" rows="1" required>
                                        </textarea>
    
                                            <div v-if="deactivateReasonError" class="invalid-feedback">يجب أن يحتوي الإدخال على 10 حرفًا على الأقل
                                            وأن يكون حروفًا
                                            فقط.</div>
                               
                                
                                </div>

                                <h5 class="mt-3">{{ $t('message.propertyLocation') }}</h5>
                                <p>{{ $t('message.selectFromMap') }}</p>

                                <l-map :zoom="zoom" :center="center" style="height: 500px; width: 100%;"
                                    @update:center="updateLatLngFromMap">
                                    <l-tile-layer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
                                    <l-marker :lat-lng="markerPosition" :draggable="true"
                                        @update:lat-lng="updateLatLngFromMarker"></l-marker>
                                </l-map>
                                <div class="mt-3 col-md-6 col-6">
                                    <label for="latitude" class="form-label">{{ $t('message.latitude') }}</label>
                                    <input id="latitude" :disabled="!enableInput" class="form-control form-control-sm" type="number"
                                        v-model="lat" @change="updateMarkerPosition" />
                                </div>
                                <div class="mt-3 col-md-6 col-6">
                                    <label for="longitude" class="form-label">{{ $t('message.longitude') }}</label>
                                    <input id="longitude" :disabled="!enableInput" class="form-control form-control-sm" type="number"
                                        v-model="lng" @change="updateMarkerPosition" />
                                </div>

                                <div class="mt-5">
                                    <h5 class="mb-0">{{ $t('message.attachments') }}</h5>
                                    <p>{{ $t('message.attachmentsParagraph') }}</p>

                                    <div class="mb-5">
                                        <!-- <label for="attachFilesNewProjectLabel"
                                            class="col-form-label form-label">صور
                                            العمارة</label> -->
                                        <div id="attachFilesNewProjectLabel"
                                            class="js-dropzone dz-dropzone dz-dropzone-card mb-4" @dragenter.prevent @dragover.prevent @drop.prevent="handleFileDrop">
                                            <div class="dz-message">
                                                <h5>{{ $t('message.dragAndDrop') }}
                                                </h5>
                                                <p class="mb-2">{{ $t('message.or') }}</p>
                                                <span class="btn btn-white btn-sm" @click="triggerFileInput">{{
                                                    $t('message.chooseFiles') }}</span>

                                                <div class="uploaded-files">
                                                    <div v-for="(file) in files" :key="file.id"
                                                        class="file-item col-md-3">
                                                        <div style="text-align: center;"
                                                            v-if="file && file.mime_type && file.mime_type.includes('image')">
                                                            <img :src="file.url" class="uploaded-image" @click="openInNewTab(file.url, 'image')" />
                                                            <div class="limit-name">{{ file.name }}</div>
                                                        </div>
                                                        <div v-else>
                                                            <img class="uploaded-image"
                                                                :src="require(`../assets/${getIconNameForFileType(file.type)}`)"
                                                                alt="File Icon" @click="openInNewTab(file.url,file.type)" />
                                                            <div class="limit-name">{{ file.name }}</div>
                                                        </div>
                                                        <progress class="progress-bar bg-success" :value="file.progress"
                                                            max="100"></progress>
                                                        <span>{{ (file.size / 1024).toFixed(2) }} KB</span>
                                                        <button @click="removeFile(file.id)" class="btn-sm">X</button>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <input :disabled="!enableInput" type="file" ref="fileInput" @change="handleFilesUpload" multiple
                                        style="display: none;" />

                                </div>




                            </div>
                        </div>

                    </div>

                </div>
                <div class="modal fade " id="gobackmodel" ref="gobackmodel" tabindex="-1"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="exampleModalLabel">
                                    {{ $t('message.back') }}</h4>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">

                                <p>{{ $t('message.warningLoseData') }}</p>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-white"
                                    data-bs-dismiss="modal">{{ $t('message.CancelButton') }}</button>
                                <button type="button" @click="confirmBack" class="btn btn-logo">
                                    {{ $t('message.confirmBack') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-5" style="text-align: end">
                    <button style="margin: 0px 5px;" v-if="editPropertyId" class="btn btn-logo" @click="toggleEditMode">{{ enableInput ? 'قفل' : 'تحرير'}}</button>
                    <button class="btn btn-logo" @click="handleSubmit" :disabled="!isStepValid || isAdding || (this.editPropertyId && !updateSucess)">{{
                        this.editPropertyId ? $t('message.save') : $t('message.create') }}</button>
                    <button v-if="!editPropertyId" class="btn btn-logo anotherAddition-btn" @click="anotherAddition()" :disabled="!isStepValid || isAdding">{{ $t('message.anotherAddition') }}</button>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import axiosInstance from '../axios-config';



// تخصيص الرمز الافتراضي
delete L.Icon.Default.prototype._getIconUrl;


L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


export default {
    // isEditMode
    props: {
        editPropertyId: {
            type: Number,
            required: false
        }
    },

    components: {
        LMap,
        LTileLayer,
        LMarker,
        vSelect,
        DatePicker
    },
    data() {
        return {
            updateSucess: false,
            enableInput: false,
            center: [24.7136, 46.6753], // موقع الرياض
            markerPosition: ['24.7136', '46.6753'],
            lat: '24.7136',
            lng: '46.6753',
            zoom: 10,
            files: [],
            isAdding: false,
            passwordReveal: false,
            form: {
                street: '',
                space: '',
                flatArea: '',
                legalOwnership: '',
                selectedAssociationId: '',
                plotNumber: '',
                bankNumber: '',
                elevatorCount: '',
                floorCount: '',
                constructionYear: '',
                constructionType: '',
                instrumentNumber1: '',
                sourceInstrument1: '',
                northBoundary: '',
                southBoundary: '',
                eastBoundary: '',
                westBoundary: '',
                electricityAccountNumber: '',
                electricityNumber: '',
                subscriptionElectricityNumber: '',
                waterAccountNumber: '',
                WaterNumber: '',
                deactivateReason: '',
                // for modal 
                idNumber: '',
                userName: '',
                email: '',
                password: '',
                phoneNumber: '',
                //end modal
            },
            managers: [],
            associations: [],
            propertyTypes: [
                'شقة بثلاثة غرف',
                'شقة بخمسة غرف',
                'مجمع سكني',
                'فيلا'
            ],
            errors: {
                street: '',
                space: '',
                flatArea: '',
                legalOwnership: '',
                selectedAssociationId: '',
                plotNumber: '',
                bankNumber: '',
                elevatorCount: '',
                floorCount: '',
                constructionYear: '',
                instrumentNumber1: '',
                sourceInstrument1: '',
                northBoundary: '',
                southBoundary: '',
                eastBoundary: '',
                westBoundary: '',
                electricityAccountNumber: '',
                electricityNumber: '',
                subscriptionElectricityNumber: '',
                waterAccountNumber: '',
                WaterNumber: '',
                deactivateReason: '',
                // for modal
                idNumber: '',
                userName: '',
                email: '',
                password: '',
                phoneNumber: '',
                //  end modal

            },
            touched: {
                email: false,
                phoneNumber: false,
            },
            propertyId: null,
            isActive: true,
            toggleKey: 0,
            deactivateReasonError: false,

        }
    },
    mounted() {
        this.getusersRole();
        this.getAssociationsId();


        if (this.editPropertyId) {
            this.featchData();
        }else{
            // Access the id from the route
            this.propertyId = this.$route.params.id; // This will log the id, e.g., "1", or undefined if no id is provided
            this.enableInput = true;
            if (this.propertyId !== undefined) {
                this.form.selectedAssociationId = this.propertyId;
            }
        }
        
    },
    watch: {
        // مراقبة form.constructionYear
        'form.constructionYear': function (newValue) {
            if (!newValue) {
                this.isStepValid(); // استدعاء isStepValid إذا كانت القيمة فارغة
            } else {
                this.isStepValid();
            }
        }
    },
    computed: {
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
        direction() {
            return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
        },
        isModalValid() {
            return this.form.idNumber && this.form.userName && this.form.email &&
                this.form.password && this.form.phoneNumber;
        },
        isStepValid() {
            this.validateEmail();
            const step3Fields = [
                'street',
                'space',
                'flatArea',
                'legalOwnership',
                'selectedAssociationId',
                'plotNumber',
                // 'bankNumber',
                'elevatorCount',
                'floorCount',
                'constructionYear',
                'constructionType',
                'instrumentNumber1',
                'sourceInstrument1',
                'northBoundary',
                'southBoundary',
                'eastBoundary',
                'westBoundary',
                'electricityAccountNumber',
                'electricityNumber',
                'subscriptionElectricityNumber',
                'waterAccountNumber',
                'WaterNumber',
            ];
            const step3Errors = step3Fields.some(field => this.errors[field]);

            const step3Filled = step3Fields.every(field => this.form[field] !== '');

            if (!this.isActive) {
                const deactivateReason = this.form.deactivateReason ? this.form.deactivateReason.trim() : ''; // التحقق من القيمة
                if (deactivateReason.length < 10) {
                    this.errors.deactivateReason = true; // تحديد الخطأ
                    return false;
                } else {
                    this.errors.deactivateReason = false; // لا يوجد خطأ
                }
            }
            // if (this.form.selectedAssociationId == '') {
            //     return false;
            // }
            // if (this.form.legalOwnership.length == 0) {
            // return false;
            // }

            // if (!this.form.constructionYear) {
            //     this.errors.constructionYear = this.$t('message.errorHandle'); // تعيين رسالة الخطأ
            //     return false; // خطوة غير صالحة
            // } else {
            //     this.errors.constructionYear = ''; // حذف رسالة الخطأ إذا كانت القيمة صحيحة
            //     // return true; // خطوة صالحة
            // }

            return !step3Errors && step3Filled && true;
        }
    },
    methods: {
        validateDeactivateReason() {
            const reason = this.form.deactivateReason.trim(); // إزالة المسافات الزائدة
            this.deactivateReasonError = reason.length < 10; // تحقق إذا كان أقل من 10 أحرف
        },
        validateEmail() {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(this.form.email)) {
                this.errors.email = 'البريد الإلكتروني غير صحيح';
            } else {
                this.errors.email = '';
            }
        },
        togglePasswordVisibility() {
            this.passwordReveal = !this.passwordReveal;
        },
        isStep1Filled() {
            return ['street', 'space', 'flatArea', 'legalOwnership', 'selectedAssociationId', 'plotNumber', 'elevatorCount', 'floorCount', 'constructionYear' , 'constructionType' , 'instrumentNumber1' , 'sourceInstrument1' , 'northBoundary']
                .some(field => this.form[field]); 
        },
        toggleEditMode() {
            this.enableInput = !this.enableInput;
            this.updateSucess = true;


        },
        goBack() {
            if (this.isStep1Filled()) {
                $('#gobackmodel').modal('show');
            } else {
                window.history.back();
            }
        },
        confirmBack() {
        $('#gobackmodel').modal('hide');        
        window.history.back();
        },
        validateField(field) {
            if (!this.form[field] || this.form[field].length === 0) {
                this.$set(this.errors, field, this.$t('message.errorHandle'));
            }
            if (field === 'email') {
                this.touched.email = true;
                this.validateEmail();
            } else if (field === 'phoneNumber') {
                this.touched.phoneNumber = true;
                // this.validatePhoneNumber();
            }
        },
        clearError(field) {
            this.$delete(this.errors, field);
        },
        submitModal() {
            if (this.isModalValid) {
                this.addNewUser();
            }
        },
        async addNewUser() {

            try {
                const response = await axiosInstance.post('users', {
                    name: this.form.userName,
                    email: this.form.email,
                    identity_number: this.form.idNumber,
                    password: this.form.password,
                    role: 'user',
                    phone_number: this.form.phoneNumber
                });




                this.$izitoast.success({
                    title: 'Success',
                    message: 'successfully add new owner',
                    position: 'bottomLeft',
                    timeout: 5000, // يمكنك تخصيص مدة العرض
                });


                this.form.idNumber = '';
                this.form.userName = '';
                this.form.email = '';
                this.form.password = '';
                this.form.phoneNumber = '';
                $('#addnewusermodel').modal('hide');

                // Refresh the page after closing the modal
                setTimeout(() => {
                    // window.location.reload();
                    this.getusersRole();
                }, 500);




            } catch (error) {
                console.error('Add user error:', error);

                // Check if the error has a response and show the backend message
                const errorMessage = error.response?.data?.message || 'An error occurred while trying to add the new owner';

                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        updateLatLngFromMarker(latLng) {
            this.lat = latLng.lat;
            this.lng = latLng.lng;
            this.markerPosition = [this.lat, this.lng];
        },
        updateLatLngFromMap(center) {
            this.center = center;
        },
        updateMarkerPosition() {
            this.markerPosition = [this.lat, this.lng];
            this.center = [...this.markerPosition]; // لتحديث المركز عند تحديث marker
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleFilesUpload(event) {
            const selectedFiles = Array.from(event.target.files);

            selectedFiles.forEach(file => {
                this.uploadFile(file);
            });
        },
        uploadFile(file) {
            const isDuplicate = this.files.some(
                (existingFile) => existingFile.name === file.name && existingFile.size === file.size
            );

            if (isDuplicate) {
                // إذا كان الملف موجودًا بالفعل، لا تفعل شيئًا
                return;
            }

            const reader = new FileReader();
            const fileData = {
                name: file.name,
                type: file.type,
                size: file.size,
                mime_type: file.type, // Ensure mime_type is included
                progress: 0,
                url: '',
                rawFile: file // تخزين الملف الأصلي هنا
            };
            reader.onload = (e) => {
                fileData.url = e.target.result;
            };
            reader.readAsDataURL(file);

            // Simulate upload progress
            const fakeUpload = setInterval(() => {
                if (fileData.progress >= 100) {
                    clearInterval(fakeUpload);
                } else {
                    fileData.progress += 10;
                }
            }, 100);

            this.files.push(fileData);

        },
        openInNewTab(url, type) {
            
            
            window.open(url, '_blank');

        },
        handleFileDrop(event) {
            event.preventDefault();
            event.stopPropagation(); // منع السلوك الافتراضي
            const droppedFiles = Array.from(event.dataTransfer.files);
            droppedFiles.forEach((file) => {
                this.uploadFile(file);
            });
        },
        removeFile(fileId) {
            // ابحث عن الملف في المصفوفة
            const fileIndex = this.files.findIndex((file) => file.id === fileId);

            if (fileIndex !== -1) {
                const file = this.files[fileIndex];

                if (file.id) {
                // إذا كان الملف يحتوي على ID، فهو مرفوع على السيرفر
                this.deleteFileId(fileId); // إرسال طلب الحذف
                } else {
                // إذا لم يكن لديه ID، فهو ملف جديد لم يُرفع بعد
                this.files.splice(fileIndex, 1); // حذفه فقط من المصفوفة
                }
            }
        },
        async deleteFileId(fileId) {
            try {

                let url = 'files/deleteFile';

                if (fileId) {
                    url += `/${fileId}`;
                }

                const response = await axiosInstance.post(url, {
                    _method: 'DELETE',
                });


                const fileIndex = this.files.findIndex(file => file.id === fileId);

                if (fileIndex !== -1) {
                    this.files.splice(fileIndex, 1);
                }

                this.$izitoast.success({
                    title: 'Success',
                    message: 'successfully delete file',
                    position: 'bottomLeft',
                    timeout: 5000, // يمكنك تخصيص مدة العرض
                });

                


            } catch (error) {
                console.error('update user error:', error);

                // Check if the error has a response and show the backend message
                const errorMessage = error.response?.data?.message || 'An error occurred while trying to delete file';

                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        // sendForm(){
        //     this.$nextTick(() => {
        //     this.$router.push('/' + this.$i18n.locale + '/properties/' + this.associationId);
        //     });
        // }
        async anotherAddition() {
            const success = await this.addNewProperty();
            
            if (success) {
                this.resetForm();
            }
        },
         handleSubmit() {
            if (this.editPropertyId) {
                this.updatePropertyData();
            } else {
                this.createNewProperty();
            }
        },
        async createNewProperty() {
            const success = await this.addNewProperty();
            if (success) {
                const locale = this.$i18n.locale;
                const targetRoute = '/' + locale + '/properties/list';

                if (this.$route.path !== targetRoute) {
                    this.$router.push(targetRoute);
                }
            }
    
            // this.addNewUser();
        },

        async featchData() {
            try {

                let url = 'properties';

                if (this.editPropertyId) {
                    url += `/${this.editPropertyId}`;
                }

                const response = await axiosInstance.get(url);



                this.form.selectedAssociationId = response.data.data.association.id;
                

                this.form.street = response.data.data.street;
                this.form.space = response.data.data.area;
                this.form.flatArea = response.data.data.surface_area;
                this.form.plotNumber = response.data.data.part_number;
                this.form.instrumentNumber1 = response.data.data.instrument_number;
                this.form.sourceInstrument1 = response.data.data.instrument_source;
                this.form.floorCount = response.data.data.floors_number;
                this.form.elevatorCount = response.data.data.elevators_number;
                this.form.constructionType = response.data.data.building_type;
                const constructionYear = response.data.data.construction_year;
                const fullDate = new Date(constructionYear, 0, 1);
                this.form.constructionYear = fullDate;
                this.form.northBoundary = response.data.data.northern_border;
                this.form.southBoundary = response.data.data.southern_border;
                this.form.eastBoundary = response.data.data.eastern_border;
                this.form.westBoundary = response.data.data.western_border;
                this.form.electricityAccountNumber = response.data.data.electricity_account_number;
                this.form.electricityNumber = response.data.data.electricity_meter_number;
                this.form.subscriptionElectricityNumber = response.data.data.electricity_subscription_number;
                this.form.waterAccountNumber = response.data.data.water_account_number;
                this.form.WaterNumber = response.data.data.water_meter_number;
                this.lat = response.data.data.latitude;
                this.lng = response.data.data.longitude;
                this.center = [response.data.data.latitude, response.data.data.longitude];
                this.markerPosition = [response.data.data.latitude, response.data.data.longitude];
                const selectedOwners = response.data.data.owners;
                const selectedOwnerIds = selectedOwners.map(owner => owner.id);
                setTimeout(() => {
                    this.form.legalOwnership = this.managers.filter(manager => selectedOwnerIds.includes(manager.value));
                }, 1000);

                this.files = response.data.data.files.map(file => ({
                    id: file.id,
                    name: file.file_name,  // تأكد من أنك تضيف اسم الملف
                    type: file.mime_type,
                    size: file.size,
                    mime_type: file.mime_type,
                    url: file.url,
                    progress: 100, // يمكنك تعيينها كـ 100 لأن الملفات تم تحميلها بالفعل
                    rawFile: null // لا داعي لتخزين الملف الأصلي في حالة التحديث
                }));

                this.$nextTick(() => { this.isActive = response.data.data.status === 'active'; this.toggleKey += 1;});
                this.form.deactivateReason = response.data.data.deactivation_reason;



                // this.$izitoast.success({
                //     title: 'Success',
                //     message: 'successfully add new property',
                //     position: 'bottomLeft',
                //     timeout: 5000, // يمكنك تخصيص مدة العرض
                // });


            } catch (error) {
                console.error('update user error:', error);

                // Check if the error has a response and show the backend message
                const errorMessage = error.response?.data?.message || 'An error occurred while trying to get data';

                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        resetForm(){
            
            this.form.selectedAssociationId = '';
                this.form.street = '';
                this.form.space = '';
                this.form.flatArea = '';
                this.form.plotNumber = '';
                this.form.instrumentNumber1 = '';
                this.form.sourceInstrument1 = '';
                this.form.floorCount = '';
                this.form.elevatorCount  = '';
                this.form.constructionType = '';
                this.form.constructionYear = '';
                this.form.northBoundary = '';
                this.form.southBoundary = '';
                this.form.eastBoundary = '';
                this.form.westBoundary = '';
                this.form.electricityAccountNumber = '';
                this.form.electricityNumber = '';
                this.form.subscriptionElectricityNumber = '';
                this.form.waterAccountNumber = '';
                this.form.WaterNumber = '';
                this.form.legalOwnership = [];
                this.lat = '';                
                this.lng = ''; 
                this.files = [];
        },
        getIconNameForFileType(mimeType) {
            const type = mimeType.split('/')[1];

            if (type === 'pdf') {
                return 'pdf.png'; // أيقونة ملفات PDF
            } else if (type.includes('wordprocessingml.document')) {
                return 'doc.png'; // أيقونة ملفات Word
            } else if (type.includes('spreadsheetml.sheet')) {
                return 'xls.png'; // أيقونة ملفات Excel
            } else if (type.includes('video')) {
                return 'mp4.png'; // أيقونة ملفات الفيديو
            } else if (type.includes('audio')) {
                return 'mp3.png'; // أيقونة ملفات الصوت
            } else if (type === 'css') {
                return 'css.png'; // أيقونة ملفات CSS
            } else if (type === 'csv') {
                return 'csv.png'; // أيقونة ملفات CSV
            } else if (type === 'dbf') {
                return 'dbf.png'; // أيقونة ملفات DBF
            } else if (type === 'dwg') {
                return 'dwg.png'; // أيقونة ملفات DWG
            } else if (type === 'exe') {
                return 'exe.png'; // أيقونة ملفات EXE
            } else if (type === 'fla') {
                return 'fla.png'; // أيقونة ملفات FLA
            } else if (type === 'html') {
                return 'html.png'; // أيقونة ملفات HTML
            } else if (type === 'iso') {
                return 'iso.png'; // أيقونة ملفات ISO
            } else if (type.includes('javascript')) {
                return 'javascript.png'; // أيقونة ملفات JavaScript
            } else if (type === 'jpeg' || type === 'jpg') {
                return 'jpg.png'; // أيقونة ملفات JPEG
            } else if (type === 'json') {
                return 'json.png'; // أيقونة ملفات JSON
            } else if (type === 'png') {
                return 'png.png'; // أيقونة ملفات PNG
            } else if (type === 'ppt' || type.includes('presentation')) {
                return 'ppt.png'; // أيقونة ملفات PowerPoint
            } else if (type === 'psd') {
                return 'psd.png'; // أيقونة ملفات Photoshop
            } else if (type === 'rtf') {
                return 'rtf.png'; // أيقونة ملفات RTF
            } else if (type === 'svg') {
                return 'svg.png'; // أيقونة ملفات SVG
            } else if (type === 'txt') {
                return 'txt.png'; // أيقونة ملفات TXT
            } else if (type === 'xls' || type.includes('excel')) {
                return 'xls.png'; // أيقونة ملفات Excel
            } else if (type === 'xml') {
                return 'xml.png'; // أيقونة ملفات XML
            } else if (type === 'zip') {
                return 'zip.png'; // أيقونة ملفات ZIP
            }
        },
        async updatePropertyData() {
            try {
                let url = 'properties';
                if (this.editPropertyId) {
                    url += `/${this.editPropertyId}`;
                }

                const formData = new FormData();

                // إضافة بيانات الملكية
                formData.append('_method', 'PUT'); // إشارة إلى أن هذا الطلب هو تحديث
                formData.append('association_id', this.form.selectedAssociationId);
                formData.append('street', this.form.street);
                formData.append('area', this.form.space);
                formData.append('surface_area', this.form.flatArea);
                formData.append('part_number', this.form.plotNumber);
                formData.append('instrument_number', this.form.instrumentNumber1);
                formData.append('instrument_source', this.form.sourceInstrument1);
                formData.append('floors_number', this.form.floorCount);
                formData.append('elevators_number', this.form.elevatorCount);
                formData.append('building_type', this.form.constructionType);
                formData.append('construction_year', new Date(this.form.constructionYear).getFullYear());
                formData.append('northern_border', this.form.northBoundary);
                formData.append('southern_border', this.form.southBoundary);
                formData.append('eastern_border', this.form.eastBoundary);
                formData.append('western_border', this.form.westBoundary);
                formData.append('electricity_account_number', this.form.electricityAccountNumber);
                formData.append('electricity_meter_number', this.form.electricityNumber);
                formData.append('electricity_subscription_number', this.form.subscriptionElectricityNumber);
                formData.append('water_account_number', this.form.waterAccountNumber);
                formData.append('water_meter_number', this.form.WaterNumber);
                formData.append('latitude', this.lat.toString());
                formData.append('longitude', this.lng.toString());
                formData.append('status', this.isActive ? 'active' : 'inactive',);
                if (!this.isActive) {
                    formData.append('deactivation_reason', this.form.deactivateReason);
                }

                if (this.form.legalOwnership && this.form.legalOwnership.length) {
                    this.form.legalOwnership.forEach((owner, index) => {
                        formData.append(`owners[${index}]`, owner.value);
                    });
                }

                if (this.files && this.files.length) {
                    this.files.forEach((fileData, index) => {
                        if (fileData.rawFile) {
                            formData.append(`files[${index}]`, fileData.rawFile); // استخدم الملف الأصلي
                        }
                    });
                }

                // استخدام POST لرفع البيانات والملفات
                const response = await axiosInstance.post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });


                // التوجيه بعد النجاح
                // const locale = this.$i18n.locale;
                // const targetRoute = '/' + locale + '/properties/list';
                // if (this.$route.path !== targetRoute) {
                //     this.$router.push(targetRoute);
                // }

                this.$izitoast.success({
                    title: 'Success',
                    message: 'successfully update property',
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            } catch (error) {
                console.error('update property error:', error);

                const errorMessage = error.response?.data?.message || 'An error occurred while trying to update property';

                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });
            }
        },


        async addNewProperty() {
            this.isAdding = true;
            try {
                // إنشاء كائن FormData
                const formData = new FormData();

                // إضافة الحقول النصية إلى formData
                if (this.propertyId !== undefined) {
                    // Check if selectedAssociationId is an object and has the label property
                    if (typeof this.form.selectedAssociationId === 'object' && this.form.selectedAssociationId.label) {
                        formData.append('association_id', this.form.selectedAssociationId.label);
                    } else {
                        // If it's not an object or doesn't have the label, append it directly
                        formData.append('association_id', this.form.selectedAssociationId);
                    }
                } else {
                    // In the case where propertyId is undefined
                    formData.append('association_id', this.form.selectedAssociationId.label);
                }
                formData.append('street', this.form.street);
                formData.append('area', this.form.space);
                formData.append('surface_area', this.form.flatArea);
                formData.append('part_number', this.form.plotNumber);
                formData.append('instrument_number', this.form.instrumentNumber1);
                formData.append('instrument_source', this.form.sourceInstrument1);
                formData.append('floors_number', this.form.floorCount);
                formData.append('elevators_number', this.form.elevatorCount);
                formData.append('building_type', this.form.constructionType);
                formData.append('construction_year', new Date(this.form.constructionYear).getFullYear());
                formData.append('northern_border', this.form.northBoundary);
                formData.append('southern_border', this.form.southBoundary);
                formData.append('eastern_border', this.form.eastBoundary);
                formData.append('western_border', this.form.westBoundary);
                formData.append('electricity_account_number', this.form.electricityAccountNumber);
                formData.append('electricity_meter_number', this.form.electricityNumber);
                formData.append('electricity_subscription_number', this.form.subscriptionElectricityNumber);
                formData.append('water_account_number', this.form.waterAccountNumber);
                formData.append('water_meter_number', this.form.WaterNumber);
                formData.append('latitude', this.lat.toString());
                formData.append('longitude', this.lng.toString());
                formData.append('status', this.isActive ? 'active' : 'inactive',);

                if (!this.isActive) {
                    formData.append('deactivation_reason', this.form.deactivateReason);
                }

                // إضافة المالكين
                if (this.form.legalOwnership && this.form.legalOwnership.length) {
                    this.form.legalOwnership.forEach((owner, index) => {
                        formData.append(`owners[${index}]`, owner.value);
                    });
                }


                if (this.files && this.files.length) {
                    this.files.forEach((fileData, index) => {
                        if (fileData.rawFile) {
                            formData.append(`files[${index}]`, fileData.rawFile); // استخدم الملف الأصلي
                        }
                    });
                }

                // إرسال البيانات باستخدام Axios
                const response = await axiosInstance.post('properties', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });


                

                // عرض رسالة نجاح
                this.$izitoast.success({
                    title: 'Success',
                    message: 'Successfully added new property',
                    position: 'bottomLeft',
                    timeout: 5000, // يمكنك تخصيص مدة العرض
                });
                return true;

            } catch (error) {
                console.error('Error adding property:', error);

                // عرض رسالة خطأ
                const errorMessage = error.response?.data?.message || 'An error occurred while trying to add new property';
                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });

                return false;

            } finally {
                // إعادة تمكين الزر بعد الانتهاء
                this.isAdding = false;
            }
        },

        async getusersRole() {
            try {
                const response = await axiosInstance.get('users', {
                    params: {
                        role: 'user',
                    }
                });



                // تخزين البيانات الراجعة في rolesList
                this.managers = response.data.data.map(role => ({
                    label: role.name, // وضع الاسم كاسم العرض
                    value: role.id // استخدام المعرف كالقيمة
                }));



            } catch (error) {
                console.error('error:', error);
                this.$izitoast.error({
                    title: 'Error',
                    message: 'An error occurred while trying to get roles',
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        async getAssociationsId() {
            try {
                const response = await axiosInstance.get('associations');



                // تخزين البيانات الراجعة في rolesList
                this.associations = response.data.data.map(association => ({
                    label: association.id, // وضع الاسم كاسم العرض
                    value: association.id // استخدام المعرف كالقيمة
                }));



            } catch (error) {
                console.error('error:', error);
                this.$izitoast.error({
                    title: 'Error',
                    message: 'An error occurred while trying to get associations id',
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        updateProperty() {
        }
    },
}
</script>
<style scoped>
.content-card {
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .0);
    margin-top: 80px;
    padding: 15px;
    background-color: #fff;
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: 0.75rem;
    margin-right: 15px;
    margin-left: 15px;
}

.page-header-content {
    padding-right: 20px;
    padding-left: 20px;
}

.page-header-title {
    margin-bottom: .5rem;
}

.page-header-text {
    margin-bottom: .25rem;
    color: #677788;
    font-size: 14px
}

.addUserBtn {
    margin-top: 28px;
    width: -webkit-fill-available;
}

#main-content {
    /* max-height: 1200px; */
    min-height: 1100px;
}

.content {
    padding-top: 20px;
    /* padding-left: 10px; */
    /* padding-right: 10px; */
}

.content-rtl {
    margin-right: 17.2rem;
}

.content-ltr {
    margin-left: 17.2rem;
}

.full-content-rtl {
    margin-right: 90px;
    /* Adjusted for closed sidebar */
}

.full-content-ltr {
    margin-left: 85px;
    /* Adjusted for closed sidebar */
}

.invalid-feedback {
    color: #dc3545;
}

.is-invalid-datepicker~.invalid-feedback {
    display: block !important;
}

.uploaded-files {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 15px;
    justify-content: center;
}

.file-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #dee2e6;
    padding: 7px;
    border-radius: 5px;
    background-color: #f8f9fa;
    width: 22%;
    justify-content: center;
    min-height: 165px;
}

.uploaded-image {
    width: 100px;
    /* width: -webkit-fill-available; */
    height: 90px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 5px;
}

.file-item progress {
    width: 100%;
    margin: 5px 0;
    height: 5px;
    border-radius: 50px;
}

.file-item button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    padding: initial;
}

.limit-name {
    text-align: center;
    width: 150px;
    overflow: hidden;
}

.modal-header {
    padding-bottom: 25px;
    background-image: url(../assets/pattren-dark.svg);
    background-size: 150px;
    background-color: #f9fafc;
}

.toggle-password {
    position: absolute;
    /* left: 21px; */
    top: 41px;
    z-index: 1000;
    cursor: pointer;
    font-size: 17px;
}

.toggle-rtl {
    left: 21px;
}

.toggle-ltr {
    right: 21px;
}
</style>
import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/auth/LoginView.vue';
import NotFound from '../views/NotFound.vue';
import ForgetPassword from '../views/auth/ForgetPassword.vue';
import addAssociations from '../views/associations/create.vue'
import showAssociation from '../views/associations/show.vue'
import editAssociation from '../views/associations/edit.vue';
import associationsList from '../views/associations/list.vue';
import associationRealEstate from '../views/associations/properties.vue';
import propertiesLists from '../views/properties/list.vue';
import createProperty from '../views/properties/create.vue';
import modifyProperty from '../views/properties/edit.vue';
import showProperty from '../views/properties/show.vue';
import showUnit from '../views/units/show.vue';
import showUser from '../views/users/show.vue';
import propertyUnits from '../views/properties/units.vue';
import editUnitData from '../views/units/edit.vue';
import addNewUser from '../views/users/create.vue';
import editUser from '../views/users/edit.vue';
import createUnit from '../views/units/create.vue';
import unitsList from '../views/units/list.vue';
import usersLists from '../views/users/list.vue';
import lists from '../views/roles/lists.vue';
import modifyUserRole from '../views/roles/edit.vue';



import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/ar',
    name: 'home-ar',
    component: HomeView,
    meta: { requiresAuth: true } // login required to entre
  },
  {
    path: '/en',
    name: 'home-en',
    component: HomeView,
    meta: { requiresAuth: true } // login required to entre
  },
  {
    path: '/ar/login',
    name: 'login-ar',
    component: LoginView,
    meta: { requiresAuth: false }
  },
  {
    path: '/en/login',
    name: 'login-en',
    component: LoginView,
    meta: { requiresAuth: false }
  },
  {
    path: '/ar/forget-password',
    name: 'ForgetPassword-ar',
    component: ForgetPassword,
    meta: { requiresAuth: false }
  },
  {
    path: '/en/forget-password',
    name: 'ForgetPassword-en',
    component: ForgetPassword,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/associations/create',
    name: 'addAssociations-ar',
    component: addAssociations,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/associations/create',
    name: 'addAssociations-en',
    component: addAssociations,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/associations/show/:id',
    name: 'showAssociation-ar',
    component: showAssociation,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/associations/show/:id',
    name: 'showAssociation-en',
    component: showAssociation,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/associations/edit/:id',
    name: 'editAssociation-ar',
    component: editAssociation,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/associations/edit/:id',
    name: 'editAssociation-en',
    component: editAssociation,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/associations/list',
    name: 'associationsList-en',
    component: associationsList,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/associations/list',
    name: 'associationsList-ar',
    component: associationsList,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/associations/:id/properties',
    name: 'associationRealEstate-en',
    component: associationRealEstate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/associations/:id/properties',
    name: 'associationRealEstate-ar',
    component: associationRealEstate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/properties/:id/units',
    name: 'propertyUnits-en',
    component: propertyUnits,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/properties/:id/units',
    name: 'propertyUnits-ar',
    component: propertyUnits,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/units/edit/:id',
    name: 'editUnitData-en',
    component: editUnitData,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/units/edit/:id',
    name: 'editUnitData-ar',
    component: editUnitData,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/users/create',
    name: 'addNewUser-en',
    component: addNewUser,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/users/create',
    name: 'addNewUser-ar',
    component: addNewUser,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/users/edit/:id',
    name: 'editUser-en',
    component: editUser,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/users/edit/:id',
    name: 'editUser-ar',
    component: editUser,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/units/create/:id',
    name: 'RealEstateUnitManagementWithIds-en',
    component: createUnit,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/units/create/:id',
    name: 'RealEstateUnitManagementWithIds-ar',
    component: createUnit,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/units/create',
    name: 'createUnit-en',
    component: createUnit,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/units/create',
    name: 'createUnit-ar',
    component: createUnit,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/units/list',
    name: 'unitsList-en',
    component: unitsList,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/units/list',
    name: 'unitsList-ar',
    component: unitsList,
    meta: { requiresAuth: true }
    
  },
  {
    path: '/en/properties/list',
    name: 'propertiesLists-en',
    component: propertiesLists,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/properties/list',
    name: 'propertiesLists-ar',
    component: propertiesLists,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/properties/create/:id?',
    name: 'createProperty-en',
    component: createProperty,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/properties/create/:id?',
    name: 'createProperty-ar',
    component: createProperty,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/properties/edit/:id',
    name: 'modifyProperty-en',
    component: modifyProperty,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/properties/edit/:id',
    name: 'modifyProperty-ar',
    component: modifyProperty,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/properties/show/:id',
    name: 'showProperty-en',
    component: showProperty,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/properties/show/:id',
    name: 'showProperty-ar',
    component: showProperty,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/units/show/:id',
    name: 'showUnit-en',
    component: showUnit,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/units/show/:id',
    name: 'showUnit-ar',
    component: showUnit,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/users/show/:id',
    name: 'showUser-en',
    component: showUser,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/users/show/:id',
    name: 'showUser-ar',
    component: showUser,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/users/list',
    name: 'usersLists-en',
    component: usersLists,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/users/list',
    name: 'usersLists-ar',
    component: usersLists,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/roles/lists',
    name: 'lists-en',
    component: lists,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/roles/lists',
    name: 'lists-ar',
    component: lists,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/roles/edit/:id',
    name: 'modifyUserRole-en',
    component: modifyUserRole,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/roles/edit/:id',
    name: 'modifyUserRole-ar',
    component: modifyUserRole,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/',
    redirect: '/ar/login'
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export function createRouter(i18n) {
  router.beforeEach((to, from, next) => {
    // إزالة '/' من نهاية المسار إذا كان موجودًا
    if (to.path !== '/' && to.path.endsWith('/')) {
      next({ path: to.path.slice(0, -1), query: to.query });
      return;
    }

    const lang = to.path.split('/')[1];
    if (['ar', 'en'].includes(lang)) {
      i18n.locale = lang;
    } else {
      i18n.locale = 'en';
    }

    // تحقق من حالة تسجيل الدخول
    const isAuthenticated = !!localStorage.getItem('token');

    // إذا كان المستخدم مسجلاً وحاول الوصول إلى صفحة تسجيل الدخول
    if (to.path == `/${lang}/login` && isAuthenticated) {
      next({ path: `/${lang}` }); // إعادة توجيه إلى الصفحة الرئيسية
    } else {
      // تحقق من حالة تسجيل الدخول للمسارات التي تتطلب ذلك
      const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
      if (requiresAuth && !isAuthenticated) {
        next(`/${lang}/login`);
      } else {
        next();
      }
    }
  });
  return router;
}


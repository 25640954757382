<template>
    <div class="forget-page">
        <b-container fluid>
            <b-row>
                <!-- Column for the image -->
                <b-col md="6" class="background-image  d-none d-md-block">

                </b-col>

                <!-- Column for the form -->
                <b-col md="6" class="d-flex flex-column align-items-center justify-content-center">
                    <b-card class="login-card">
                        <b-card-header class="text-center">
                            <div>
                                <img :src="require('@/assets/logo-edarat365.png')" style="height: 78px;width: 217px;" alt="Logo" class="">
                            </div>
                            <h2 class="login-title fw-bold mb-2 mt-4">{{ $t('message.ForgetPassword') }}</h2>
                            <p class="login-parag fs-6 text-gray-500">{{ $t('message.EnterYourEmail') }}</p>
                        </b-card-header>
                        <b-card-body>
                            <b-form @submit.prevent="onSubmit" class="login-form">
                                <b-form-group>
                                    <b-form-input class="form-control-sm" v-model="email" type="email" required
                                        :placeholder="$t('message.emailPlaceholder')" :state="emailState"
                                        @input="validateEmail"></b-form-input>
                                    <b-form-invalid-feedback v-if="emailState === false && email.length === 0">
                                        <div class="error-message">{{ $t('message.emailRequiredErrorMessage') }}</div>
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-if="emailState === false && !isEmailValid">
                                        <div class="error-message">{{ $t('message.emailInvalidFormatErrorMessage') }}
                                        </div>
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <div class=" reset-footer d-flex align-items-center flex-wrap justify-content-center mt-4"
                                    style="gap: 0.75rem !important;">
                                    <b-button class="btn btn-sm btn-logo" type="submit"  block>{{
                                $t('message.continueButton') }}</b-button>
                                    <b-button class="cancel btn btn-sm" variant="light" block @click="navigateToLogin">{{
                                        $t('message.CancelButton') }}</b-button>
                                </div>
                            </b-form>
                        </b-card-body>
                    </b-card>
                    <p class="text-center login-footer text-gray-400">
                        {{ $t('message.copyRights') }}
                        <span>2024 &copy;</span>
                    </p>
                </b-col>


            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            emailState: null,
            isEmailValid: true,
        };
    },
    computed: {
        // emailState() {
        //     // Simple email validation logic
        //     const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        //     return pattern.test(this.email);
        // },
    },
    methods: {
        validateEmail() {
            if (this.email.length === 0) {
                this.emailState = false;
                this.isEmailValid = true;
            } else if (!this.validateEmailFormat(this.email)) {
                this.emailState = false;
                this.isEmailValid = false;
            } else {
                this.emailState = true;
                this.isEmailValid = true;
            }
        },
        validateEmailFormat(email) {
            const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            return emailFormat.test(email);
        },
        navigateToLogin() {
            this.$router.push('login');
        },
    },
};
</script>

<style>
.forget-page {
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.background-image {
    background-size: cover;
    background-position: center;
    background-image: url('@/assets/auth-bg.png');
    height: 100vh !important
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    background-image: none !important;
}

.login-card {
    /* border-radius: 10px; */
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    border-radius: 0.475rem !important;
    /* color: #f9f9f9; */
    border: none !important;
    outline: none !important;
    margin: 80px;
    /* margin-bottom: 0px; */
    padding: 0.5rem !important;
    background-color: #FFFFFF;
    width: 75%;
}
@media (max-width:1024px){
    .login-card {
        width: 100%;
    }
}

.login-form {
    /* margin-bottom: 20px; */
    max-width: 350px !important;
    /* width: 350px !important; */
    margin-left: auto !important;
    margin-right: auto !important;
}

.card-header {
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
    margin-bottom: 0;
    color: var(--bs-card-cap-color);
    background-color: transparent !important;
    border-bottom: none !important;
}

.card-body {
    padding: 20px;
}

.login-title {
    color: #181C32 !important;
    font-size: 1.5rem !important;
}

.login-parag {
    color: #A1A5B7;
    font-size: 1rem !important;
    margin-bottom: 0.5rem;
}

.col-form-label {
    margin-bottom: 0.5rem !important;
    font-size: 1.05rem !important;
    font-weight: 500 !important;
    color: #3F4254 !important;
}

.reset-footer button {
    padding: calc(0.30rem + 1px) calc(1.25rem + 1px);
    border: 0;
    box-shadow: none;
}

.cancel {
    box-shadow: inset 0 0 0 1px #d1d1de !important;
    /* color: #79858b; */
    /* background-color: #f1f1f1 !important;  */
    /* border-color: #c6c7c8; */
}

.login-footer {
    margin-top: 10px;
    font-size: 12px
}

.text-gray-400 {
    color: #B5B5C3;
}
.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width)* 2));
  /* padding: .366rem .75rem; */
  font-size: 0.875rem;
  border-radius: 0.425rem;
}
.form-control:focus {
    color: #5E6278 !important;
    border-color: #B5B5C3 !important;
    outline: 0 !important;
    box-shadow: none !important;
}
.form-control.is-invalid:focus {
    border-color: var(--bs-form-invalid-border-color) !important;
    box-shadow: none !important;
}
.form-control.is-invalid{
    padding-right: 0.75rem !important;
}
.form-control{
  color: #5E6278;
  background-color: transparent;
}
</style>
<template>
  <div
    class="home content"
    id="main-content"
    :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]"
  >
    <div class="content-card">
      <div class="">
        <div class="mt-3 mt-lg-0">
          <div class="">
            <div class="card-body">
              <div class="col-12">
                <div class="col-sm mb-2 mb-sm-0">
                  <h2 class="page-header-title mb-0">الصفحة الرئيسية</h2>
                  <p class="page-header-text">هذه الصفحة قيد التطوير</p>
                </div>
              </div>
              <div class="alerts__wrapper w-100 mt-3 d-none">
                <div
                  class="alert alert-soft-danger alert-dismissible fade show"
                  role="alert"
                >
                  <span class="fw-semibold d-block">هذا مجرد تنبيه</span>
                  مبيعات البضاعة مطروحاً منها كلفة البضاعة المباعة، والتي يتم
                  التعبير عنها بنسبة مئوية من مجموع الإيرادات. ويمكن تعريفه
                  أيضاً على انه إجمالي الدخل الفردي الشخصي أو جميع المكاسب
                  والأرباح التي يتم تحصيلها من جميع المصادر قبل احتساب قيمة
                  الضرائب والخصومات
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              </div>

              <div
                class="card card-body mb-3 w-100 mt-3"
                style="margin-bottom: 500px !important"
              >
                <div class="row col-lg-divider w-100">
                  <div class="col-lg-3 homepage__boxes__statics">
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        <h6 class="card-subtitle mb-2">المستخدمين</h6>
                        <h1 class="card-title">{{ stats.total_users }}</h1>
                      </div>
                      <span class="icon">
                        <lord-icon
                          target=".homepage__boxes__statics"
                          src="https://cdn.lordicon.com/imamsnbq.json"
                          trigger="hover"
                          colors="primary:#121331,secondary:#edd100"
                          style="width: 65px; height: 65px"
                        ></lord-icon>
                      </span>
                    </div>
                    <div class="d-flex flex-wrap justify-content-start gap-1 mt-2">
                      <div class="badge-group d-flex align-items-center">
                        <span class="badge bg-success">النشطين</span>
                        <span class="badge bg-success">{{ stats.active_users }}</span>
                      </div>
                      <div class="badge-group d-flex align-items-center">
                        <span class="badge bg-danger">غير النشطين</span>
                        <span class="badge bg-soft-danger text-danger">{{ stats.inactive_users }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- End Col -->

                  <div class="col-lg-3 homepage__boxes__statics">
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        <h6 class="card-subtitle mb-2">الجمعيات</h6>
                        <h1 class="card-title">{{ stats.total_associations }}</h1>
                      </div>
                      <span class="icon">
                        <lord-icon
                          target=".homepage__boxes__statics"
                          src="https://cdn.lordicon.com/xxduqotz.json"
                          trigger="hover"
                          colors="primary:#121331,secondary:#edd100"
                          style="width: 65px; height: 65px"
                        ></lord-icon>
                      </span>
                    </div>
                    <div class="d-flex flex-wrap justify-content-start gap-1 mt-2">
                      <div class="badge-group d-flex align-items-center">
                        <span class="badge bg-success">النشطة</span>
                        <span class="badge bg-success">{{ stats.active_associations }}</span>
                      </div>
                      <div class="badge-group d-flex align-items-center">
                        <span class="badge bg-danger">غير النشطة</span>
                        <span class="badge bg-soft-danger text-danger">{{ stats.inactive_associations }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 homepage__boxes__statics">
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        <h6 class="card-subtitle mb-2">العقارات</h6>
                        <h1 class="card-title">{{ stats.total_properties }}</h1>
                      </div>
                      <span class="icon">
                        <lord-icon
                          target=".homepage__boxes__statics"
                          src="https://cdn.lordicon.com/nocovwne.json"
                          trigger="hover"
                          colors="primary:#121331,secondary:#edd100"
                          style="width: 65px; height: 65px"
                        ></lord-icon>
                      </span>
                    </div>
                    <div class="d-flex flex-wrap justify-content-start gap-1 mt-2">
                      <div class="badge-group d-flex align-items-center">
                        <span class="badge bg-success">النشطة</span>
                        <span class="badge bg-success">{{ stats.active_properties }}</span>
                      </div>
                      <div class="badge-group d-flex align-items-center">
                        <span class="badge bg-danger">غير النشطة</span>
                        <span class="badge bg-soft-danger text-danger">{{ stats.inactive_properties }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 homepage__boxes__statics">
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        <h6 class="card-subtitle mb-2">الوحدات</h6>
                        <h1 class="card-title">{{ stats.total_units }}</h1>
                      </div>
                      <span class="icon">
                        <lord-icon
                          target=".homepage__boxes__statics"
                          src="https://cdn.lordicon.com/jjjmlddk.json"
                          trigger="hover"
                          colors="primary:#121331,secondary:#edd100"
                          style="width: 65px; height: 65px"
                        ></lord-icon>
                      </span>
                    </div>
                    <div class="d-flex flex-wrap justify-content-start gap-1 mt-2">
                      <div class="badge-group d-flex align-items-center">
                        <span class="badge bg-success">النشطة</span>
                        <span class="badge bg-success">{{ stats.active_units }}</span>
                      </div>
                      <div class="badge-group d-flex align-items-center">
                        <span class="badge bg-danger">غير النشطة</span>
                        <span class="badge bg-soft-danger text-danger">{{ stats.inactive_units }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axiosInstance from "../axios-config";

export default {
  name: "HomeView",
  data() {
    return {
      selected: this.$i18n.locale,
      options: [
        { item: "ar", name: "ar" },
        { item: "en", name: "en" },
      ],
      stats: {
        total_users: 0,
        active_users: 0,
        inactive_users: 0,
        total_associations: 0,
        active_associations: 0,
        inactive_associations: 0,
        total_properties: 0,
        active_properties: 0,
        inactive_properties: 0,
        total_units: 0,
        active_units: 0,
        inactive_units: 0,
      },
    };
  },
  computed: {
    isRTL() {
      return this.$i18n.locale === "ar";
    },
    ...mapGetters(["user"]),
  },
  methods: {
    switchLanguage() {
      this.$i18n.locale = this.selected;
      const currentPath = this.$route.path;
      const newPath = currentPath.replace(/^\/(ar|en)/, `/${this.selected}`);
      this.$router.push(newPath);
    },
    async fetchStatistics() {
      try {
        const response = await axiosInstance.get("statistics"); // Update with your correct API endpoint
        this.stats = response.data.data; // Assuming the structure of the response is { data: { ... } }

      } catch (error) {
        console.error('error:', error);
        this.$izitoast.error({
          title: 'Error',
          message: 'An error occurred while trying to get data',
          position: 'bottomLeft',
          timeout: 5000,
        });

      }
    },
  },
  created() {
    this.fetchStatistics(); // Fetch statistics when the component is created
  },
};
</script>
<style scoped>
.page-header-content {
  padding-right: 20px;
  padding-left: 20px;
}

.page-header-title {
  margin-bottom: .5rem;
}
</style>

<template>
    <div>
      <userForm :editUserId="parsedUserId" />
    </div>
  </template>

<script>

import userForm from '../../components/userForm.vue';


export default {
    props: ['id'],
    components: {
        userForm
    },
    data() {
        return {
            
        };
    },
    computed: {
        parsedUserId() {
            return parseInt(this.id);
        }
    }
};
</script>
<template>
    <div class="home footer-content" id="footer" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
      <div class="footer">
      <div class="row justify-content-between align-items-center">
        <div class="col-md-8 col-sm-12 copyrights">
          <p class="fs-6 mb-0">{{ $t('message.copyRightsFooter') }}</p>
        </div>
        <!-- End Col -->

        <div class="col-md-4 col-sm-12">
          <div class="d-flex contact-us">
            <!-- List Separator -->
            <ul class="list-inline list-separator">
              <li class="list-inline-item" :class="[{ 'list-inline-item-rtl': isRTL, 'list-inline-item-ltr': !isRTL }]">
                <a class="list-separator-link" href="#">{{$t('message.contactUs')}}</a>
              </li>

              <li class="list-inline-item">
                <a class="list-separator-link" href="#">{{$t('message.technicalSupport')}}</a>
              </li>
            </ul>
            <!-- End List Separator -->
          </div>
        </div>
        <!-- End Col -->
      </div>
      <!-- End Row -->
    </div>
    </div>
  </template>

<script>
export default{
    name: "theFooter",
    data(){
      return{

      }
    },
    computed: {
    isRTL() {
      return this.$i18n.locale === 'ar';
    }
  },
}

</script>

<style scoped>
body:not(.navbar-vertical-aside-mini-mode) .footer {
    margin-right: 0rem;
}
.footer {
    position: relative;
    
    height: 0rem;
}
.footer-content{
  padding-top: 10px;
}

.list-inline-item-ltr{
  padding-right: 2rem !important;
  padding-left: 0 !important;
}
.list-separator .list-inline-item-ltr::after {
    right: 0.8rem !important;
    left: auto !important;
}
.list-separator .list-inline-item-ltr::after {
    position: absolute;
    top: 50%;
    /* right: .8rem; */
    transform: translateY(-50%);
    content: "/";
    opacity: .4;
}
.contact-us{
  justify-content: end;
}
.copyrights{
  justify-content: space-between;
}
@media(max-width:1024px) {
  .contact-us{
    justify-content: center;
  }
  .copyrights{
    text-align: center;
  }
  .list-separator {
    padding-right: 0px !important;
  }
}

</style>
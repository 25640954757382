<template>
    <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
        <div class="content-card" v-if="!this.noData">
            <div class="page-header-content">
                <div class="row mb-3">
                    <div class="col-sm mb-2 mb-sm-0">
                        <div class="d-flex gap-2 align-items-center justify-content-between mb-3">

                            <div>
                                <h1 v-if="isRTL" class="page-header-title mb-0"><b-icon class="title-icon"
                                        icon="chevron-right" aria-hidden="true"></b-icon>{{ 'بيانات العقار' }}
                                </h1>
                                <h1 v-if="!isRTL" class="page-header-title mb-0"><b-icon class="title-icon"
                                        icon="chevron-right" aria-hidden="true"></b-icon>{{ 'بيانات العقار' }}
                                </h1>
                            </div>
                            <b-button variant="logo" @click="handleEditClick()">
                                <b-icon icon="box-arrow-in-down-left"></b-icon> تعديل البيانات
                            </b-button>
                            <!-- <a class="edit-btn" @click="goBack"><b-icon icon="box-arrow-in-down-left" aria-hidden="true"></b-icon></a> -->

                        </div>
                    </div>
                </div>
                <h5 class="mb-0 section-header">{{ 'المعلومات الاساسية للعقار' }}</h5>
                <div class="section-content mt-4">
                    <div class="row">
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.associationNumber') }}</h5>
                                <p class="value">{{ form.selectedAssociationId }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.legalOwnership') }}</h5>
                                <p class="value">{{ form.legalOwnership }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.street') }}</h5>
                                <p class="value">{{ form.street }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.area') }}</h5>
                                <p class="value">{{ form.space }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.surfaceArea') }}</h5>
                                <p class="value">{{ form.flatArea }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.plotNumber') }}</h5>
                                <p class="value">{{ form.plotNumber }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.deedNumber') }}</h5>
                                <p class="value">{{ form.instrumentNumber1 }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.deedSource') }}</h5>
                                <p class="value">{{ form.sourceInstrument1 }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.numberOfFloors') }}</h5>
                                <p class="value">{{ form.floorCount }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.numberOfElevators') }}</h5>
                                <p class="value">{{ form.elevatorCount }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.typeOfConstruction') }}</h5>
                                <p class="value">{{ form.constructionType }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.yearOfConstruction') }}</h5>
                                <p class="value">{{ form.constructionYear }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.electricityAccountNumber') }}</h5>
                                <p class="value">{{ form.electricityAccountNumber }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.electricityNumber') }}</h5>
                                <p class="value">{{ form.electricityNumber }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.subscriptionElectricityNumber') }}</h5>
                                <p class="value">{{ form.subscriptionElectricityNumber }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.waterAccountNumber') }}</h5>
                                <p class="value">{{ form.waterAccountNumber }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.WaterNumber') }}</h5>
                                <p class="value">{{ form.WaterNumber }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.status') }}</h5>
                                <span :class="form.status === 'نشط' ? 'badge bg-success' : 'badge bg-danger'">
                                    <p class="value">{{ form.status }}</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <h5 class="mt-4 mb-0 section-header">{{ 'الموقع الجرافي' }}</h5>
                <div class="section-content mt-4">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.northernBoundary') }}</h5>
                                <p class="value">{{ form.northBoundary }}</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.southernBoundary') }}</h5>
                                <p class="value">{{ form.southBoundary }}</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.eastBoundary') }}</h5>
                                <p class="value">{{ form.eastBoundary }}</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.westBoundary') }}</h5>
                                <p class="value">{{ form.westBoundary }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <h5 class="mt-4 mb-0 section-header">{{ 'موقع العقار على الخريطة' }}</h5>
                <div class="map-section mt-4">
                    <div class="row">
                        
                        <l-map :zoom="zoom" :center="center" style="height: 300px; width: 100%;border-radius: 10px;"
                            @update:center="updateLatLngFromMap">
                            <l-tile-layer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
                            <l-marker :lat-lng="markerPosition"
                                @update:lat-lng="updateLatLngFromMarker"></l-marker>
                        </l-map>
                    </div>
                </div>
                

                <h5 v-if="this.files.length > 0" class="mt-4 mb-0 section-header">{{ $t('message.attachments') }}</h5>
                <div v-if="this.files.length > 0" class="row mt-4">
                    <div v-for="file in files" :key="file.id" class="col-md-4 mb-3">
                        <div class="attachment-item p-2"
                            style="border: 1px solid #F1F1F2; border-radius: 10px;min-height:66px;max-height: 66px; position: relative;">
                            <div class="d-flex align-items-center" style="padding: 10px;"
                                v-if="file && file.mime_type && file.mime_type.includes('image')">
                                
                                <img :src="file.url" alt="Attachment Preview" class="attachment-icon"
                                    @click="openInNewTab(file.url, 'image')" />

                                <div style="width: max-content;">
                                    <a :href="file.url" target="_blank" class="attachment-link">
                                        {{ truncateFileName(file.file_name) }}
                                    </a>
                                    <p class="file-size text-muted mb-0" style="font-size: 12px;">
                                        {{ formatFileSize(file.size) }}
                                    </p>
                                </div>
                                <div>
                                    <b-icon class="download-icon" @click="downloadFile(file.url, file.file_name)" icon="download" aria-hidden="true"></b-icon>
                                </div>
                            </div>
                            <div class="d-flex align-items-center" v-else>
                                <img class="attachment-icon"
                                    :src="require(`../../assets/${getIconNameForFileType(file.mime_type)}`)"
                                    alt="File Icon" @click="openInNewTab(file.url, file.mime_type)" />
                                <div style="width: max-content;">
                                    <a :href="file.url" target="_blank" class="attachment-link">
                                        {{ truncateFileName(file.file_name) }}
                                    </a>
                                    <p class="file-size text-muted mb-0" style="font-size: 12px;">
                                        {{ formatFileSize(file.size) }}
                                    </p>
                                </div>
                                <div>
                                    <b-icon class="download-icon" @click="downloadFile(file.url, file.file_name)" icon="download" aria-hidden="true"></b-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <div v-else >
            <div class="content-card">
                <h5> {{ 'لم يتم العثور على عقار برقم التعريف: ' + this.id }} </h5>
            </div>
        </div>
    </div>
</template>

<script>
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axiosInstance from '../../axios-config';


// تخصيص الرمز الافتراضي
delete L.Icon.Default.prototype._getIconUrl;


L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
    name: 'addAssociations',
    props: ['id'],
    components: {
        LMap,
        LTileLayer,
        LMarker,
    },
    data() {
        return {
            center: [24.7136, 46.6753], // موقع الرياض
            markerPosition: ['24.7136', '46.6753'],
            lat: '24.7136',
            lng: '46.6753',
            zoom: 10,
            form: {
                street: '',
                space: '',
                flatArea: '',
                legalOwnership: '',
                selectedAssociationId: '',
                plotNumber: '',
                bankNumber: '',
                elevatorCount: '',
                floorCount: '',
                constructionYear: '',
                constructionType: '',
                instrumentNumber1: '',
                sourceInstrument1: '',
                northBoundary: '',
                southBoundary: '',
                eastBoundary: '',
                westBoundary: '',
                electricityAccountNumber: '',
                electricityNumber: '',
                subscriptionElectricityNumber: '',
                waterAccountNumber: '',
                WaterNumber: '',
                status: '',
            },
            files: [],
            selectedAssociationManagement: {},
            noData: false,
            managers: [],

        }
    },
    computed: {
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
    },
    created() {
        this.getusersRole();
        this.featchData();
    },
    methods: {
        async featchData() {
            try {

                let url = 'properties';

                if (this.id) {
                    url += `/${this.id}`;
                }

                const response = await axiosInstance.get(url);



                this.form.selectedAssociationId = response.data.data.association.id;
                

                this.form.street = response.data.data.street;
                this.form.space = response.data.data.area;
                this.form.flatArea = response.data.data.surface_area;
                this.form.plotNumber = response.data.data.part_number;
                this.form.instrumentNumber1 = response.data.data.instrument_number;
                this.form.sourceInstrument1 = response.data.data.instrument_source;
                this.form.floorCount = response.data.data.floors_number;
                this.form.elevatorCount = response.data.data.elevators_number;
                this.form.constructionType = response.data.data.building_type;
                this.form.constructionYear = response.data.data.construction_year;
                this.form.northBoundary = response.data.data.northern_border;
                this.form.southBoundary = response.data.data.southern_border;
                this.form.eastBoundary = response.data.data.eastern_border;
                this.form.westBoundary = response.data.data.western_border;
                this.form.electricityAccountNumber = response.data.data.electricity_account_number;
                this.form.electricityNumber = response.data.data.electricity_meter_number;
                this.form.subscriptionElectricityNumber = response.data.data.electricity_subscription_number;
                this.form.waterAccountNumber = response.data.data.water_account_number;
                this.form.WaterNumber = response.data.data.water_meter_number;
                this.lat = response.data.data.latitude;
                this.lng = response.data.data.longitude;
                this.center = [response.data.data.latitude, response.data.data.longitude];
                this.markerPosition = [response.data.data.latitude, response.data.data.longitude];
                const selectedOwners = response.data.data.owners;
                const selectedOwnerIds = selectedOwners.map(owner => owner.id);
                setTimeout(() => {
                    const legalOwnership = this.managers.filter(manager => selectedOwnerIds.includes(manager.value));
                    this.form.legalOwnership = legalOwnership.map(item => item.label).join(', ');

                }, 1000);

                this.files = response.data.data.files;
                this.form.status = response.data.data.status === 'active' ? 'نشط' : 'غير نشط';




                // this.$izitoast.success({
                //     title: 'Success',
                //     message: 'successfully add new property',
                //     position: 'bottomLeft',
                //     timeout: 5000, // يمكنك تخصيص مدة العرض
                // });


            } catch (error) {
                console.error('update user error:', error);
                this.noData = true;
                this.$router.push('/' + this.$i18n.locale + '/NotFound');
                // Check if the error has a response and show the backend message
                const errorMessage = error.response?.data?.message || 'An error occurred while trying to get data';

                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        async getusersRole() {
            try {
                const response = await axiosInstance.get('users', {
                    params: {
                        role: 'user',
                    }
                });



                // تخزين البيانات الراجعة في rolesList
                this.managers = response.data.data.map(role => ({
                    label: role.name, // وضع الاسم كاسم العرض
                    value: role.id // استخدام المعرف كالقيمة
                }));



            } catch (error) {
                console.error('error:', error);
                this.$izitoast.error({
                    title: 'Error',
                    message: 'An error occurred while trying to get roles',
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        formatFileSize(size) {
            const kb = size / 1024;
            if (kb < 1024) return `${kb.toFixed(2)} KB`;
            return `${(kb / 1024).toFixed(2)} MB`;
        },
        getIconNameForFileType(mimeType) {
            const type = mimeType.split('/')[1];

            if (type === 'pdf') {
                return 'pdf.png'; // أيقونة ملفات PDF
            } else if (type.includes('wordprocessingml.document')) {
                return 'doc.png'; // أيقونة ملفات Word
            } else if (type.includes('spreadsheetml.sheet')) {
                return 'xls.png'; // أيقونة ملفات Excel
            } else if (type.includes('video')) {
                return 'mp4.png'; // أيقونة ملفات الفيديو
            } else if (type.includes('audio')) {
                return 'mp3.png'; // أيقونة ملفات الصوت
            } else if (type === 'css') {
                return 'css.png'; // أيقونة ملفات CSS
            } else if (type === 'csv') {
                return 'csv.png'; // أيقونة ملفات CSV
            } else if (type === 'dbf') {
                return 'dbf.png'; // أيقونة ملفات DBF
            } else if (type === 'dwg') {
                return 'dwg.png'; // أيقونة ملفات DWG
            } else if (type === 'exe') {
                return 'exe.png'; // أيقونة ملفات EXE
            } else if (type === 'fla') {
                return 'fla.png'; // أيقونة ملفات FLA
            } else if (type === 'html') {
                return 'html.png'; // أيقونة ملفات HTML
            } else if (type === 'iso') {
                return 'iso.png'; // أيقونة ملفات ISO
            } else if (type.includes('javascript')) {
                return 'javascript.png'; // أيقونة ملفات JavaScript
            } else if (type === 'jpeg' || type === 'jpg') {
                return 'jpg.png'; // أيقونة ملفات JPEG
            } else if (type === 'json') {
                return 'json.png'; // أيقونة ملفات JSON
            } else if (type === 'png') {
                return 'png.png'; // أيقونة ملفات PNG
            } else if (type === 'ppt' || type.includes('presentation')) {
                return 'ppt.png'; // أيقونة ملفات PowerPoint
            } else if (type === 'psd') {
                return 'psd.png'; // أيقونة ملفات Photoshop
            } else if (type === 'rtf') {
                return 'rtf.png'; // أيقونة ملفات RTF
            } else if (type === 'svg') {
                return 'svg.png'; // أيقونة ملفات SVG
            } else if (type === 'txt') {
                return 'txt.png'; // أيقونة ملفات TXT
            } else if (type === 'xls' || type.includes('excel')) {
                return 'xls.png'; // أيقونة ملفات Excel
            } else if (type === 'xml') {
                return 'xml.png'; // أيقونة ملفات XML
            } else if (type === 'zip') {
                return 'zip.png'; // أيقونة ملفات ZIP
            }
        },
        openInNewTab(url, type) {

            window.open(url, '_blank');

        },
        handleEditClick(){
            this.$nextTick(() => {
                this.$router.push('/' + this.$i18n.locale + '/properties/edit/' + this.id);
            });
        },
        updateLatLngFromMarker(latLng) {
            this.lat = latLng.lat;
            this.lng = latLng.lng;
            this.markerPosition = [this.lat, this.lng];
        },
        updateLatLngFromMap(center) {
            this.center = center;
        },
        // updateMarkerPosition() {
        //     this.markerPosition = [parseFloat(this.lat), parseFloat(this.lng)];
        //     this.center = [...this.markerPosition]; // لتحديث المركز عند تحديث marker
        // },
        truncateFileName(fileName) {
            const maxLength = 28; // يمكنك تعديل الحد الأقصى للطول حسب الحاجة
            if (fileName.length > maxLength) {
                return fileName.substring(0, maxLength) + '...';
            }
            return fileName;
        },
        downloadFile(url, fileName) {
            // إنشاء طلب لجلب الملف
            fetch(url)
                .then(response => response.blob()) // تحويل الاستجابة إلى Blob
                .then(blob => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob); // إنشاء رابط URL مؤقت للملف
                    link.download = fileName; // تعيين اسم الملف للتنزيل
                    document.body.appendChild(link); // إضافة الرابط إلى DOM
                    link.click(); // النقر على الرابط لتنزيل الملف
                    document.body.removeChild(link); // إزالة الرابط بعد التنزيل
                    // عرض رسالة النجاح
                    this.$izitoast.success({
                        title: 'Success',
                        message: 'File downloaded successfully',
                        position: 'bottomLeft',
                        timeout: 5000,
                    });
                })
                .catch(error => {
                    // عرض رسالة الخطأ
                    this.$izitoast.error({
                        title: 'Error',
                        message: 'An error occurred while trying to download the file',
                        position: 'bottomLeft',
                        timeout: 5000,
                    });
                    console.error('Error downloading the file:', error);
                });
        }
    }
}


</script>

<style scoped>
.content-card {
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .0);
    margin-top: 80px;
    padding: 15px;
    background-color: #fff;
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: 0.75rem;
    margin-right: 15px;
    margin-left: 15px;
}

.page-header-content {
    padding-right: 20px;
    padding-left: 20px;
}

.page-header-title {
    margin-bottom: .5rem;
}

.title-icon {
    font-size: 15px;
    margin-left: 5px
}

.section-header {
    color: #007AFF;
}

.section-content {
    border: 1px solid #F1F1F1;
    padding: 40px;
    border-radius: 10px;
}

.map-section {
    padding-right: 12px;
    padding-left: 12px;
}

p {
    margin-bottom: 0px !important;
}

.space {
    margin-bottom: 50px;
}

.attachment-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */

}

.attachment-item:hover {
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */
}

.attachment-link {
    color: #161313;
    text-decoration: none;
    width: 30%;
}

.attachment-link:hover {
    text-decoration: none
}
.attachment-icon{
    width: 24px;
    height: 24px;
    margin-left: 10px;
    cursor: pointer;
}
.download-icon{
    position: absolute;
    left: 11px;
    top: 24px;
    color: #FF0000;
    font-size: medium;
    cursor: pointer;
}
</style>
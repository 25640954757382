<template>
    <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
        <div class="content-card">
            <div class="page-header">
                <div class="row mb-3">
                    <div class="col-sm mb-2 mb-sm-0">
                        <div class="d-flex gap-2 align-items-center mb-3">
                            <h1 class="page-header-title mb-0">{{ $t('message.realEstateUnits') }}</h1>
                            <div class="d-flex align-items-center gap-3 ms-3" v-b-tooltip.hover title="قيد التطوير">
                                <h1 class="card-title mb-0">{{this.totalRealEstateUnits}} <small style="font-weight: 400;font-size: 13px;">{{
                                        'وحدة' }}</small>
                                </h1>
                                <!-- <h1 class="card-title mb-0">356 <small style="font-weight: 400;font-size: 13px;">{{
                                        $t('message.owner') }}</small></h1> -->
                            </div>
                        </div>
                        <!-- <p class="page-header-text">{{ $t('message.realEstateUnitsParagraph') }}</p> -->
                        <div class="mt-2">

                            <button v-b-tooltip.hover title="قيد التطوير" class="btn btn-logo btn-sm"
                                :class="[{ 'exportBtn-rtl': isRTL, 'exportBtn-ltr': !isRTL }]" href="javascript:;"
                                data-bs-toggle="dropdown" aria-expanded="false" style="margin: 5px;">
                                <i class="bi-download me-1"></i> {{ $t('message.exportLists') }}
                            </button>

                            <div class="dropdown-menu dropdown-menu-sm-end" aria-labelledby="usersExportDropdown">
                                <a id="export-excel" class="dropdown-item" href="javascript:;">
                                    <img class="avatar avatar-xss avatar-4x3 me-2" src="../../assets/excel-icon.svg"
                                        alt="Image Description">
                                    Excel
                                </a>
                                <a id="export-csv" class="dropdown-item" href="javascript:;">
                                    <img class="avatar avatar-xss avatar-4x3 me-2"
                                        src="../../assets/placeholder-csv-format.svg" alt="Image Description">
                                    .CSV
                                </a>
                                <a id="export-pdf" class="dropdown-item" href="javascript:;">
                                    <img class="avatar avatar-xss avatar-4x3 me-2" src="../../assets/pdf-icon.svg"
                                        alt="Image Description">
                                    PDF
                                </a>
                            </div>
                            <a v-b-tooltip.hover title="قيد التطوير" class="btn btn-outline-logo btn-sm import-btn" href="javascript:;" data-bs-toggle="modal"
                                data-bs-target="#importProductsModal">
                                <i class="bi-upload me-1"></i> {{ $t('message.importLists') }}
                            </a>
                        </div>
                    </div>
                    <div class="col-auto homepage__boxes__statics">
                        <!-- <div v-b-tooltip.hover title="قيد التطوير" class="d-flex align-items-center">
                            <span class="d-block fs-6">{{ 152 }} {{ 'وحدة عقارية هذا الأسبوع' }}</span>
                            <span class="badge bg-soft-success text-success ms-2">
                                <i class="bi-graph-up"></i> 7.53%
                            </span>
                        </div> -->
                        <div class="d-flex flex-wrap justify-content-start gap-1 mt-2">
                            <div class="badge-group d-flex align-items-center">
                                <span class="badge bg-success">{{ 'الوحدات المفعلة' }}</span>
                                <span class="badge bg-soft-success text-success">{{this.activeCount}}</span>
                            </div>
                            <div class="badge-group d-flex align-items-center">
                                <span class="badge bg-danger">{{ 'الوحدات الغير مفعلة' }}</span>
                                <span class="badge bg-soft-danger text-danger">{{this.inactiveCount}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Row -->
                 <!-- Nav Scroller -->



                <div style="z-index:2" id="stickyBlockStartPoint">
                    <div class="js-sticky-block js-nav-scroller hs-nav-scroller-horizontal"
                        data-hs-sticky-block-options='{
					 "parentSelector": "#stickyBlockStartPoint",
					 "breakpoint": "lg",
					 "startPoint": "#stickyBlockStartPoint",
					 "endPoint": "#stickyBlockEndPoint",
					 "stickyOffsetTop": 70,
					 "stickyOffsetBottom": 20
				   }'>
                        <span class="hs-nav-scroller-arrow-prev" style="display: none;">
                            <a class="hs-nav-scroller-arrow-link" href="javascript:;">
                                <i class="bi-chevron-left"></i>
                            </a>
                        </span>

                        <span class="hs-nav-scroller-arrow-next" style="display: none;">
                            <a class="hs-nav-scroller-arrow-link" href="javascript:;">
                                <i class="bi-chevron-right"></i>
                            </a>
                        </span>

                        <!-- Nav -->
                        <ul class="nav nav-tabs page-header-tabs" id="pageHeaderTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" href="">{{ 'قائمة الوحدات العقارية' }}</a>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :to="'/' + this.$i18n.locale + '/units/create'">{{
                                    $t('message.createNewRealStateUnit') }}
                                </router-link>
                            </li>
                        </ul>
                        <!-- End Nav -->
                    </div>
                </div>
                <!-- End Nav Scroller -->
            </div>




            <div class="card">
                <!-- Header -->
                <div class="card-header card-header-content-md-between bg-light jadda-pattern-dark">
                    <div class="mb-md-0 col-md-4">
                        <div class="input-group input-group-merge input-group-flush">
                            <div class="input-group-prepend input-group-text search-icon">
                                <lord-icon colors="primary:#121331,secondary:#edd100" target=".input-group"
                                    src="https://cdn.lordicon.com/iszohckt.json" trigger="hover"
                                    style="width:15px;height:15px"> </lord-icon>
                            </div>
                            <b-form-input v-model="filter" :placeholder="$t('message.searchInRealStateUnitsList')"
                                class="mt-3 mb-3 form-control bg-white filter-search-input"></b-form-input>
                            <!-- <input id="datatableSearch" type="search" class="form-control bg-white" placeholder="البحث في قائمة الجمعيات" aria-label="Search users"> -->
                        </div>
                    </div>
                    <!-- End Search -->

                    <div class="d-grid d-sm-flex gap-2">
                        <div class="dropdown" v-if="selectedCount > 0">
                            <div id="datatableCounterInfo">
                                <div class="d-sm-flex justify-content-lg-end align-items-sm-center">
                                    <span class="d-block d-sm-inline-block fs-5 me-3 mb-2 mb-sm-0"
                                        style="margin-left: 15px">
                                        {{ $t('message.selectedLists') }} {{ ': ' }}<span id="datatableCounter">{{
                                            selectedCount
                                            }}</span>
                                    </span>


                                    <div class="dropdown mb-1">
                                        <button type="button" class="btn btn-white dropdown-toggle w-100"
                                            id="usersExportDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                            {{ ' إجراءات سريعة ' }}
                                        </button>

                                        <div class="dropdown-menu dropdown-menu-sm-end"
                                            aria-labelledby="usersExportDropdown">


                                            <a class="dropdown-item" @click="activateSelected()">
                                                <i class="bi-check-lg"></i> {{ $t('message.activate') }}
                                            </a>
                                            <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#deactivate-modal" @click="prepareDeactivate()">
                                                <i class="bi-x-lg"></i> {{ $t('message.deactivate') }}
                                            </a>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="dropdown">
                            <!-- <div class="dropdown mb-2 mb-md-0">
                                <button @click="createNewRealStateUnit()"
                                    class="btn btn-logo add-new-property">{{ $t('message.createNewRealStateUnit') }}</button>
                            </div> -->
                            <div class="dropdown">
                                <button type="button" class="btn btn-white show-lists w-100" id="showHideDropdown"
                                    data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                    <i class="bi-table me-1"></i> {{ $t('message.lists') }} <span
                                        class="badge bg-soft-dark text-dark rounded-circle ms-1">{{ this.fields.length
                                        }}</span>
                                </button>

                                <div class="dropdown-menu dropdown-menu-end dropdown-card"
                                    aria-labelledby="showHideDropdown" style="width: 15rem;">
                                    <div class="card card-sm">
                                        <div class="card-body">
                                            <div class="d-grid gap-3 w-100">
                                                <b-form-checkbox v-for="field in fields" :disabled="field.key === 'select'"
                                                    :key="field.key" v-model="field.visible" @change="updateVisibleFields">
                                                    <span style="margin: 5px">{{ field.label }}</span>
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End Dropdown -->
                        </div>
                    </div>

                </div>

                <b-table hover :busy.sync="isBusy" responsive :items="filteredItems" :fields="visibleFields"
                    :current-page="1" :per-page="perPage" :filter="filter" @filtered="onFiltered"
                    @sort-changed="onSortChanged">

                    <template #table-busy>
                        <div class="text-center text-success  my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                            <!-- <img :src="require('@/assets/icon-edarat365.png')" width="40" height="40" alt="Logo"
                                class="loading-icon"> -->
                        </div>
                    </template>

                    <template #head(select)="data">
                        <b-form-checkbox v-model="selectAll" @change="toggleSelectAll"></b-form-checkbox>
                    </template>

                    <template #cell(status)="row">
                        <span :class="row.item.status === 'active' ? 'badge bg-success' : 'badge bg-danger'">
                            {{ row.item.statusArabic  }}
                        </span>
                    </template>

                    <template #cell(select)="row">
                        <b-form-checkbox v-model="row.item.selected"></b-form-checkbox>
                    </template>

                    <template #cell(actions)="row">
                        <!-- <b-dropdown size="sm" text="Actions" variant="link">
                        <b-dropdown-item @click="enableEdit(row.item)">Edit</b-dropdown-item>
                        <b-dropdown-item @click="deleteItem(row.item)">Delete</b-dropdown-item>
                    </b-dropdown> -->
                        <div class="btn-group" role="group">
                            <a @click="handleViewClick(row.item)" class="btn btn-white view-btn btn-sm">
                                <i class="bi-eye me-1"></i> {{ $t('message.view') }}
                            </a>

                            <!-- Button Group -->
                            <div class="btn-group">
                                <button type="button"
                                    class="btn btn-white btn-icon btn-sm dropdown-toggle dropdown-toggle-empty"
                                    id="productsEditDropdown1" data-bs-toggle="dropdown" aria-expanded="false"></button>

                                <div class="dropdown-menu dropdown-menu-end mt-1"
                                    aria-labelledby="productsEditDropdown1">
                                    <!-- <a class="dropdown-item" @click="handleUnitClick(row.item)">
                                        <i class="bi bi-house-add dropdown-item-icon"></i> {{ $t('message.realEstateUnits') }}
                                    </a> -->
                                    <a class="dropdown-item" @click="handleEditClick(row.item)">
                                        <i class="bi-pencil-fill dropdown-item-icon"></i> {{
                                        $t('message.modifyUnitData') }}
                                    </a>
                                    <!-- <a class="dropdown-item" @click="enableDelete(row.item)" data-bs-toggle="modal"
                                        data-bs-target="#delete-modal">
                                        <i class="bi-trash dropdown-item-icon"></i> {{ $t('message.delete') }}
                                    </a> -->
                                    <a class="dropdown-item" v-if="row.item.status === 'active'" @click="handleDeactivateClick(row.item)" data-bs-toggle="modal" data-bs-target="#deactivate-modal">
                                        <i class="bi-x-lg dropdown-item-icon"></i>
                                        {{ $t('message.deactivate') }}
                                    </a>
                                    <a class="dropdown-item" v-else @click="handleActivateClick(row.item)">
                                        <i class="bi-check-lg dropdown-item-icon"></i>
                                        {{ $t('message.activate') }}
                                    </a>
                                </div>
                            </div>
                            <!-- End Button Group -->
                        </div>
                    </template>

                    <!-- <template #cell(name)="row">
                        <template v-if="row.item.isEditing">
                            <b-form-input v-model="row.item.editName" @keyup.enter="saveEdit(row.item)"></b-form-input>
                        </template>
                        <template v-else>
                            {{ row.item.name }}
                        </template>
                    </template> -->

                    <!-- <template #cell(date)="row">
                        <template v-if="row.item.isEditing">
                            <b-form-input v-model="row.item.editDate" @keyup.enter="saveEdit(row.item)"></b-form-input>
                        </template>
                        <template v-else>
                            {{ row.item.date }}
                        </template>
                    </template> -->

                </b-table>

                <!-- delete Modal -->
                <!-- <div class="modal fade" id="delete-modal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="exampleModalLabel">{{ $t('message.deleteData') }}</h4>
                                <button type="button" class="btn-close"
                                    :class="[{ 'btn-close-rtl': isRTL, 'btn-close-ltr': !isRTL }]"
                                    data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p class="page-header-text"> {{ $t('message.deleteDataParagraph') }}</p>
                                <input type="text" v-model="inputText" class="form-control" placeholder="سبب الحذف"
                                    @input="validateInput">
                                <small v-if="showError" class="text-danger">يجب أن يحتوي الإدخال على 50 حرفًا على الأقل
                                    وأن يكون حروفًا
                                    فقط.</small>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-white"
                                    data-bs-dismiss="modal">{{ $t('message.CancelButton') }}</button>
                                <button type="button" @click="deleteItemConfirmed" :disabled="!isValid"
                                    class="btn btn-danger">{{ $t('message.delete') }}</button>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- Deactivate Modal -->
                <div class="modal fade" id="deactivate-modal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="exampleModalLabel">{{ 'إيقاف' }}</h4>
                                <button type="button" class="btn-close"
                                    :class="[{ 'btn-close-rtl': isRTL, 'btn-close-ltr': !isRTL }]"
                                    data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p class="page-header-text"> {{ 'يرجى توضيح سبب الإيقاف' }}</p>
                                <input type="text" v-model="deactivateReason" class="form-control" placeholder="سبب الإيقاف"
                                    @input="validateInput">
                                <small v-if="showError" class="text-danger">يجب أن يحتوي الإدخال على 10 حرفًا على الأقل
                                    وأن يكون حروفًا
                                    فقط.</small>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-white"
                                    data-bs-dismiss="modal">{{ $t('message.CancelButton') }}</button>
                                <button type="button" @click="deactivateItemConfirmed()" :disabled="!isValid"
                                    class="btn btn-danger">{{ 'إيقاف' }}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <template v-if="filteredItems.length === 0 && filter.length > 0">
                    <div class="text-center p-4">
                        <lord-icon src="https://cdn.lordicon.com/wkppeqfv.json"
                            colors="primary:#121331,secondary:#edd100" trigger="loop" style="width:100px;height:100px">
                        </lord-icon>
                        <p class="mb-0"> {{ $t('message.noMatchingResults') }} "{{ filter }}".</p>
                    </div>
                </template>


                <div
                    class="d-flex  align-items-center  card-header card-header-content-md-between bg-light jadda-pattern-dark pagination-section justify-content-between">
                    <ul class="mb-0" style="padding-left: 5px; padding-right: 0px !important;">
                        <b-form-group>
                            <b-form-select id="perPageSelect" class="perPageSelect" v-model="perPage"
                                :options="perPageOptions" @change="onPerPageChange"></b-form-select>
                        </b-form-group>
                    </ul>

                    <b-pagination class="mb-0" style="padding-right: 0px !important;" :total-rows="totalRows"
                        v-model="currentPage" :per-page="perPage" @change="changePage" prev-text="Previous"
                        next-text="Next"></b-pagination>
                </div>


            </div>
            <!-- end card -->
             <!-- Import Modal -->
            <div class="modal fade" id="importProductsModal" tabindex="-1" aria-labelledby="importProductsModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <!-- Header -->
                        <div class="modal-header">
                            <h4 class="modal-title" id="importProductsModalLabel">{{ $t('message.importNewOwnersLists')
                                }}
                            </h4>
                            <button type="button" class="btn-close"
                                :class="[{ 'btn-close-rtl': isRTL, 'btn-close-ltr': !isRTL }]" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <!-- End Header -->

                        <!-- Body -->
                        <div class="modal-body">
                            <p><a class="link" href="#">{{ $t('message.downloadCsvFile1') }}</a>
                                {{ $t('message.downloadCsvFileForExample') }}</p>

                            <!-- Dropzone -->
                            <div id="attachFilesNewProjectLabel" class="js-dropzone dz-dropzone dz-dropzone-card mb-4">
                                <div class="dz-message">
                                    <h5>{{ $t('message.dragDropFile') }}</h5>
                                    <p class="mb-2">{{ $t('message.or') }}</p>
                                    <span class="btn btn-white btn-sm" @click="triggerFileInput">{{
                                        $t('message.chooseFiles')
                                        }}</span>
                                    <div class="uploaded-files">
                                        <div v-for="(file, index) in files" :key="index" class="file-item col-md-3">
                                            <div style="text-align: center;"
                                                v-if="file && file.mime_type && file.mime_type.includes('image')">
                                                <img :src="file.url" class="uploaded-image" />
                                                <div class="limit-name">{{ file.name }}</div>
                                            </div>
                                            <div v-else>
                                                <img class="uploaded-image"
                                                    :src="require(`../../assets/${getIconNameForFileType(file.type)}`)"
                                                    alt="File Icon" />
                                                <div class="limit-name">{{ file.name }}</div>
                                            </div>
                                            <progress class="progress-bar bg-success" :value="file.progress"
                                                max="100"></progress>
                                            <span>{{ (file.size / 1024).toFixed(2) }} KB</span>
                                            <button @click="removeFile(index)" class="btn-sm">X</button>
                                        </div>
                                    </div>
                                    <input type="file" ref="fileInput" @change="handleFilesUpload" multiple
                                        style="display: none;" />
                                </div>
                            </div>
                            <!-- End Dropzone -->
                            
                        </div>
                        <!-- End Body -->

                        <!-- Footer -->
                        <div class="modal-footer gap-2">
                            <button type="button" class="btn btn-white" data-bs-dismiss="modal" aria-label="Close">{{
                                $t('message.CancelButton') }}</button>
                            <button type="button" class="btn btn-logo">{{ $t('message.uploadAndContinue') }}</button>
                        </div>
                        <!-- End Footer -->
                    </div>
                </div>
            </div>
            <!-- End Import Modal -->
        </div>
    </div>
</template>

<script>
import axiosInstance from '../../axios-config';

export default {
    name: 'realEstateUnitLists',
    data() {
        return {
            items: [],
            fields: [
                { key: 'select', label: '', visible: true },
                { key: 'id', label: 'رقم الوحدة العقارية', visible: true },
                { key: 'property_id', label: 'رقم العقار', visible: true },
                { key: 'description', label: 'وصف الوحدة العقارية', visible: true },
                { key: 'area', label: this.$t('message.area'), visible: true },
                { key: 'code', label: 'كود الوحدة', visible: true },
                // { key: 'ownerName', label: 'أسم المالك', visible: true },
                // { key: 'ownershipercentage', label: 'نسبه تملكة', visible: true },
                { key: 'rooms', label: 'عدد الغرف', visible: true },
                { key: 'status', label: 'الحالة', visible: true },
                { key: 'bathrooms', label: 'عدد الحمامات', visible: true },
                { key: 'actions', label: this.$t('message.actions'), visible: true }
            ],
            currentPage: 1,
            perPage: 10,
            perPageOptions: [1, 2, 5, 10, 20, 50, 100],
            totalRows: 0,
            filter: '',
            selectAll: false,
            unitId: this.id,
            inputText: '',
            showError: false,
            isValid: false,
            isBusy: false,
            files: [],
            modalName: '',
            selectedItemStatusId: [],
            deactivateReason: '',
            inputText: '',
            showError: false,
            isValid: false,
            activeCount: 0,
            inactiveCount: 0,
            totalRealEstateUnits: 0,

        };
    },
    computed: {
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
        visibleFields() {
            return this.fields.filter(field => field.visible);
        },
        filteredItems() {
            return this.items.filter(item => {
                return this.visibleFields.some(field => {
                    return item[field.key] && item[field.key].toString().toLowerCase().includes(this.filter.toLowerCase());
                });
            });
        },
        selectedCount() {
            return this.items.filter(item => item.selected).length;
        },
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        updateVisibleFields() {
            this.visibleFields = this.fields.filter(field => field.visible);
        },
        validateInput() {
            // إزالة المسافات من النص لحساب الطول بدون المسافات
            const cleanedText = this.deactivateReason.replace(/\s/g, '');
            // Regex يتحقق من أن النص يحتوي فقط على الأحرف الإنجليزية أو العربية
            const regex = /^[\u0600-\u06FFa-zA-Z]+$/;
            this.isValid = regex.test(cleanedText) && cleanedText.length >= 10;
            this.showError = !this.isValid;
        },
        toggleSelectAll() {
            this.items.forEach((item, index) => {
                // استخدم Vue.set لضمان أن التحديث تفاعلي
                this.$set(this.items, index, { ...item, selected: this.selectAll });
            });
        },
        activateSelected() {
            // اجلب العناصر المحددة
            const selectedItems = this.items.filter(item => item.selected);
            // اجلب IDs الخاصة بالعناصر المحددة
            const selectedIds = selectedItems.map(item => item.id);


            // إجراء طلب تفعيل العناصر المحددة
            this.activateItems(selectedIds);
        },
        prepareDeactivate() {
            // اجلب العناصر المحددة
            const selectedItems = this.items.filter(item => item.selected);
            // اجلب IDs الخاصة بالعناصر المحددة
            this.selectedItemStatusId = selectedItems.map(item => item.id);

        },
        handleDeactivateClick(item) {
            // this.selectedItemStatusId.push(item.id);
            item.selected = !item.selected;

            // تحديث selectedItemStatusId بناءً على العناصر المحددة
            const selectedItems = this.items.filter(item => item.selected);
            this.selectedItemStatusId = selectedItems.map(item => item.id);

            
        },
        async handleActivateClick(item) {
            // if (item) {
                this.selectedItemStatusId.push(item.id);
            // }
            
            const formData = new FormData();
            this.selectedItemStatusId.forEach((id, index) => {
                formData.append(`ids[${index}]`, id);
            });
            formData.append('status', 'active');
            formData.append('model', this.modalName);
            formData.append('_method', 'PUT');

            try {
                
                const response = await axiosInstance.post('updateStatus', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });



                

                this.$izitoast.success({
                    title: 'Success',
                    message: 'Users have been successfully activated!',
                    position: 'bottomLeft',
                    timeout: 5000,
                });

                 // إعادة تعيين المصفوفة بعد تنفيذ الإجراء
                this.selectedItemStatusId = [];


                // Refresh the page after closing the modal
                setTimeout(() => {
                    window.location.reload();
                }, 500);

            } catch (error) {
                console.error('update property error:', error);

                const errorMessage = error.response?.data?.message || 'An error occurred while trying to activate user';

                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });
            }
        },
        async activateItems(selectedIds) {
            try {
                // إنشاء كائن FormData
                const formData = new FormData();
                formData.append('_method', 'PUT');
                formData.append('model', this.modalName);
                formData.append('status', 'active');

                // إضافة العناصر المحددة إلى FormData باستخدام index
                selectedIds.forEach((id, index) => {
                    formData.append(`ids[${index}]`, id);
                });

                const response = await axiosInstance.post('updateStatus', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });


                this.$izitoast.success({
                    title: 'Success',
                    message: 'Users have been successfully activated!',
                    position: 'bottomLeft',
                    timeout: 5000,
                });

                // تحديث الحالة في الجدول
                // this.items.forEach(item => {
                //     if (selectedIds.includes(item.id)) {
                //         item.status = 'active'; // تحديث الحالة
                //         item.selected = false; // إلغاء تحديد العنصر بعد التفعيل
                //     }
                // });
                setTimeout(() => {
                    window.location.reload();
                }, 500);

            } catch (error) {
                console.error('Update property error:', error);

                const errorMessage = error.response?.data?.message || 'An error occurred while trying to activate users';

                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });
            }
        },
        async deactivateItemConfirmed() {
            
            const formData = new FormData();
            this.selectedItemStatusId.forEach((id, index) => {
                formData.append(`ids[${index}]`, id);
            });
            formData.append('status', 'inactive');
            formData.append('model', this.modalName);
            formData.append('deactivation_reason', this.deactivateReason);
            formData.append('_method', 'PUT');

            try {
                
                const response = await axiosInstance.post('updateStatus', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                

                this.$izitoast.success({
                    title: 'Success',
                    message: 'Users have been successfully deactivated!',
                    position: 'bottomLeft',
                    timeout: 5000,
                });

                 // إعادة تعيين المصفوفة بعد تنفيذ الإجراء
                this.selectedItemStatusId = [];
                this.deactivateReason = ''

                $('#deactivate-modal').modal('hide');

                // Refresh the page after closing the modal
                setTimeout(() => {
                    window.location.reload();
                }, 500);

            } catch (error) {
                console.error('update property error:', error);

                const errorMessage = error.response?.data?.message || 'An error occurred while trying to deactivate user';

                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });
            }
        },
        enableDelete(item) {
            this.deleteItem = item; // تخزين العنصر الذي سيتم حذفه
        },
        deleteItemConfirmed() {
            this.items = this.items.filter(i => i.id !== this.deleteItem.id); // حذف العنصر
            this.deleteItem = {}; // إعادة تعيين العنصر المحذوف
            $('#delete-modal').modal('hide'); // إخفاء المودال
        },
        deleteSelected() {
            this.items = this.items.filter(item => !item.selected);
            this.selectAll = false;
        },
        onSortChanged(context) {
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleFilesUpload(event) {
            const selectedFiles = Array.from(event.target.files);
            selectedFiles.forEach(file => {
                this.uploadFile(file);
            });
        },
        uploadFile(file) {
            const reader = new FileReader();
            const fileData = {
                name: file.name,
                type: file.type,
                size: file.size,
                progress: 0,
                url: ''
            };
            reader.onload = (e) => {
                fileData.url = e.target.result;
            };
            reader.readAsDataURL(file);

            // محاكاة عملية الرفع وإظهار شريط التقدم
            const fakeUpload = setInterval(() => {
                if (fileData.progress >= 100) {
                    clearInterval(fakeUpload);
                } else {
                    fileData.progress += 10;
                }
            }, 100);

            this.files.push(fileData);
        },
        removeFile(index) {
            this.files.splice(index, 1);
        },
        createNewRealStateUnit() {
            this.$nextTick(() => {
                this.$router.push('/' + this.$i18n.locale + '/units/create');
            });
        },
        handleEditClick(item) {
            this.$nextTick(() => {
                this.$router.push('/' + this.$i18n.locale + '/units/edit/' + item.id);
            });
        },
        // handleUnitClick(item) {
        //     this.$nextTick(() => {
        //     this.$router.push('/' + this.$i18n.locale + '/units/propertyUnits/' + item.id);
        //     });
        // },
        handleViewClick(item){
            this.$nextTick(() => {
            this.$router.push('/' + this.$i18n.locale + '/units/show/' + item.id);
            });
        },
        
        async featchData() {
            this.isBusy = true;
            try {
                const response = await axiosInstance.get('units', {
                    params: {
                        page: this.currentPage,
                        paginated: this.perPage,
                    }
                });



                // this.items = [];
                // this.items = response.data.data;

                this.items = [...response.data.data.map(item => {
                    item.selected = false;
                    item.statusArabic = item.status === 'active' ? 'نشط' : 'غير نشط';
                    return item;
                })];
                this.modalName = response.data.meta.model_name;
                this.activeCount = response.data.meta.active_count;
                this.inactiveCount = response.data.meta.inactive_count;
                this.totalRealEstateUnits = response.data.meta.total_count;
                
                // this.$refs.table.refresh();

                //this.$refs.table.refresh()

                this.currentPage = response.data.meta.current_page;
                this.perPage = response.data.meta.per_page;
                this.totalRows = response.data.meta.total;

                this.isBusy = false;


            } catch (error) {
                console.error('Login error:', error);
                this.isBusy = false
            }
        },
        changePage(page) {
            this.currentPage = page;
            this.featchData();
        },
        onPerPageChange() {
            this.currentPage = 1;
            this.featchData();
        },
    },
    mounted() {
        this.featchData();
        this.totalRows = this.items.length;
    }
};
</script>

<style scoped>
.bg-light {
    background-color: rgba(249, 250, 252, 1) !important;
    min-height: 100%;
}

.jadda-pattern-dark {
    background-image: url(../../assets/pattren-dark.svg);
    background-size: 150px;
}

.card-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
    /* color: var(--bs-card-cap-color); */
    /* background-color: var(--bs-card-cap-bg); */
    border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;
}

.card-header:first-child {
    border-radius: 0.6875rem 0.6875rem 0 0;
}

.page-header-content {
    padding-right: 20px;
    padding-left: 20px;
}

.page-header-title {
    margin-bottom: .5rem;
}

.page-header-text {
    margin-bottom: .25rem;
    color: #677788;
    font-size: 14px
}

/* .dropdown-menu.show {
    overflow: scroll !important;
    height: 300px !important;
} */
</style>
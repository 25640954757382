<template>
    <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
        <div class="content-card">
            <div class="card-header bg-light jadda-pattern-dark">
                <div class="page-header-content d-flex gap-2 align-items-center justify-content-between">
                    <h4 class="page-header-title">{{ $t('message.modifyUnitData') }}</h4>
                    <a class="back-btn" @click="goBack"><b-icon icon="arrow-left" aria-hidden="true"></b-icon></a>
                    <!-- <p class="page-header-text">{{ $t('message.modifyUnitDataParagraph') }}</p> -->
                </div>
            </div>
            <!-- <div class="container"> -->
            <div class="row">
                <div class="col-md-3" :class="[{ 'ps-md-0-rtl': isRTL, 'ps-md-0-ltr': !isRTL }]">
                    <div class="stepper-wrapper bg-light">
                        <div class="stepper d-flex flex-column">
                            <div v-for="step in steps" :key="step.id"
                                :class="['stepper-item', { 'completed': step.completed, 'active': step.id === currentStep }]">
                                <div class="step-counter">{{ step.id }}</div>
                                <div class="step-name">{{ step.title }}</div>
                                <div class="step-name">{{ step.content }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9" :class="[{ 'pe-md-0-rtl': isRTL, 'pe-md-0-ltr': !isRTL }]">
                    <div class="step-content">
                        <keep-alive>
                            <div v-if="currentStep === 1">
                                <!-- محتوى الخطوة 1 -->
                                <h5 class="mb-0">{{ $t('message.unitData') }}</h5>
                                <p>{{ $t('message.manageBasicInformationAboutRealEstateUnit') }}</p>

                                <div class="mt-5">
                                    <div class="row justify-content-start">
                                        <div class="mb-3 col-md-3 col-6">
                                            <div>
                                                <label for="associationSelect" class="form-label">{{
                                                    $t('message.associationSelect') }}</label>
                                                <v-select :dir="direction" v-model="form.selectedAssociationId"
                                                    :options="associations" :disabled="!enableInput"
                                                    @input="getpropertys()"
                                                    :class="{ 'is-invalid': errors.selectedAssociationId }"
                                                    :placeholder="$t('message.associationSelect')"
                                                    id="multiple-select-custom-field"
                                                    @search:blur="validateField('selectedAssociationId')"
                                                    @search:focus="clearError('selectedAssociationId')"></v-select>
                                                <div class="invalid-feedback" v-if="errors.selectedAssociationId">{{
                                                    errors.selectedAssociationId
                                                    }}</div>
                                            </div>
                                        </div>

                                        <div class="mb-3 col-md-3 col-6">
                                            <div>
                                                <label for="propertyNumberSelect" class="form-label">{{
                                                    $t('message.propertyNumberSelect') }}</label>
                                                <v-select :dir="direction" v-model="form.propertyNumberSelect"
                                                    :options="properties" :disabled="!enableInput"
                                                    :class="{ 'is-invalid': errors.propertyNumberSelect }"
                                                    :placeholder="$t('message.propertyNumberSelect')"
                                                    id="multiple-select-custom-field"
                                                    @search:blur="validateField('propertyNumberSelect')"
                                                    @search:focus="clearError('propertyNumberSelect')"></v-select>
                                                <div class="invalid-feedback" v-if="errors.propertyNumberSelect">{{
                                                    errors.propertyNumberSelect
                                                    }}</div>
                                            </div>
                                        </div>


                                        <div class="mb-3 col-md-3 col-6">
                                            <label for="unitNumber" class="form-label">{{ 'رقم الوحدة العقارية'
                                                }}</label>
                                            <input type="number" placeholder='رقم الوحدة العقارية'
                                                :class="{ 'is-invalid': errors.unitNumber }"
                                                class="form-control form-control-sm" id="unitNumber"
                                                v-model="form.unitNumber" @blur="validateField('unitNumber')"
                                                :disabled="!enableInput" @focus="clearError('unitNumber')" required>
                                            <div class="invalid-feedback" v-if="errors.unitNumber">{{ errors.unitNumber
                                                }}
                                            </div>
                                        </div>
                                        <div class="mb-3 col-md-3 col-6">
                                            <label for="descriptionUnit" class="form-label">{{ 'وصف الوحدة العقارية'
                                                }}</label>
                                            <textarea placeholder='وصف الوحدة العقارية'
                                                :class="{ 'is-invalid': errors.descriptionUnit }"
                                                class="form-control form-control-sm" id="descriptionUnit"
                                                v-model="form.descriptionUnit" @blur="validateField('descriptionUnit')"
                                                :disabled="!enableInput" @focus="clearError('descriptionUnit')" rows="1"
                                                required>
                                        </textarea>
                                            <div class="invalid-feedback" v-if="errors.descriptionUnit">{{
                                                errors.descriptionUnit }}</div>
                                        </div>

                                        <div class="mb-3 col-md-3 col-6">
                                            <label for="space" class="form-label">{{ $t('message.area') }}</label>
                                            <input type="number" :placeholder="$t('message.area')"
                                                :class="{ 'is-invalid': errors.space }"
                                                class="form-control form-control-sm" id="space" v-model="form.space"
                                                @blur="validateField('space')" :disabled="!enableInput"
                                                @focus="clearError('space')" required>
                                            <div class="invalid-feedback" v-if="errors.space">{{ errors.space }}</div>
                                        </div>

                                        <div class="mb-3 col-md-3 col-6">
                                            <label for="unitCode" class="form-label">{{ 'كود الوحدة' }}</label>
                                            <input type="text" placeholder='كود الوحدة'
                                                :class="{ 'is-invalid': errors.unitCode }"
                                                class="form-control form-control-sm" id="unitCode"
                                                :disabled="!enableInput" v-model="form.unitCode"
                                                @blur="validateField('unitCode')" @focus="clearError('unitCode')"
                                                required>
                                            <div class="invalid-feedback" v-if="errors.unitCode">{{ errors.unitCode }}
                                            </div>
                                        </div>

                                        <!-- <div class="mb-3 col-md-3 col-6">
                                    <label for="ownershipercentage" class="form-label">{{'نسبه تملكة' }}</label>
                                    <input type="number" placeholder= 'نسبه تملكة'
                                        :class="{ 'is-invalid': errors.ownershipercentage }"
                                        class="form-control form-control-sm" id="ownershipercentage"
                                        v-model="form.ownershipercentage" @blur="validateField('ownershipercentage')"
                                        @focus="clearError('ownershipercentage')" required>
                                    <div class="invalid-feedback" v-if="errors.ownershipercentage">{{errors.ownershipercentage }}</div>
                                </div> -->

                                        <!-- <div class="mb-3 col-md-3 col-6">
                                        <label for="ownedSpace" class="form-label">{{ 'المساحة المملوكة' }}</label>
                                        <input type="number" placeholder='المساحة المملوكة'
                                            :class="{ 'is-invalid': errors.ownedSpace }"
                                            class="form-control form-control-sm" id="ownedSpace"
                                            v-model="form.ownedSpace" @blur="validateField('ownedSpace')"
                                            @focus="clearError('ownedSpace')" required>
                                        <div class="invalid-feedback" v-if="errors.ownedSpace">{{ errors.ownedSpace }}
                                        </div>
                                    </div> -->

                                        <div class="mb-3 col-md-3 col-6">
                                            <label for="FloorNumber" class="form-label">{{ 'رقم الطابق' }}</label>
                                            <input type="number" placeholder='رقم الطابق'
                                                :class="{ 'is-invalid': errors.FloorNumber }"
                                                class="form-control form-control-sm" id="FloorNumber"
                                                v-model="form.FloorNumber" :disabled="!enableInput"
                                                @blur="validateField('FloorNumber')" @focus="clearError('FloorNumber')"
                                                required>
                                            <div class="invalid-feedback" v-if="errors.FloorNumber">{{
                                                errors.FloorNumber }}
                                            </div>
                                        </div>

                                        <div class="mb-3 col-md-3 col-6">
                                            <label for="bathroomsNumber" class="form-label">{{ 'عدد الحمامات' }}</label>
                                            <input type="number" placeholder='عدد الحمامات'
                                                :class="{ 'is-invalid': errors.bathroomsNumber }"
                                                class="form-control form-control-sm" id="bathroomsNumber"
                                                v-model="form.bathroomsNumber" :disabled="!enableInput"
                                                @blur="validateField('bathroomsNumber')"
                                                @focus="clearError('bathroomsNumber')" required>
                                            <div class="invalid-feedback" v-if="errors.bathroomsNumber">{{
                                                errors.bathroomsNumber }}
                                            </div>
                                        </div>


                                        <div class="mb-3 col-md-3 col-6">
                                            <label for="bedroomsNumber" class="form-label">{{ 'عدد غرف النوم' }}</label>
                                            <input type="number" placeholder='عدد غرف النوم'
                                                :class="{ 'is-invalid': errors.bedroomsNumber }"
                                                class="form-control form-control-sm" id="bedroomsNumber"
                                                v-model="form.bedroomsNumber" :disabled="!enableInput"
                                                @blur="validateField('bedroomsNumber')"
                                                @focus="clearError('bedroomsNumber')" required>
                                            <div class="invalid-feedback" v-if="errors.bedroomsNumber">{{
                                                errors.bedroomsNumber
                                                }}</div>
                                        </div>


                                        <div class="mb-3 col-md-3 col-6">
                                            <label for="northBoundary" class="form-label">{{
                                                $t('message.northernBoundary')
                                                }}</label>
                                            <input type="text" :placeholder="$t('message.northernBoundary')"
                                                :class="{ 'is-invalid': errors.northBoundary }"
                                                class="form-control form-control-sm" id="northBoundary"
                                                v-model="form.northBoundary" :disabled="!enableInput"
                                                @blur="validateField('northBoundary')"
                                                @focus="clearError('northBoundary')" required>
                                            <div class="invalid-feedback" v-if="errors.northBoundary">{{
                                                errors.northBoundary }}
                                            </div>
                                        </div>

                                        <div class="mb-3 col-md-3 col-6">
                                            <label for="southBoundary" class="form-label">{{
                                                $t('message.southernBoundary')
                                                }}</label>
                                            <input type="text" :placeholder="$t('message.southernBoundary')"
                                                :class="{ 'is-invalid': errors.southBoundary }"
                                                class="form-control form-control-sm" id="southBoundary"
                                                v-model="form.southBoundary" :disabled="!enableInput"
                                                @blur="validateField('southBoundary')"
                                                @focus="clearError('southBoundary')" required>
                                            <div class="invalid-feedback" v-if="errors.southBoundary">{{
                                                errors.southBoundary }}
                                            </div>
                                        </div>

                                        <div class="mb-3 col-md-3 col-6">
                                            <label for="eastBoundary" class="form-label">{{ $t('message.eastBoundary')
                                                }}</label>
                                            <input type="text" :placeholder="$t('message.eastBoundary')"
                                                :class="{ 'is-invalid': errors.eastBoundary }"
                                                class="form-control form-control-sm" id="eastBoundary"
                                                v-model="form.eastBoundary" :disabled="!enableInput"
                                                @blur="validateField('eastBoundary')"
                                                @focus="clearError('eastBoundary')" required>
                                            <div class="invalid-feedback" v-if="errors.eastBoundary">{{
                                                errors.eastBoundary
                                                }}</div>
                                        </div>

                                        <div class="mb-3 col-md-3 col-6">
                                            <label for="westBoundary" class="form-label">{{ $t('message.westBoundary')
                                                }}</label>
                                            <input type="text" :placeholder="$t('message.westBoundary')"
                                                :class="{ 'is-invalid': errors.westBoundary }"
                                                class="form-control form-control-sm" id="westBoundary"
                                                v-model="form.westBoundary" :disabled="!enableInput"
                                                @blur="validateField('westBoundary')"
                                                @focus="clearError('westBoundary')" required>
                                            <div class="invalid-feedback" v-if="errors.westBoundary">{{
                                                errors.westBoundary
                                                }}</div>
                                        </div>

                                        <div class="mb-3 col-md-3 col-6">
                                            <label for="status" class="form-label">{{ $t('message.status') }}</label>
                                            <toggle-button 
                                                style="display: block;"
                                                class="form-control form-control-sm"
                                                :key="toggleKey"
                                                :disabled="!enableInput"
                                                v-model="isActive"
                                        />
                                
                                        </div>

                                        <div v-if="!isActive" class="mb-3 col-md-3 col-6">
                                                <label for="deactivateReason" class="form-label">{{ ' سبب الإيقاف' }}</label>
                                                <textarea placeholder=' سبب الإيقاف'
                                                    :class="{ 'is-invalid': deactivateReasonError }"
                                                    :disabled="!enableInput"
                                                    class="form-control form-control-sm" id="deactivateReason"
                                                    v-model="form.deactivateReason" @input="validateDeactivateReason" rows="1" required>
                                                </textarea>

                                                    <div v-if="deactivateReasonError" class="invalid-feedback">يجب أن يحتوي الإدخال على 10 حرفًا على الأقل
                                                    وأن يكون حروفًا
                                                    فقط.</div>
                                        
                                        
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </keep-alive>

                        <div v-if="currentStep === 2">
                            <h5 class="mb-0">{{ 'الملاك' }}</h5>
                            <p>{{ $t('message.realEstateManagementOptions') }} - <strong> {{  'إجمالى نسب الملاك لا يجب أن يتعدى 100%' }}</strong> </p>

                            <div class="mt-3 stepTwoContent">
                                <div class="row">
                                    <div class="mb-3 col-md-9 col-6">
                                        <div>
                                            <label for="legalOwnership" class="form-label">{{ 'اسم المالك' }}</label>
                                            <v-select :dir="direction" v-model="form.legalOwnership" :options="managers"
                                                :class="{ 'is-invalid': errors.legalOwnership }" multiple
                                                :placeholder="$t('message.selectLegalOwnership')"
                                                id="multiple-select-custom-field" :disabled="!enableInput"
                                                @search:blur="validateField('legalOwnership')"
                                                @search:focus="clearError('legalOwnership')"></v-select>
                                            <div class="invalid-feedback" v-if="errors.legalOwnership">{{
                                                errors.legalOwnership
                                                }}</div>
                                        </div>
                                    </div>

                                    <div class="mb-3 col-md-3 col-6">
                                        <button :disabled="!enableInput" type="button"
                                            class="btn btn-success addUserBtn btn-sm" data-bs-toggle="modal"
                                            data-bs-target="#addnewusermodel">
                                            {{ $t('message.AddNewOwner') }}
                                        </button>

                                        <div class="modal fade " id="addnewusermodel" tabindex="-1"
                                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered scrollable">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h4 class="modal-title" id="exampleModalLabel">
                                                            {{ $t('message.AddNewOwner') }}</h4>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">



                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="idNumber" class="form-label">{{ 'رقم الهوية'
                                                                }}</label>
                                                            <input type="text" placeholder='رقم الهوية'
                                                                :class="{ 'is-invalid': errors.idNumber }"
                                                                class="form-control form-control-sm" id="idNumber"
                                                                v-model="form.idNumber"
                                                                @blur="validateField('idNumber')"
                                                                @focus="clearError('idNumber')" required>
                                                            <div class="invalid-feedback" v-if="errors.idNumber">{{
                                                                errors.idNumber }}</div>
                                                        </div>

                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="userName" class="form-label">{{ 'الاسم'
                                                                }}</label>
                                                            <input type="text" placeholder='الاسم'
                                                                :class="{ 'is-invalid': errors.userName }"
                                                                class="form-control form-control-sm" id="userName"
                                                                v-model="form.userName"
                                                                @blur="validateField('userName')"
                                                                @focus="clearError('userName')" required>
                                                            <div class="invalid-feedback" v-if="errors.userName">{{
                                                                errors.userName }}</div>
                                                        </div>


                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="email" class="form-label">{{ 'البريد الإلكتروني'
                                                                }}</label>
                                                            <input type="email" :placeholder="'البريد الإلكتروني'"
                                                                :class="{ 'is-invalid': touched.email && errors.email }"
                                                                class="form-control form-control-sm" id="email"
                                                                v-model="form.email" @blur="validateField('email')"
                                                                @focus="clearError('email')" required>
                                                            <div class="invalid-feedback"
                                                                v-if="touched.email && errors.email">{{ errors.email
                                                                }}</div>
                                                        </div>

                                                        <div class="mb-3 col-md-12 col-6" style="position: relative;">
                                                            <label for="password" class="form-label">{{ 'الرقم السرى'
                                                                }}</label>
                                                            <b-form-input :type="passwordReveal ? 'text' : 'password'"
                                                                :placeholder="'الرقم السرى'"
                                                                :class="{ 'is-invalid': errors.password }"
                                                                class="form-control form-control-sm" id="password"
                                                                v-model="form.password"
                                                                @blur="validateField('password')"
                                                                @focus="clearError('password')" required>
                                                            </b-form-input>
                                                            <b-icon class="toggle-password"
                                                                :class="[{ 'toggle-rtl': isRTL, 'toggle-ltr': !isRTL }]"
                                                                @click="togglePasswordVisibility"
                                                                :icon="passwordReveal ? 'eye-slash' : 'eye'"></b-icon>

                                                            <div class="invalid-feedback" v-if="errors.password">{{
                                                                errors.password
                                                                }}</div>
                                                        </div>

                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="phoneNumber" class="form-label">{{ 'رقم الهاتف'
                                                                }}</label>
                                                            <input type="number" placeholder="رقم الهاتف"
                                                                :class="{ 'is-invalid': touched.phoneNumber && errors.phoneNumber }"
                                                                class="form-control form-control-sm" id="phoneNumber"
                                                                v-model="form.phoneNumber"
                                                                @blur="validateField('phoneNumber')"
                                                                @focus="clearError('phoneNumber')" required>
                                                            <div class="invalid-feedback"
                                                                v-if="touched.phoneNumber && errors.phoneNumber">{{
                                                                    errors.phoneNumber }}</div>
                                                        </div>







                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-white"
                                                            data-bs-dismiss="modal">{{ $t('message.CancelButton')
                                                            }}</button>
                                                        <button type="button" @click="submitModal()"
                                                            :disabled="!isModalValid" class="btn btn-logo">{{
                                                                $t('message.saveAndInsert') }}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Ownership percentage inputs -->
                                    <label v-if="this.ownershipPercentages.length > 0" style="color: #1e2022;" :for="'ownership' + index">{{ 'نسبة التملك' }}</label>
                                    <div v-for="(owner, index) in ownershipPercentages" :key="index"
                                        class="mt-2 mb-3 col-md-4 col-6">
                                        <label :for="'ownership' + index">{{ form.legalOwnership[index]?.label }}</label>
                                        <input type="number" :disabled="!enableInput" class="form-control"
                                            v-model.number="owner.percentage" @input="adjustRemainingPercentages(index)"
                                            :id="'ownership' + index" min="0" max="100">
                                    </div>
                                    <div v-if="totalPercentage > 100" class="text-danger mt-2">
                                        {{ 'إجمالي النسب المئوية يتجاوز 100٪. يرجى ضبط النسب.' }}
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div v-if="currentStep === 3">

                            <h5 class="mb-0">{{ $t('message.attachments') }}</h5>
                            <p>{{ $t('message.attachmentsParagraph') }}</p>

                            <div class="mb-5">

                                <div id="attachFilesNewProjectLabel"
                                    class="js-dropzone dz-dropzone dz-dropzone-card mb-4" @dragenter.prevent
                                    @dragover.prevent @drop.prevent="handleFileDrop">
                                    <div class="dz-message">
                                        <h5>{{ $t('message.dragAndDrop') }}
                                        </h5>
                                        <p class="mb-2">{{ $t('message.or') }}</p>
                                        <span class="btn btn-white btn-sm" @click="triggerFileInput">{{
                                            $t('message.chooseFiles') }}</span>

                                        <div class="uploaded-files">
                                            <div v-for="(file) in files" :key="file.id" class="file-item col-md-3">
                                                <div style="text-align: center;"
                                                    v-if="file && file.mime_type && file.mime_type.includes('image')">
                                                    <img :src="file.url" class="uploaded-image"
                                                        @click="openInNewTab(file.url, 'image')" />
                                                    <div class="limit-name">{{ file.name }}</div>
                                                </div>
                                                <div v-else>
                                                    <img class="uploaded-image"
                                                        :src="require(`../../assets/${getIconNameForFileType(file.type)}`)"
                                                        alt="File Icon" @click="openInNewTab(file.url, file.type)" />
                                                    <div class="limit-name">{{ file.name }}</div>
                                                </div>
                                                <progress class="progress-bar bg-success" :value="file.progress"
                                                    max="100"></progress>
                                                <span>{{ (file.size / 1024).toFixed(2) }} KB</span>
                                                <button @click="removeFile(file.id)" class="btn-sm">X</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <input :disabled="!enableInput" type="file" ref="fileInput" @change="handleFilesUpload"
                                multiple style="display: none;" />


                        </div>



                    </div>
                    <div class="modal fade " id="gobackmodel" ref="gobackmodel" tabindex="-1"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered scrollable">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title" id="exampleModalLabel">
                                        {{ $t('message.back') }}</h4>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-body">

                                    <p>{{ $t('message.warningLoseData') }}</p>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-white" data-bs-dismiss="modal">{{
                                        $t('message.CancelButton') }}</button>
                                    <button type="button" @click="confirmBack" class="btn btn-logo">
                                        {{ $t('message.confirmBack') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-3 action-content">
                        <button class="btn btn-ghost-secondary" @click="prevStep" :disabled="currentStep === 1"><i
                                v-if="isRTL" class="bi-chevron-right small"></i><i v-else
                                class="bi-chevron-left small"></i> {{ $t('message.previous') }} </button>

                        <div>
                            <button class="btn btn-logo" style="margin: 0px 5px;" @click="toggleEditMode">{{ enableInput
                                ? 'قفل' : 'تحرير'
                                }}</button>

                            <button v-if="this.currentStep < this.steps.length" class="btn btn-logo" @click="nextStep"
                                :disabled="!isStepValid">{{ $t('message.next') }} <i v-if="isRTL"
                                    class="bi-chevron-left small"></i><i v-else
                                    class="bi-chevron-right small"></i></button>
                            <button v-else class="btn btn-logo" @click="sendForm()"
                                :disabled="!isStepValid || !updateSucess">{{
                                    $t('message.save') }}</button>

                        </div>


                    </div>
                </div>

            </div>
        </div>
        <!-- </div> -->



    </div>
</template>

<script>

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import axiosInstance from '../../axios-config';




export default {
    name: 'RealEstateUnitManagement',
    props: ['id'],
    components: {
        vSelect
    },
    data() {
        return {
            updateSucess: false,
            enableInput: false,
            currentStep: 1,
            ownershipPercentages: [],
            files: [],
            passwordReveal: false,
            steps: [
                { id: 1, title: this.$t('message.unitData'), completed: false },
                { id: 2, title: 'الملاك', completed: false },
                { id: 3, title: this.$t('message.attachments'), completed: false },

            ],
            form: {
                unitNumber: '',
                descriptionUnit: '',
                space: '',
                legalOwnership: [],
                selectedAssociationId: {},
                propertyNumberSelect: '',
                unitCode: '',
                // ownerName1: '',
                bathroomsNumber: '',
                FloorNumber: '',
                bedroomsNumber: '',
                // ownershipercentage: '',
                // ownedSpace: '',
                northBoundary: '',
                southBoundary: '',
                eastBoundary: '',
                westBoundary: '',
                deactivateReason: '',

                // for modal 
                idNumber: '',
                userName: '',
                email: '',
                password: '',
                phoneNumber: '',
                //end modal
            },

            managers: [],
            associations: [],
            properties: [],
            errors: {
                unitNumber: '',
                descriptionUnit: '',
                space: '',
                legalOwnership: '',
                selectedAssociationId: '',
                propertyNumberSelect: '',
                unitCode: '',
                // ownerName1: '',
                bathroomsNumber: '',
                bedroomsNumber: '',
                FloorNumber: '',
                // ownershipercentage: '',
                // ownedSpace: '',
                northBoundary: '',
                southBoundary: '',
                eastBoundary: '',
                westBoundary: '',
                deactivateReason: '',

                // for modal
                idNumber: '',
                userName: '',
                email: '',
                password: '',
                phoneNumber: '',
                //  end modal

            },
            touched: {
                email: false,
                phoneNumber: false,
            },
            isActive: false,
            toggleKey: 0,
            deactivateReasonError: false,
        }
    },
    mounted() {
        this.getusersRole();
        this.getAssociationsId();
        // this.getPropertiesId();




        // if (this.isEditMode === false) {
        //     this.enableInput = true;
        // }

    },
    computed: {
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
        direction() {
            return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
        },
        isModalValid() {
            return this.form.idNumber && this.form.userName && this.form.email &&
                this.form.password && this.form.phoneNumber;
        },
        totalPercentage() {
            return this.ownershipPercentages.reduce((total, owner) => total + owner.percentage, 0);
        },
        isStepValid() {
            // تحقق من صحة الخطوة الحالية
            if (this.currentStep === 1) {
                const step1Fields = [
                    'unitNumber',
                    'descriptionUnit',
                    'space',
                    'selectedAssociationId',
                    'propertyNumberSelect',
                    'unitCode',
                    // 'ownerName1',
                    'bathroomsNumber',
                    'FloorNumber',
                    'bedroomsNumber',
                    // 'ownershipercentage',
                    // 'ownedSpace',
                    'northBoundary',
                    'southBoundary',
                    'eastBoundary',
                    'westBoundary',
                ];
                // تحقق من عدم وجود أخطاء في الحقول الخاصة بالخطوة 1
                const step1Errors = step1Fields.some(field => this.errors[field]);

                // تحقق من ملء جميع الحقول الخاصة بالخطوة 1
                const step1Filled = step1Fields.every(field => this.form[field] !== '');

                // تحقق من أن selectedAssociationId ليس فارغًا أو null
                if (this.form.selectedAssociationId === '' || this.form.selectedAssociationId === null) {
                    return false;
                }
                // تحقق من أن propertyNumberSelect ليس فارغًا أو null
                if (this.form.propertyNumberSelect === '' || this.form.propertyNumberSelect === null) {
                    return false;
                }

                // التحقق من حالة isActive
                if (!this.isActive) {
                    const deactivateReason = this.form.deactivateReason ? this.form.deactivateReason.trim() : ''; // التحقق من القيمة
                    if (deactivateReason.length < 10) {
                        this.errors.deactivateReason = true; // تحديد الخطأ
                        return false;
                    } else {
                        this.errors.deactivateReason = false; // لا يوجد خطأ
                    }
                }

                return !step1Errors && step1Filled;

                // return Object.keys(this.errors).length === 0 && Object.values(this.form).every(value => value !== '');
            }
            // تحقق صحة الخطوة 2
            if (this.currentStep === 2) {

                if (this.form.legalOwnership.length === 0) {
                    return false;
                }

                // تحقق من أن أي مالك ليس لديه نسبة ملكية 0 أو نسبة ملكية فارغة
                const hasInvalidPercentage = this.ownershipPercentages.some(owner =>
                    owner.percentage === 0 || owner.percentage === '' || owner.percentage == null
                );

                if (hasInvalidPercentage) {
                    return false;
                }

                // تحقق من أن مجموع النسب المئوية يساوي 100
                const totalPercentage = this.ownershipPercentages.reduce((total, owner) => {
                    return total + parseFloat(owner.percentage || 0);
                }, 0);

                if (totalPercentage !== 100) {
                    return false;
                }

            }


            return true;
        }
    },
    watch: {
        currentStep() {
            if (this.currentStep === 2) {
                this.getStep2();
            }
        },
        'form.legalOwnership': {
            handler() {
                this.updateOwnershipPercentages();
            },
            deep: true
        },
        ownershipPercentages: {
            handler() {
                // Ensure total does not exceed 100%
                this.ownershipPercentages.forEach((owner, index) => {
                    if (this.totalPercentage > 100) {
                        this.adjustRemainingPercentages(index);
                    }
                });
            },
            deep: true
        },
        editUnitId: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.isEditMode = true;
                    this.fetchPropertyData(newVal);
                } else {
                    this.isEditMode = false;
                    // this.resetForm();
                }
            }
        }
    },
    methods: {
        goToStep(step) {
            if (step <= this.currentStep) {
                this.currentStep = step;
            }
        },
        togglePasswordVisibility() {
            this.passwordReveal = !this.passwordReveal;
        },
        nextStep() {
            if (this.currentStep < this.steps.length) {
                this.steps[this.currentStep - 1].completed = true;
                this.currentStep++;
            }
            else if (this.currentStep == this.steps.length) {
            }
        },
        prevStep() {
            if (this.currentStep > 1) {
                this.currentStep--;
            }
        },
        toggleEditMode() {
            this.enableInput = !this.enableInput;
            this.updateSucess = true;

        },
        isStep1Filled() {
            return ['unitNumber', 'descriptionUnit', 'space', 'selectedAssociationId', 'propertyNumberSelect', 'unitCode', 'bathroomsNumber', 'FloorNumber', 'bedroomsNumber', 'northBoundary']
                .some(field => this.form[field]);
        },
        goBack() {
            if (this.isStep1Filled()) {
                $('#gobackmodel').modal('show');
            } else {
                window.history.back();
            }
        },
        confirmBack() {
            $('#gobackmodel').modal('hide');
            window.history.back();
        },
        getIconNameForFileType(mimeType) {
            const type = mimeType.split('/')[1];

            if (type === 'pdf') {
                return 'pdf.png'; // أيقونة ملفات PDF
            } else if (type.includes('wordprocessingml.document')) {
                return 'doc.png'; // أيقونة ملفات Word
            } else if (type.includes('spreadsheetml.sheet')) {
                return 'xls.png'; // أيقونة ملفات Excel
            } else if (type.includes('video')) {
                return 'mp4.png'; // أيقونة ملفات الفيديو
            } else if (type.includes('audio')) {
                return 'mp3.png'; // أيقونة ملفات الصوت
            } else if (type === 'css') {
                return 'css.png'; // أيقونة ملفات CSS
            } else if (type === 'csv') {
                return 'csv.png'; // أيقونة ملفات CSV
            } else if (type === 'dbf') {
                return 'dbf.png'; // أيقونة ملفات DBF
            } else if (type === 'dwg') {
                return 'dwg.png'; // أيقونة ملفات DWG
            } else if (type === 'exe') {
                return 'exe.png'; // أيقونة ملفات EXE
            } else if (type === 'fla') {
                return 'fla.png'; // أيقونة ملفات FLA
            } else if (type === 'html') {
                return 'html.png'; // أيقونة ملفات HTML
            } else if (type === 'iso') {
                return 'iso.png'; // أيقونة ملفات ISO
            } else if (type.includes('javascript')) {
                return 'javascript.png'; // أيقونة ملفات JavaScript
            } else if (type === 'jpeg' || type === 'jpg') {
                return 'jpg.png'; // أيقونة ملفات JPEG
            } else if (type === 'json') {
                return 'json.png'; // أيقونة ملفات JSON
            } else if (type === 'png') {
                return 'png.png'; // أيقونة ملفات PNG
            } else if (type === 'ppt' || type.includes('presentation')) {
                return 'ppt.png'; // أيقونة ملفات PowerPoint
            } else if (type === 'psd') {
                return 'psd.png'; // أيقونة ملفات Photoshop
            } else if (type === 'rtf') {
                return 'rtf.png'; // أيقونة ملفات RTF
            } else if (type === 'svg') {
                return 'svg.png'; // أيقونة ملفات SVG
            } else if (type === 'txt') {
                return 'txt.png'; // أيقونة ملفات TXT
            } else if (type === 'xls' || type.includes('excel')) {
                return 'xls.png'; // أيقونة ملفات Excel
            } else if (type === 'xml') {
                return 'xml.png'; // أيقونة ملفات XML
            } else if (type === 'zip') {
                return 'zip.png'; // أيقونة ملفات ZIP
            }
        },
        updateOwnershipPercentages() {
            const totalOwners = this.form.legalOwnership.length;

            // Ensure ownershipPercentages array matches the selected owners
            this.ownershipPercentages = this.form.legalOwnership.map((owner, index) => ({
                name: owner.label, // Accessing the label of the owner
                percentage: this.ownershipPercentages[index]?.percentage || 0
            }));
        },
        adjustRemainingPercentages(index) {
            if (this.totalPercentage > 100) {
                this.ownershipPercentages[index].percentage -= (this.totalPercentage - 100);
            }
        },
        validateField(field) {

            if (!this.form[field] || this.form[field].length === 0) {
                this.$set(this.errors, field, this.$t('message.errorHandle'));
            }
            if (field === 'email') {
                this.touched.email = true;
                this.validateEmail();
            } else if (field === 'phoneNumber') {
                this.touched.phoneNumber = true;
                // this.validatePhoneNumber();
            }
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleFilesUpload(event) {
            const selectedFiles = Array.from(event.target.files);
            selectedFiles.forEach(file => {
                this.uploadFile(file);
            });
        },
        uploadFile(file) {
            const isDuplicate = this.files.some(
                (existingFile) => existingFile.name === file.name && existingFile.size === file.size
            );

            if (isDuplicate) {
                // إذا كان الملف موجودًا بالفعل، لا تفعل شيئًا
                return;
            }
            const reader = new FileReader();
            const fileData = {
                name: file.name,
                type: file.type,
                size: file.size,
                mime_type: file.type, // Ensure mime_type is included
                progress: 0,
                url: '',
                rawFile: file // تخزين الملف الأصلي هنا
            };
            reader.onload = (e) => {
                fileData.url = e.target.result;
            };
            reader.readAsDataURL(file);

            // Simulate upload progress
            const fakeUpload = setInterval(() => {
                if (fileData.progress >= 100) {
                    clearInterval(fakeUpload);
                } else {
                    fileData.progress += 10;
                }
            }, 100);

            this.files.push(fileData);
        },
        openInNewTab(url, type) {
            

            window.open(url, '_blank');

        },
        handleFileDrop(event) {
            event.preventDefault();
            event.stopPropagation(); // منع السلوك الافتراضي
            const droppedFiles = Array.from(event.dataTransfer.files);
            droppedFiles.forEach((file) => {
                this.uploadFile(file);
            });
        },
        removeFile(fileId) {
            // ابحث عن الملف في المصفوفة
            const fileIndex = this.files.findIndex((file) => file.id === fileId);

            if (fileIndex !== -1) {
                const file = this.files[fileIndex];

                if (file.id) {
                    // إذا كان الملف يحتوي على ID، فهو مرفوع على السيرفر
                    this.deleteFileId(fileId); // إرسال طلب الحذف
                } else {
                    // إذا لم يكن لديه ID، فهو ملف جديد لم يُرفع بعد
                    this.files.splice(fileIndex, 1); // حذفه فقط من المصفوفة
                }
            }
        },
        async deleteFileId(fileId) {
            try {

                let url = 'files/deleteFile';

                if (fileId) {
                    url += `/${fileId}`;
                }

                const response = await axiosInstance.post(url, {
                    _method: 'DELETE',
                });


                const fileIndex = this.files.findIndex(file => file.id === fileId);

                if (fileIndex !== -1) {
                    this.files.splice(fileIndex, 1);
                }

                this.$izitoast.success({
                    title: 'Success',
                    message: 'successfully delete file',
                    position: 'bottomLeft',
                    timeout: 5000, // يمكنك تخصيص مدة العرض
                });




            } catch (error) {
                console.error('update user error:', error);

                // Check if the error has a response and show the backend message
                const errorMessage = error.response?.data?.message || 'An error occurred while trying to delete file';

                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        clearError(field) {
            this.$delete(this.errors, field);
        },

        async addNewUser() {

            try {
                const response = await axiosInstance.post('users', {
                    name: this.form.userName,
                    email: this.form.email,
                    identity_number: this.form.idNumber,
                    password: this.form.password,
                    role: 'user',
                    phone_number: this.form.phoneNumber
                });




                this.$izitoast.success({
                    title: 'Success',
                    message: 'successfully add new owner',
                    position: 'bottomLeft',
                    timeout: 5000, // يمكنك تخصيص مدة العرض
                });


                this.form = {
                    idNumber: '',
                    userName: '',
                    email: '',
                    password: '',
                    phoneNumber: '',
                }
                $('#addnewusermodel').modal('hide');

                // Refresh the page after closing the modal
                setTimeout(() => {
                    window.location.reload();
                }, 500);




            } catch (error) {
                console.error('Add user error:', error);

                // Check if the error has a response and show the backend message
                const errorMessage = error.response?.data?.message || 'An error occurred while trying to add the new owner';

                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },

        submitModal() {
            if (this.isModalValid) {
                this.addNewUser();
            }
        },


        getEmptyForm() {

            this.form.selectedAssociationId = '';
            this.form.propertyNumberSelect = '';
            this.form.unitNumber = '';
            this.form.descriptionUnit = '';
            this.form.space = '';
            this.form.unitCode = '';
            this.form.FloorNumber = '';
            this.form.bathroomsNumber = '';
            this.form.bedroomsNumber = '';
            this.form.northBoundary = '';
            this.form.southBoundary = '';
            this.form.eastBoundary = '';
            this.form.westBoundary = '';
        },
        markAllStepsUnCompleted() {
            this.steps = this.steps.map(step => ({ ...step, completed: false }));
        },
        async getusersRole() {
            try {
                const response = await axiosInstance.get('users', {
                    params: {
                        role: 'user',
                    }
                });



                // تخزين البيانات الراجعة في rolesList
                this.managers = response.data.data.map(role => ({
                    label: role.name, // وضع الاسم كاسم العرض
                    value: role.id // استخدام المعرف كالقيمة
                }));



            } catch (error) {
                console.error('error:', error);
                this.$izitoast.error({
                    title: 'Error',
                    message: 'An error occurred while trying to get roles',
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        async getAssociationsId() {
            try {
                const response = await axiosInstance.get('associations');



                // تخزين البيانات الراجعة في rolesList
                this.associations = response.data.data.map(association => ({
                    label: association.name, // وضع الاسم كاسم العرض
                    value: association.id // استخدام المعرف كالقيمة
                }));

                if (this.associations.length !== 0) {
                    this.getData();
                }



            } catch (error) {
                console.error('error:', error);
                this.$izitoast.error({
                    title: 'Error',
                    message: 'An error occurred while trying to get associations id',
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },

        getpropertys() {
            if (this.form.selectedAssociationId.value) {
                this.form.propertyNumberSelect = '';
                this.getPropertiesId(this.form.selectedAssociationId.value);
            }
        },

        async getPropertiesId(associationId) {

            let url = 'associations';

            if (associationId) {
                url += `/${associationId}` + '/properties';
            }
            try {
                const response = await axiosInstance.get(url);



                // تخزين البيانات الراجعة في rolesList
                this.properties = response.data.data.map(property => ({
                    label: property.id, // وضع الاسم كاسم العرض
                    value: property.id // استخدام المعرف كالقيمة
                }));



            } catch (error) {
                console.error('error:', error);
                this.$izitoast.error({
                    title: 'Error',
                    message: 'An error occurred while trying to get properties id',
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        validateDeactivateReason() {
            const reason = this.form.deactivateReason.trim(); // إزالة المسافات الزائدة
            this.deactivateReasonError = reason.length < 10; // تحقق إذا كان أقل من 10 أحرف
        },
        async getData() {

            try {
                let url = 'units';

                // إذا كان id موجودًا، نعدل المسار
                if (this.id) {
                    url += `/${this.id}`;
                }
                const response = await axiosInstance.get(url);




                // Search for the association name based on association_id
                const association = this.associations.find(
                    assoc => assoc.value === response.data.data.property.association_id
                );

                this.form.selectedAssociationId = association ? { label: association.label, value: association.value } : { label: '', value: '' };

                if (this.form.selectedAssociationId.value) {

                    this.getPropertiesId(this.form.selectedAssociationId.value);
                }

                this.form.propertyNumberSelect = response.data.data.property_id;
                this.form.unitNumber = response.data.data.number;
                this.form.descriptionUnit = response.data.data.description;
                this.form.space = response.data.data.area;
                this.form.unitCode = response.data.data.code;
                this.form.FloorNumber = response.data.data.floor;
                this.form.bathroomsNumber = response.data.data.bathrooms;
                this.form.bedroomsNumber = response.data.data.rooms;
                this.form.northBoundary = response.data.data.northern_border;
                this.form.southBoundary = response.data.data.southern_border;
                this.form.eastBoundary = response.data.data.eastern_border;
                this.form.westBoundary = response.data.data.western_border;
                // Extract owners' data
                this.form.legalOwnership = response.data.data.owners.map(owner => ({
                    label: owner.name, // وضع الاسم كاسم العرض
                    value: owner.id // استخدام المعرف كالقيمة
                }));

                // Extract ownership percentages
                this.ownershipPercentages = response.data.data.owners.map(owner => ({
                    percentage: parseFloat(owner.percentage)
                }));

                

                this.files = response.data.data.files.map(file => ({
                    id: file.id,
                    name: file.file_name,  // تأكد من أنك تضيف اسم الملف
                    type: file.mime_type,
                    size: file.size,
                    mime_type: file.mime_type,
                    url: file.url,
                    progress: 100, // يمكنك تعيينها كـ 100 لأن الملفات تم تحميلها بالفعل
                    rawFile: null // لا داعي لتخزين الملف الأصلي في حالة التحديث
                }));
                this.$nextTick(() => { this.isActive = response.data.data.status === 'active'; this.toggleKey += 1;});
                this.form.deactivateReason = response.data.data.deactivation_reason;


                // this.$izitoast.success({
                //     title: 'Success',
                //     message: 'successfully get data',
                //     position: 'bottomLeft',
                //     timeout: 5000, // يمكنك تخصيص مدة العرض
                // });


            } catch (error) {
                console.error('error:', error);
                this.$izitoast.error({
                    title: 'Error',
                    message: 'An error occurred while trying to get data',
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        async getStep2() {

            try {
                let url = 'units';

                // إذا كان id موجودًا، نعدل المسار
                if (this.id) {
                    url += `/${this.id}`;
                }
                const response = await axiosInstance.get(url);




                // Extract owners' data
                this.form.legalOwnership = response.data.data.owners.map(owner => ({
                    label: owner.name, // وضع الاسم كاسم العرض
                    value: owner.id // استخدام المعرف كالقيمة
                }));

                // Extract ownership percentages
                this.ownershipPercentages = response.data.data.owners.map(owner => ({
                    percentage: parseFloat(owner.percentage)
                }));




                // this.$izitoast.success({
                //     title: 'Success',
                //     message: 'successfully get data',
                //     position: 'bottomLeft',
                //     timeout: 5000, // يمكنك تخصيص مدة العرض
                // });


            } catch (error) {
                console.error('error:', error);
                this.$izitoast.error({
                    title: 'Error',
                    message: 'An error occurred while trying to get data',
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },

        async updateUnitData() {

            try {
                let url = 'units';

                // إذا كان editUserId موجودًا، نعدل المسار
                if (this.id) {
                    url += `/${this.id}`;
                }
                // إنشاء كائن FormData
                const formData = new FormData();

                // إضافة الحقول النصية إلى formData
                if (this.form.selectedAssociationId.value == undefined) {
                    formData.append('association_id', this.form.selectedAssociationId);
                } else {
                    formData.append('association_id', this.form.selectedAssociationId.value);
                }


                if (this.form.propertyNumberSelect.label == undefined) {

                    formData.append('property_id', this.form.propertyNumberSelect);
                } else {

                    formData.append('property_id', this.form.propertyNumberSelect.label);
                }
                formData.append('_method', 'PUT'); // إشارة إلى أن هذا الطلب هو تحديث
                formData.append('number', this.form.unitNumber);
                formData.append('description', this.form.descriptionUnit);
                formData.append('area', this.form.space);
                formData.append('code', this.form.unitCode);
                formData.append('floor', this.form.FloorNumber);
                formData.append('bathrooms', this.form.bathroomsNumber);
                formData.append('rooms', this.form.bedroomsNumber);
                formData.append('northern_border', this.form.northBoundary);
                formData.append('southern_border', this.form.southBoundary);
                formData.append('eastern_border', this.form.eastBoundary);
                formData.append('western_border', this.form.westBoundary);
                formData.append('status', this.isActive ? 'active' : 'inactive',);
                if (!this.isActive) {
                    formData.append('deactivation_reason', this.form.deactivateReason);
                }

                // إضافة المالكين مع النسب المئوية الخاصة بهم
                if (this.form.legalOwnership && this.form.legalOwnership.length) {
                    this.form.legalOwnership.forEach((owner, index) => {
                        const ownerId = owner.value; // استخراج الـ id من legalOwnership
                        const ownerPercentage = this.ownershipPercentages[index]?.percentage || 0; // استخراج النسبة من ownershipPercentages

                        // إضافة الـ id والنسبة إلى formData
                        formData.append(`owners[${index}][id]`, ownerId);
                        formData.append(`owners[${index}][percentage]`, ownerPercentage);
                    });
                }


                if (this.files && this.files.length) {
                    this.files.forEach((fileData, index) => {
                        if (fileData.rawFile) {
                            formData.append(`files[${index}]`, fileData.rawFile); // استخدم الملف الأصلي
                        }
                    });
                }
                for (const pair of formData.entries()) {
                }
                // إرسال البيانات باستخدام Axios
                const response = await axiosInstance.post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });



                this.$izitoast.success({
                    title: 'Success',
                    message: 'successfully update unit',
                    position: 'bottomLeft',
                    timeout: 5000, // يمكنك تخصيص مدة العرض
                });

                // this.currentStep = 1;
                // this.markAllStepsUnCompleted();
                // this.getEmptyForm();



                // التحقق إذا كان المستخدم موجود بالفعل على نفس الصفحة قبل إعادة التوجيه
                // const locale = this.$i18n.locale;
                // const targetRoute = '/' + locale + '/units/list';

                // if (this.$route.path !== targetRoute) {

                //     this.$router.push(targetRoute);

                // }

            } catch (error) {
                console.error('Add user error:', error);

                // Check if the error has a response and show the backend message
                const errorMessage = error.response?.data?.message || 'An error occurred while trying to add the new user';

                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        sendForm() {
            this.updateUnitData();
            // this.currentStep = 1;
            // this.markAllStepsUnCompleted();
            // this.getEmptyForm();
        }
    }

}
</script>


<style scoped>
.content-card {
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .075);
    margin-top: 80px;
    padding: 0px;
    background-color: #fff;
    background-clip: border-box;
    border: 0.0625rem solid rgba(231, 234, 243, 0.7);
    border-radius: 0.75rem;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 2rem
}


.stepper-wrapper {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 20px; */
    /* padding-right: 1rem; */
    padding-top: 35px;
    /* padding-bottom: 25px; */
}

.step-content {
    padding: 20px 15px 15px;
    padding-top: 35px;
}

.action-content {
    padding: 20px 15px 15px;
}

.stepper {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
}

.stepper-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
}

.stepper-item::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    height: 20px;
    width: 4px;
    background: #ddd;
    transform: translateX(-50%);
}

.stepper-item:last-child::after {
    display: none;
}

.stepper-item.active .step-counter,
.stepper-item.completed .step-counter {
    background: #0d6efd;
    color: white;
}

.step-counter {
    width: 2.40625rem;
    height: 2.40625rem;
    background: #ddd;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.step-name {
    font-size: 14px;
    text-align: center;
}

.stepper-item.active {
    color: #0d6efd;
}

.form-select {
    width: 70%;
    margin-left: 5px;
}

.form-select-rtl {
    background-position: left 0.75rem center;
}

.jadda-pattern-dark {
    background-image: url(../../assets/pattren-dark.svg);
    background-size: 150px;
}

.card-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
    /* color: var(--bs-card-cap-color); */
    /* background-color: var(--bs-card-cap-bg); */
    border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;
}

.card-header:first-child {
    border-radius: 0.6875rem 0.6875rem 0 0;
}

.page-header-content {
    padding-right: 20px;
    padding-left: 20px;
}

.page-header-title {
    margin-bottom: .5rem;
}

.page-header-text {
    margin-bottom: .25rem;
    color: #677788;
    font-size: 14px
}

.h4,
h4 {
    font-size: .984375rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    /* margin-bottom: .5rem; */
    font-weight: 600;
    line-height: 1.2;
    color: #1E202C;
}

.h5,
h5 {
    font-size: .875rem;
}

.bg-light {
    background-color: rgba(249, 250, 252, 1) !important;
    min-height: 100%;
}

.form-label {
    padding-top: .675rem;
    padding-bottom: .675rem;
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
    color: #1e2022;
}

.form-control {
    border: 1px solid #d0d7db;
}

.form-control-sm {
    min-height: calc(1.5em + 1.125rem);
    padding: .5rem 1rem;
    font-size: .8125rem;
    border-radius: .3125rem;
}

.text-danger {
    color: #ff5252 !important;
    margin-top: 5px;
    font-size: 12px;
}




.invalid-feedback {
    color: #dc3545;
}

.commissions {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 10px;
    margin-left: 10px;
}

.commissions-content {
    margin-top: 12px;
}

.stepTwoContent {
    border: 1px solid #ddd;
    border-radius: .3125rem;
    padding: .6125rem 1rem;
}

.addUserBtn {
    margin-top: 42px;
    width: -webkit-fill-available;
}

.modal-header {
    padding-bottom: 25px;
    background-image: url(../../assets/pattren-dark.svg);
    background-size: 150px;
    background-color: #f9fafc;
}

.btn-close {
    font-size: smaller;
}

.uploaded-files {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 15px;
    justify-content: center;
}

.file-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #dee2e6;
    padding: 7px;
    border-radius: 5px;
    background-color: #f8f9fa;
    width: 22%;
    justify-content: center;
    min-height: 165px;
}

.uploaded-image {
    width: 100px;
    /* width: -webkit-fill-available; */
    height: 90px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 5px;
}

.file-item progress {
    width: 100%;
    margin: 5px 0;
    height: 5px;
    border-radius: 50px;
}

.file-item button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    padding: initial;
}

.limit-name {
    text-align: center;
    width: 150px;
    overflow: hidden;
}

.ps-md-0-rtl {
    padding-left: 0 !important;
}

.ps-md-0-ltr {
    padding-right: 0 !important;
}

.pe-md-0-rtl {
    padding-right: 0 !important;
}

.pe-md-0-ltr {
    padding-left: 0 !important;
}

@media(max-width:1024px) {

    .ps-md-0-ltr,
    .pe-md-0-rtl {
        padding-right: calc(1.5rem * .5) !important;
    }

    .ps-md-0-rtl,
    .pe-md-0-ltr {
        padding-left: calc(1.5rem * .5) !important;
    }
}

.toggle-password {
    position: absolute;
    /* left: 21px; */
    top: 52px;
    z-index: 1000;
    cursor: pointer;
    font-size: 17px;
}

.toggle-rtl {
    left: 21px;
}

.toggle-ltr {
    right: 21px;
}
</style>
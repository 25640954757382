<template>
    <div class="content">
        <div>
            <img class="error-image" alt="404 Error" :src="require(`../assets/404-Error-Page.png`)" />
            <h1> {{ 'خطأ 404' }} </h1>
            <h4> {{ 'الصفحة غير موجودة' }} </h4>
            <h5> {{ $t('message.NotFoundMessage') }} </h5>

            <div class="button-group mt-4">
                <b-button variant="logo" class="mx-2" @click="goToHome">
                العودة إلى الصفحة الرئيسية
                </b-button>
                <b-button variant="outline-logo" class="mx-2" @click="goBack">
                العودة للصفحة السابقة
                </b-button>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    name: "NotFound",
    methods: {
        goToHome() {
            // الانتقال إلى الصفحة الرئيسية
            // this.$router.push({ name: "home" }); // تأكد من أن اسم الراوت للصفحة الرئيسية هو 'home'
            this.$nextTick(() => {
                this.$router.push('/' + this.$i18n.locale);
            //     location.reload();
            });
        },  
        
        goBack() {
            // العودة خطوة للخلف
            this.$router.go(-1);
        },
    },
}
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* يجعل المحتوى في منتصف الصفحة */
  background-color: #fff;
  text-align: center;
}

.error-image {
  width: 400px;
  height: 300px;
  margin-bottom: 20px;
}

h1 {
  font-size: 2rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
}

h4 {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 10px;
}

h5 {
  font-size: 1rem;
  color: #777;
  margin-bottom: 20px;
}

.button-group b-button {
  min-width: 150px;
}
</style>
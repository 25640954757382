<template>
    <div>
        <PropertyWithAssociationSelectionForm :isEditMode="false" />
    </div>
</template>


<script>
import PropertyWithAssociationSelectionForm from '../../components/PropertyWithAssociationSelectionForm.vue';  

export default {
  components: {
    PropertyWithAssociationSelectionForm
  }
};
</script>
<template>
    <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
        <div class="content-card">
            <div class="page-header-content">
                <div class="row mb-3">
                    <div class="col-sm mb-2 mb-sm-0">
                        <div class="d-flex gap-2 align-items-center justify-content-between mb-3">
                            <h1 class="page-header-title mb-0">{{ isEditMode ? 'تعديل بيانات المستخدم' :
                                'إنشاء مستخدم جديد' }}</h1>
                            <a class="back-btn" @click="goBack"><b-icon icon="arrow-left" aria-hidden="true"></b-icon></a>

                        </div>
                        <!-- <p class="page-header-text">{{ isEditMode ? 'يمكنك البدء فى تعديل بيانات المستخدم من هنا.' :
                            'يمكنك البدء في إنشاء مستخدم جديد من هنا.' }}</p> -->
                    </div>
                </div>
                <!-- End Row -->
                <div class="step-content">
                    <div>
                        <div class="mt-5">
                            <div class="row justify-content-start">

                                <div class="mb-3 col-md-4 col-6">
                                    <label for="idNumber" class="form-label">{{ 'رقم الهوية' }}</label>
                                    <input type="text" placeholder='رقم الهوية'
                                        :class="{ 'is-invalid': errors.idNumber }" class="form-control form-control-sm"
                                        id="idNumber" :disabled="!enableInput" v-model="form.idNumber"
                                        @blur="validateField('idNumber')" @focus="clearError('idNumber')" required>
                                    <div class="invalid-feedback" v-if="errors.idNumber">{{ errors.idNumber }}</div>
                                </div>

                                <div class="mb-3 col-md-4 col-6">
                                    <label for="userName" class="form-label">{{ 'الاسم' }}</label>
                                    <input type="text" placeholder='الاسم' :class="{ 'is-invalid': errors.userName }"
                                        class="form-control form-control-sm" id="userName" :disabled="!enableInput"
                                        v-model="form.userName" @blur="validateField('userName')"
                                        @focus="clearError('userName')" required>
                                    <div class="invalid-feedback" v-if="errors.userName">{{
                                        errors.userName }}</div>
                                </div>


                                <div class="mb-3 col-md-4 col-6">
                                    <label for="email" class="form-label">{{ 'البريد الإلكتروني' }}</label>
                                    <input type="email" :placeholder="'البريد الإلكتروني'" :disabled="!enableInput"
                                        :class="{ 'is-invalid': touched.email && errors.email }"
                                        class="form-control form-control-sm" id="email" v-model="form.email"
                                        @blur="validateField('email')" @focus="clearError('email')" required>
                                    <div class="invalid-feedback" v-if="touched.email && errors.email">{{ errors.email
                                        }}</div>
                                </div>

                                <div class="mb-3 col-md-4 col-6" style="position: relative;" v-if="!this.editUserId">
                                    <label for="password" class="form-label">{{ 'الرقم السرى' }}</label>
                                    <b-form-input :type="passwordReveal ? 'text' : 'password'" :placeholder="'الرقم السرى'" :disabled="!enableInput"
                                        :class="{ 'is-invalid': errors.password }" class="form-control form-control-sm"
                                        id="password" v-model="form.password" @blur="validateField('password')"
                                        @focus="clearError('password')" required>
                                        </b-form-input>
                                        <b-icon class="toggle-password" :class="[{ 'toggle-rtl': isRTL, 'toggle-ltr': !isRTL }]"
                      @click="togglePasswordVisibility" :icon="passwordReveal ? 'eye-slash' : 'eye'"></b-icon>
                                        
                                    <div class="invalid-feedback" v-if="errors.password">{{ errors.password
                                        }}</div>
                                </div>

                                <div class="mb-3 col-md-4 col-6">
                                    <label for="phoneNumber" class="form-label">{{ 'رقم الهاتف' }}</label>
                                    <input type="number" placeholder="رقم الهاتف"
                                        :class="{ 'is-invalid': touched.phoneNumber && errors.phoneNumber }"
                                        :disabled="!enableInput" class="form-control form-control-sm" id="phoneNumber"
                                        v-model="form.phoneNumber" @blur="validateField('phoneNumber')"
                                        @focus="clearError('phoneNumber')" required>
                                    <div class="invalid-feedback" v-if="touched.phoneNumber && errors.phoneNumber">{{
                                        errors.phoneNumber }}</div>
                                </div>
                            

                                <div class="mb-3 col-md-4 col-6">
                                    <div>
                                        <label for="role" class="form-label">{{ 'الدور' }}</label>
                                        <v-select :dir="direction" v-model="form.role" :disabled="!enableInput" :options="rolesList"
                                            :class="{ 'is-invalid': errors.role }" class="select-input" :placeholder="'أختر الدور'"
                                            id="multiple-select-custom-field"></v-select>
                                        <div class="invalid-feedback" v-if="errors.role">{{ errors.role
                                            }}</div>
                                    </div>
                                </div>

                                <div class="mb-3 col-md-4 col-6" v-if="this.hideStatus == false">
                                    <label for="status" class="form-label">{{ $t('message.status') }}</label>

                                    <toggle-button 
                                        style="display: block;"
                                        class="form-control form-control-sm"
                                        :disabled="!enableInput"
                                        v-model="isActive" 
                                        :key="toggleKey"
                                    />
                         
                                </div>

                                <div v-if="!isActive && this.hideStatus == false" class="mb-3 col-md-4 col-6">
                                        <label for="deactivateReason" class="form-label">{{ 'سبب الإيقاف' }}</label>
                                        <textarea placeholder='سبب الإيقاف'
                                            :class="{ 'is-invalid': deactivateReasonError }"
                                            :disabled="!enableInput"
                                            class="form-control form-control-sm" id="deactivateReason"
                                            v-model="form.deactivateReason" @input="validateDeactivateReason" rows="1" required>
                                        </textarea>
    
                                            <div v-if="deactivateReasonError" class="invalid-feedback">يجب أن يحتوي الإدخال على 10 حرفًا على الأقل
                                            وأن يكون حروفًا
                                            فقط.</div>
                               
                                
                                </div>
                                






                            </div>
                        </div>

                    </div>

                </div>
                <div class="modal fade " id="gobackmodel" ref="gobackmodel" tabindex="-1"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="exampleModalLabel">
                                    {{ $t('message.back') }}</h4>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">

                                <p>{{ $t('message.warningLoseData') }}</p>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-white"
                                    data-bs-dismiss="modal">{{ $t('message.CancelButton') }}</button>
                                <button type="button" @click="confirmBack" class="btn btn-logo">
                                    {{ $t('message.confirmBack') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="action-content">

                    <div class="mt-5" v-if="isEditMode">
                        <button class="btn btn-logo" @click="toggleEditMode">{{ enableInput ? 'قفل' : 'تحرير'
                            }}</button>
                    </div>

                    <div class="mt-5">
                        <button class="btn btn-logo" @click="handleSubmit" :disabled="!isStepValid || isAdding || (isEditMode && !updateSucess)">{{ isEditMode ?
                            $t('message.save') : $t('message.create') }}</button>
                        <button v-if="!isEditMode" class="btn btn-logo anotherAddition-btn" @click="anotherAddition()" :disabled="!isStepValid || isAdding">{{ $t('message.anotherAddition') }}</button>
                    </div>

                    <!-- <div class="mt-5">
                        <button class="btn btn-logo" @click="goBack">{{ $t('message.back') }}</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import axiosInstance from '../axios-config';



export default {
    props: {
        createUnitId: {
            type: Number,
            required: false
        },
        editUserId: {
            type: Number,
            required: false
        }
    },
    components: {
        vSelect,
    },
    data() {
        return {
            updateSucess: false,
            enableInput: false,
            isAdding: false,
            passwordReveal: false,
            form: {
                idNumber: '',
                userName: '',
                email: '',
                password: '',
                phoneNumber: '',
                role: '',
                deactivateReason: '',
            },

            errors: {
                idNumber: '',
                userName: '',
                email: '',
                password: '',
                phoneNumber: '',
                role: '',
                deactivateReason: '',

            },
            touched: {
                email: false,
                phoneNumber: false,
            },
            rolesList: [],
            isActive: true,
            toggleKey: 0,
            deactivateReasonError: false,
            hideStatus: false,

        }
    },
    mounted() {
        this.getRoles();
        if (this.editUserId) {
            this.getData();
        }
        if (this.isEditMode === false) {
            this.enableInput = true;
        }
        if (this.editUserId == 1) {
            this.hideStatus = true;
        }
    },
    computed: {
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
        direction() {
            return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
        },

        isStepValid() {
            // this.validatePhoneNumber();
            this.validateEmail();

            const step3Fields = ['idNumber', 'userName', 'email', 'password', 'phoneNumber', 'role'];
            const step3Errors = step3Fields.some(field => this.errors[field]);
            const step3Filled = step3Fields.every(field => this.form[field] !== '');
            if (this.form.role === '' || this.form.role === null) {                
                // 0556214251
                return false;
            }

            if (!this.isActive) {
                const deactivateReason = this.form.deactivateReason ? this.form.deactivateReason.trim() : ''; // التحقق من القيمة
                if (deactivateReason.length < 10) {
                    this.errors.deactivateReason = true; // تحديد الخطأ
                    return false;
                } else {
                    this.errors.deactivateReason = false; // لا يوجد خطأ
                }
            }


            return !step3Errors && step3Filled;
        }
    },
    watch: {
        editUserId: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.isEditMode = true;
                    this.fetchPropertyData(newVal);
                } else {
                    this.isEditMode = false;
                    this.resetForm();
                }
            }
        },
    },
    methods: {
        validateDeactivateReason() {
            const reason = this.form.deactivateReason.trim(); // إزالة المسافات الزائدة
            this.deactivateReasonError = reason.length < 10; // تحقق إذا كان أقل من 10 أحرف
        },
        fetchPropertyData(id) {
            // Fetch property data and populate form
        },
        isStep1Filled() {
            return ['idNumber', 'userName', 'email', 'password', 'phoneNumber', 'role']
                .some(field => this.form[field]);  // إذا كان الحقل يحتوي على قيمة، ترجع true
        },
        goBack() {
            if (this.isStep1Filled()) {
                $('#gobackmodel').modal('show');
            } else {
                window.history.back();
            }
        },
        confirmBack() {
            $('#gobackmodel').modal('hide');        
            window.history.back();
        },
        toggleEditMode() {
            this.enableInput = !this.enableInput;
            this.updateSucess = true;

        },
        togglePasswordVisibility() {
            this.passwordReveal = !this.passwordReveal;
        },
        resetForm() {
            // Reset form data

        },
        // validatePhoneNumber() {
        //     const saudiPhonePattern = /^(\+966|05)[0-9]{8}$/;
        //     if (!saudiPhonePattern.test(this.form.phoneNumber)) {
        //         this.errors.phoneNumber = 'رقم الهاتف غير صحيح، يجب أن يكون رقم سعودي';
        //     } else {
        //         this.errors.phoneNumber = '';
        //     }
        // },
        validateEmail() {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(this.form.email)) {
                this.errors.email = 'البريد الإلكتروني غير صحيح';
            } else {
                this.errors.email = '';
            }
        },

        validateField(field) {
            if (!this.form[field]) {
                this.$set(this.errors, field, this.$t('message.errorHandle'));
            }
            if (field === 'email') {
                this.touched.email = true;
                this.validateEmail();
            } else if (field === 'phoneNumber') {
                this.touched.phoneNumber = true;
                // this.validatePhoneNumber();
            }
        },
        clearError(field) {
            this.$delete(this.errors, field);
        },

        form: {
            idNumber: '',
            userName: '',
            email: '',
            phoneNumber: '',
            role: '',
        },

        async addNewUser() {
            this.isAdding = true;
            try {

                const requestData = {
                    name: this.form.userName,
                    email: this.form.email,
                    identity_number: this.form.idNumber,
                    password: this.form.password,
                    role: this.form.role.label,
                    phone_number: this.form.phoneNumber,
                    status: this.isActive ? 'active' : 'inactive', 
                };

                if (!this.isActive) {
                    requestData.deactivation_reason = this.form.deactivateReason;
                }
                const response = await axiosInstance.post('users', requestData);





                this.$izitoast.success({
                    title: 'Success',
                    message: 'successfully add user',
                    position: 'bottomLeft',
                    timeout: 5000, // يمكنك تخصيص مدة العرض
                });

                return true;

            } catch (error) {
                console.error('Add user error:', error);

                // Check if the error has a response and show the backend message
                const errorMessage = error.response?.data?.message || 'An error occurred while trying to add the new user';
                
                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });

                return false;
            
            }finally {
                // إعادة تمكين الزر بعد الانتهاء
                this.isAdding = false;
            }
        },

        async getData() {

            try {
                let url = 'users';

                // إذا كان editUserId موجودًا، نعدل المسار
                if (this.editUserId) {
                    url += `/${this.editUserId}`;
                }
                const response = await axiosInstance.get(url);



                this.form.userName = response.data.data.name;
                this.form.email = response.data.data.email;
                this.form.idNumber = response.data.data.identity_number;
                this.form.password = response.data.data.password;
                this.form.role = response.data.data.role;
                this.form.phoneNumber = response.data.data.phone_number;
                this.$nextTick(() => { this.isActive = response.data.data.status === 'active'; this.toggleKey += 1;});
                this.form.deactivateReason = response.data.data.deactivation_reason;


                // this.$izitoast.success({
                //     title: 'Success',
                //     message: 'successfully get data',
                //     position: 'bottomLeft',
                //     timeout: 5000, // يمكنك تخصيص مدة العرض
                // });


            } catch (error) {
                console.error('error:', error);
                this.$izitoast.error({
                    title: 'Error',
                    message: 'An error occurred while trying to get data',
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        async getRoles() {
            try {
                const response = await axiosInstance.get('roles');



                // تخزين البيانات الراجعة في rolesList
                this.rolesList = response.data.data.map(role => ({
                    label: role.name, // وضع الاسم كاسم العرض
                    value: role.id // استخدام المعرف كالقيمة
                }));



            } catch (error) {
                console.error('error:', error);
                this.$izitoast.error({
                    title: 'Error',
                    message: 'An error occurred while trying to get roles',
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },

        async updateUserData() {
            this.isAdding = true;
        try {
            let url = 'users';

            // إذا كان editUserId موجودًا، نعدل المسار
            if (this.editUserId) {
                url += `/${this.editUserId}`;
            }

            const requestData = {
                name: this.form.userName,
                email: this.form.email,
                identity_number: this.form.idNumber,
                password: this.form.password,
                role: this.form.role.label,
                phone_number: this.form.phoneNumber,
                status: this.isActive ? 'active' : 'inactive',
            };

            if (!this.isActive) {
                requestData.deactivation_reason = this.form.deactivateReason;
            }


            const response = await axiosInstance.put(url,requestData);




            
            // const locale = this.$i18n.locale;
            // const targetRoute = '/' + locale + '/list';

            // if (this.$route.path !== targetRoute) {

            //     this.$router.push(targetRoute);

            // }
            


            this.$izitoast.success({
                title: 'Success',
                message: 'successfully update user data',
                position: 'bottomLeft',
                timeout: 5000, // يمكنك تخصيص مدة العرض
            });


        } catch (error) {
            console.error('update user error:', error);

                // Check if the error has a response and show the backend message
                const errorMessage = error.response?.data?.message || 'An error occurred while trying to update user data';
                
                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });

        }
        finally {
            // إعادة تمكين الزر بعد الانتهاء
            this.isAdding = false;
        }
        },

        async anotherAddition() {
            const success = await this.addNewUser();
            
            if (success) {
                this.form.idNumber = '';
                this.form.userName = '';
                this.form.email = '';
                this.form.password = '';
                this.form.phoneNumber = '';
                this.form.role = '';
            }
        },

        handleSubmit() {
            if (this.isEditMode) {
                this.updateUsers();
            } else {
                this.createNewUser();
            }
        },
        async createNewUser() {
            const success = await this.addNewUser();
            if (success) {
                const locale = this.$i18n.locale;
                const targetRoute = '/' + locale + '/users/list';

                if (this.$route.path !== targetRoute) {
                    this.$router.push(targetRoute);
                }
            }
    
            // this.addNewUser();
        },
        updateUsers() {
           this.updateUserData();
        }
    },
}
</script>
<style scoped>

.content-card {
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .0);
    margin-top: 80px;
    padding: 15px;
    background-color: #fff;
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: 0.75rem;
    margin-right: 15px;
    margin-left: 15px;
}

.page-header-content {
    padding-right: 20px;
    padding-left: 20px;
}

.page-header-title {
    margin-bottom: .5rem;
}

.page-header-text {
    margin-bottom: .25rem;
    color: #677788;
    font-size: 14px
}

.addUserBtn {
    margin-top: 28px;
    width: -webkit-fill-available;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #4b7ab8;
    --bs-btn-border-color: #4b7ab8;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #3e69a1;
    --bs-btn-hover-border-color: #3e69a1;
    --bs-btn-focus-shadow-rgb: 85, 145, 255;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #3e69a1;
    --bs-btn-active-border-color: #3e69a1;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #3e69a1;
    --bs-btn-disabled-border-color: #3e69a1;
}

#main-content {
    /* max-height: 1200px; */
    min-height: 1100px;
}

.content {
    padding-top: 20px;
    /* padding-left: 10px; */
    /* padding-right: 10px; */
}

.content-rtl {
    margin-right: 17.2rem;
}

.content-ltr {
    margin-left: 17.2rem;
}

.full-content-rtl {
    margin-right: 90px;
    /* Adjusted for closed sidebar */
}

.full-content-ltr {
    margin-left: 85px;
    /* Adjusted for closed sidebar */
}

.stepTwoContent {
    border: 1px solid #ddd;
    border-radius: .3125rem;
    padding: .6125rem 1rem;
    margin-bottom: 15px;
}

.action-content {
    display: flex;
    justify-content: end;
}

.action-content div {
    margin: 5px;
}

.toggle-password {
  position: absolute;
  /* left: 21px; */
  top: 41px;
  z-index: 1000;
  cursor: pointer;
  font-size: 17px;
}

.toggle-rtl {
    left: 21px;
}

.toggle-ltr {
  right: 21px;
}
.modal-header {
    padding-bottom: 25px;
    background-image: url(../assets/pattren-dark.svg);
    background-size: 150px;
    background-color: #f9fafc;
}

</style>
<template>
    <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
        <div class="content-card" v-if="!this.noData">
            <div class="page-header-content">
                <div class="row mb-3">
                    <div class="col-sm mb-2 mb-sm-0">
                        <div class="d-flex gap-2 align-items-center justify-content-between mb-3">

                            <div>
                                <h1 class="page-header-title mb-0"><b-icon class="title-icon"
                                        icon="chevron-right" aria-hidden="true"></b-icon>{{ 'المستخدم' }} {{ this.form.userName }}
                                </h1>
                            </div>
                            <b-button variant="logo" @click="handleEditClick()">
                                <b-icon icon="box-arrow-in-down-left"></b-icon> تعديل البيانات
                            </b-button>
                            <!-- <a class="edit-btn" @click="goBack"><b-icon icon="box-arrow-in-down-left" aria-hidden="true"></b-icon></a> -->

                        </div>
                    </div>
                </div>
                <h5 class="mb-0 section-header">{{ 'المعلومات الاساسية للمستخدم' }}</h5>
                <div class="section-content mt-4">
                    <div class="row">
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ 'رقم الهوية' }}</h5>
                                <p class="value">{{ form.idNumber }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <div class="info-box">
                                <h5 class="label">{{ 'الاسم' }}</h5>
                                <p class="value">{{ form.userName }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <div class="info-box">
                                <h5 class="label">{{ 'البريد الإلكتروني' }}</h5>
                                <p class="value">{{ form.email }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <div class="info-box">
                                <h5 class="label">{{ 'رقم الهاتف' }}</h5>
                                <p class="value">{{ form.phoneNumber }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <div class="info-box">
                                <h5 class="label">{{ 'الدور' }}</h5>
                                <p class="value">{{ form.role }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.status') }}</h5>
                                <span :class="form.status === 'نشط' ? 'badge bg-success' : 'badge bg-danger'">
                                    <p class="value">{{ form.status }}</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <div v-else >
            <div class="content-card">
                <h5> {{ 'لم يتم العثور على جمعية برقم التعريف: ' + this.id }} </h5>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from '../../axios-config';

export default {
    name: 'addAssociations',
    props: ['id'],
    data() {
        return {
            form: {
                idNumber: '',
                userName: '',
                email: '',
                phoneNumber: '',
                role: '',
                status: '',
            },
            noData: false,
        }
    },
    computed: {
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {

            try {
                let url = 'users';

                // إذا كان editUserId موجودًا، نعدل المسار
                if (this.id) {
                    url += `/${this.id}`;
                }
                const response = await axiosInstance.get(url);



                this.form.userName = response.data.data.name;
                this.form.email = response.data.data.email;
                this.form.idNumber = response.data.data.identity_number;
                this.form.role = response.data.data.role;
                this.form.phoneNumber = response.data.data.phone_number;
                this.form.status = response.data.data.status === 'active' ? 'نشط' : 'غير نشط';



                // this.$izitoast.success({
                //     title: 'Success',
                //     message: 'successfully get data',
                //     position: 'bottomLeft',
                //     timeout: 5000, // يمكنك تخصيص مدة العرض
                // });


            } catch (error) {
                console.error('error:', error);
                const errorMessage = error.response?.data?.message || 'An error occurred while trying to get data';
                this.noData = true;
                this.$router.push('/' + this.$i18n.locale + '/NotFound');
                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        handleEditClick(){
            this.$nextTick(() => {
                this.$router.push('/' + this.$i18n.locale + '/users/edit/' + this.id);
            });
        },
    }
}


</script>

<style scoped>
.content-card {
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .0);
    margin-top: 80px;
    padding: 15px;
    background-color: #fff;
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: 0.75rem;
    margin-right: 15px;
    margin-left: 15px;
}

.page-header-content {
    padding-right: 20px;
    padding-left: 20px;
}

.page-header-title {
    margin-bottom: .5rem;
}

.title-icon {
    font-size: 15px;
    margin-left: 5px
}

.section-header {
    color: #007AFF;
}

.section-content {
    border: 1px solid #F1F1F1;
    padding: 40px;
    border-radius: 10px;
}

.associationManagement-section {
    border: 1px solid #F1F1F1;
    padding: 20px;
    border-radius: 10px;
}

p {
    margin-bottom: 0px !important;
}

.space {
    margin-bottom: 50px;
}

.attachment-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */

}

.attachment-item:hover {
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */
}

.attachment-link {
    color: #161313;
    text-decoration: none;
    width: 30%;
}

.attachment-link:hover {
    text-decoration: none
}
.attachment-icon{
    width: 24px;
    height: 24px;
    margin-left: 10px;
    cursor: pointer;
}
.download-icon{
    position: absolute;
    left: 11px;
    top: 24px;
    color: #FF0000;
    font-size: medium;
    cursor: pointer;
}
</style>
<template>
  <div id="app" :lang="$i18n.locale" >
    <div v-if="isLoading && needsLoading" class="loading-container">
      <img :src="require('@/assets/logo-edarat365.png')" alt="Logo" class="loading-logo" />
    </div>
    <div class="layout" v-if="!isLoading || !needsLoading">
      <navbar v-if="showNavbar" />
      <div id="main-bg" :class="{'main__header__bg--not-found': isNotFoundPage, 'main__header__bg': !isNotFoundPage}">
        <sidebar v-if="showSidebar" />
        <router-view />
        <theFooter v-if="showFooter" />
      </div>  
      <!-- <sidebar v-if="showSidebar" /> -->
    </div>
    <!-- <router-view/> -->
  </div>
</template>

<script>
import sidebar from "./components/sidebar.vue"
import navbar from "./components/navbar.vue"
import theFooter from "./components/footer.vue"

export default {
  components: {
    sidebar,
    navbar,
    theFooter
  },
  data() {
    return {
      isLoading: true, // الحالة المبدئية للتحميل
    };
  },
  computed: {
    showSidebar() {
      const hideSidebarKeywords = ['login', 'forget', '*'];
      const currentPath = this.$route.path;
      const shouldHideSidebar = hideSidebarKeywords.some(keyword => currentPath.includes(keyword));
      return !shouldHideSidebar && this.$route.name !== 'NotFound';
      // Add paths where you don't want to show the sidebar
      // const noSidebarPaths = ['/ar/login', '/en/login', '*', '/en/forget-password', '/ar/forget-password'];
      // return !noSidebarPaths.includes(this.$route.path) && this.$route.name !== 'NotFound';
    },
    showNavbar(){
      const hideSidebarKeywords = ['login', 'forget', '*'];
      const currentPath = this.$route.path;
      const shouldHideSidebar = hideSidebarKeywords.some(keyword => currentPath.includes(keyword));
      return !shouldHideSidebar && this.$route.name !== 'NotFound';
      // const noSidebarPaths = ['/ar/login', '/en/login', '*', '/en/forget-password', '/ar/forget-password'];
      // return !noSidebarPaths.includes(this.$route.path) && this.$route.name !== 'NotFound';
    },

    showFooter(){
      const hideFooterKeywords = ['login', 'forget', '*'];
      const currentPath = this.$route.path;
      const shouldHideFooter = hideFooterKeywords.some(keyword => currentPath.includes(keyword));
      return !shouldHideFooter && this.$route.name !== 'NotFound';
    },
    isRTL() {
      return this.$i18n.locale === 'ar';
    },
    isNotFoundPage() {
      // Check if the current route matches the Not Found route
      return this.$route.name === 'NotFound';
    },
    needsLoading() {
      
      // استثناء التحميل لصفحات محددة حسب اسم الـ route
      // return !['AddAssociations-ar', 'AddAssociations-en', 'home-ar', 'home-en' , 'login-ar', 'login-en' , 'ForgetPassword-ar', 'ForgetPassword-en' , 'createNewProperty-ar', 'createNewProperty-en' , 'createNewRealStateUnit-ar', 'createNewRealStateUnit-en' , 'AddNewUser-ar', 'AddNewUser-en' , 'RealEstateUnitManagement-ar', 'RealEstateUnitManagement-en' , 'createProperty-ar', 'createProperty-en'].includes(this.$route.name);
      return !['ForgetPassword-ar', 'ForgetPassword-en' , 'NotFound'].includes(this.$route.name);
    }
  },
  methods: {
  updateMainBgClass() {
      const mainBgElement = document.getElementById('main-bg');
      if (this.$route.name === 'NotFound') {
        if (mainBgElement) {
          mainBgElement.classList.remove('main__header__bg');
        }
      } else {
        if (mainBgElement) {
          mainBgElement.classList.add('main__header__bg');
        }
      }
    },
    checkLoading() {
      // تحديد ما إذا كان التحميل مطلوبًا أم لا
      if (this.needsLoading) {
        this.isLoading = true;
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('navbar-vertical-aside-mini-mode');
        // جلب البيانات أو تنفيذ ما تحتاجه من تحميل، وبعد ذلك قم بتعطيل isLoading
        setTimeout(() => this.isLoading = false, 2000);
      } else {
        this.isLoading = false;
      }
    }
  },
  watch: {
    // مراقبة التغيرات في المسار
    '$route'() {
      this.updateMainBgClass();
      this.checkLoading();
    }
  },
  mounted() {
    // استدعاء الطريقة عند تحميل المكون لأول مرة
    this.updateMainBgClass();
    this.checkLoading();
  }
}

</script>

<style scoped>

:lang(ar) {
  direction: rtl;
}

/* توجيه النص إلى اليسار للإنجليزية */
:lang(en) {
  direction: ltr;
}

.sidebar {
  /* width: 320px; */
  width: 18rem;
  /* margin-top: 50px; */
  /* margin-top: 293px; */
  margin-top: 100px;
  
  position: absolute;
  top: 0;
}

.navbar {
  /* z-index: 999; */
  z-index: 100;
}



.main__header__bg {
  background-color: #162A35 !important;
  background-image: url('@/assets/pattren.svg');
  height: 380px;
}
.main__header__bg--not-found {
  height: 100vh !important;
  background-color: #fff !important;
}
#main-content{
  /* max-height: 1200px; */
  min-height: 1100px;
}
.sidebar-rtl {
  margin-right: 15px;
  margin-left: 18rem;
  transition: margin-left 0.3s;
}

.sidebar-ltr {
  margin-left: 15px;
  margin-right: 18rem;
  transition: margin-right 0.3s;
}


.content {
  padding-top: 20px;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
}

.content-rtl {
  margin-right: 17.2rem;
}

.content-ltr {
  margin-left: 17.2rem;
}

.full-content-rtl {
  margin-right: 90px; /* Adjusted for closed sidebar */
}

.full-content-ltr {
  margin-left: 85px; /* Adjusted for closed sidebar */
}

.sidebar-rtl,
.sidebar-ltr,
.full-content-rtl,
.full-content-ltr,
.content-rtl,
.content-ltr {
  transition: all 0.2s ease-in-out;
}

.no-transition * {
  transition: none !important;
}



</style>

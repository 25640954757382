<template>
    <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
        <div class="content-card" v-if="!this.noData">
            <div class="page-header-content">
                <div class="row mb-3">
                    <div class="col-sm mb-2 mb-sm-0">
                        <div class="d-flex gap-2 align-items-center justify-content-between mb-3">

                            <div>
                                <h1 v-if="isRTL" class="page-header-title mb-0"><b-icon class="title-icon"
                                        icon="chevron-right" aria-hidden="true"></b-icon>{{ this.form.contractNameAr }}
                                </h1>
                                <h1 v-if="!isRTL" class="page-header-title mb-0"><b-icon class="title-icon"
                                        icon="chevron-right" aria-hidden="true"></b-icon>{{ this.form.contractNameEn }}
                                </h1>
                            </div>
                            <b-button variant="logo" @click="handleEditClick()">
                                <b-icon icon="box-arrow-in-down-left"></b-icon> تعديل البيانات
                            </b-button>
                            <!-- <a class="edit-btn" @click="goBack"><b-icon icon="box-arrow-in-down-left" aria-hidden="true"></b-icon></a> -->

                        </div>
                    </div>
                </div>
                <h5 class="mb-0 section-header">{{ $t('message.basicInformationAboutAssociation') }}</h5>
                <div class="section-content mt-4">
                    <div class="row">
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.associationName') }}</h5>
                                <p class="value">{{ form.contractNameAr }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.associationNumber') }}</h5>
                                <p class="value">{{ form.contractId }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.numberOfUnits') }}</h5>
                                <p class="value">{{ form.unitNumber }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.dateOfFirstApproval') }}</h5>
                                <p class="value">{{ form.firstApproval }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.dateOfEstablishment') }}</h5>
                                <p class="value">{{ form.establishmentDate }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space ">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.expirationDate') }}</h5>
                                <p class="value">{{ form.expireDate }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.unifiedNumber') }}</h5>
                                <p class="value">{{ form.unifiedNumber }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.establishmentNumber') }}</h5>
                                <p class="value">{{ form.establishmentNumber }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.status') }}</h5>
                                <span :class="form.status === 'نشط' ? 'badge bg-success' : 'badge bg-danger'">
                                    <p class="value">{{ form.status }}</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 class="mt-4 mb-0 section-header">{{ $t('message.associationManagement') }}</h5>
                <div class="section-content mt-4">
                    <div class="row">
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.associationManager') }}</h5>
                                <p class="value">{{ manager.label }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.dateOfAppointmentFrom') }}</h5>
                                <p class="value">{{ appointmentDateFrom }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.dateOfAppointmentTo') }}</h5>
                                <p class="value">{{ appointmentDateTo }}</p>
                            </div>
                        </div>
                        <div class="col-md-3 space">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.monthlyWage') }}</h5>
                                <p class="value">{{ salary }}</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.additionalCommissions') }}</h5>
                                <p class="value">{{ hasCommissions }}</p>
                            </div>
                        </div>
                        <div class="col-md-3" v-if="hasCommissions === 'yes'">
                            <div class="info-box">
                                <h5 class="label">{{ $t('message.typeOfCommission') }}</h5>
                                <p class="value">{{ commissionType }}</p>
                            </div>
                        </div>
                        <div class="col-md-3" v-if="hasCommissions === 'yes'">
                            <div class="info-box">
                                <h5 v-if="commissionType === 'fixed'" class="label">{{ $t('message.value') }}</h5>
                                <h5 v-if="commissionType !== 'fixed'" class="label">{{ $t('message.percentage') }}</h5>
                                <p class="value">{{ commissionValue }}</p>
                            </div>
                        </div>

                    </div>
                </div>

                <h5 class="mt-4 mb-0 section-header">{{ $t('message.associationFacilitiesManagement') }}</h5>
                <div class="section-content associationManagement-section  mt-4">
                    <div class="input-group input-group-vertical">
                        <label for="indivedialroles">
                            <span class="form-check">
                                <input type="radio" class="form-check-input" name="accadminrols" id="indivedialroles"
                                    checked>
                                <span class="form-check-label">
                                    <h5 class="">{{ selectedAssociationManagement.name }}</h5>
                                    <p class="">{{ selectedAssociationManagement.description }}</p>

                                </span>
                            </span>
                        </label>
                    </div>
                </div>

                <h5 v-if="this.files.length > 0" class="mt-4 mb-0 section-header">{{ $t('message.attachments') }}</h5>
                <div v-if="this.files.length > 0" class="row mt-4">
                    <div v-for="file in files" :key="file.id" class="col-md-4 mb-3">
                        <div class="attachment-item p-2"
                            style="border: 1px solid #F1F1F2; border-radius: 10px;min-height:66px;max-height: 66px; position: relative;">
                            <div class="d-flex align-items-center" style="padding: 10px;"
                                v-if="file && file.mime_type && file.mime_type.includes('image')">
                                
                                <img :src="file.url" alt="Attachment Preview" class="attachment-icon"
                                    @click="openInNewTab(file.url, 'image')" />

                                <div style="width: max-content;">
                                    <a :href="file.url" target="_blank" class="attachment-link" v-b-tooltip.hover :title="file.file_name">
                                        {{ truncateFileName(file.file_name) }}
                                    </a>
                                    <p class="file-size text-muted mb-0" style="font-size: 12px;">
                                        {{ formatFileSize(file.size) }}
                                    </p>
                                </div>
                                <div>
                                    <b-icon class="download-icon" @click="downloadFile(file.url, file.file_name)" icon="download" aria-hidden="true"></b-icon>
                                </div>
                            </div>
                            <div class="d-flex align-items-center" v-else>
                                <img class="attachment-icon"
                                    :src="require(`../../assets/${getIconNameForFileType(file.mime_type)}`)"
                                    alt="File Icon" @click="openInNewTab(file.url, file.mime_type)" />
                                <div style="width: max-content;">
                                    <a :href="file.url" target="_blank" class="attachment-link" v-b-tooltip.hover :title="file.file_name">
                                        {{ truncateFileName(file.file_name) }}
                                    </a>
                                    <p class="file-size text-muted mb-0" style="font-size: 12px;">
                                        {{ formatFileSize(file.size) }}
                                    </p>
                                </div>
                                <div>
                                    <b-icon class="download-icon" @click="downloadFile(file.url, file.file_name)" icon="download" aria-hidden="true"></b-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <div v-else >
            <div class="content-card">
                <h5> {{ 'لم يتم العثور على جمعية برقم التعريف: ' + this.id }} </h5>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from '../../axios-config';

export default {
    name: 'addAssociations',
    props: ['id'],
    data() {
        return {
            form: {
                contractNameAr: '',
                contractNameEn: '',
                contractId: '',
                unitNumber: '',
                establishmentDate: '',
                expireDate: '',
                firstApproval: '',
                unifiedNumber: '',
                establishmentNumber: '',
                status: '',
            },
            files: [],
            manager: '',
            appointmentDateFrom: '',
            appointmentDateTo: '',
            salary: '',
            hasCommissions: '',
            commissionName: '',
            commissionType: '',
            commissionValue: '',
            managers: [],
            selectedAssociationManagement: {},
            noData: false,
        }
    },
    computed: {
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
    },
    created() {
        this.fetchdata();
    },
    methods: {
        async fetchdata() {

            try {

                let url = 'associations';

                if (this.id) {
                    url += `/${this.id}`;
                }

                const response = await axiosInstance.get(url);


                if (this.$i18n.locale === 'ar') {
                    this.form.contractNameAr = response.data.data.name;
                } else {
                    this.form.contractNameEn = response.data.data.name;
                }
                this.form.contractId = response.data.data.association_number;
                this.form.unitNumber = response.data.data.units_number;
                this.form.firstApproval = response.data.data.first_approval_date;
                this.form.establishmentDate = response.data.data.founded_at;
                this.form.expireDate = response.data.data.expired_at;
                this.form.unifiedNumber = response.data.data.unified_number;
                this.form.establishmentNumber = response.data.data.founding_number;
                this.selectedAssociationManagement = response.data.data.association_management_form;
                this.manager = {
                    label: response.data.data.manager.name,
                    value: response.data.data.manager.id
                };
                this.appointmentDateFrom = response.data.data.hiring_date_from;
                this.appointmentDateTo = response.data.data.hiring_date_to;
                this.salary = response.data.data.manager_salary;
                this.commissionName = response.data.data.commission_description;
                this.commissionType = response.data.data.commission_type;
                this.commissionValue = response.data.data.commission_value;
                this.files = response.data.data.files;
                this.form.status = response.data.data.status === 'active' ? 'نشط' : 'غير نشط';


                if (response.data.data.commission_value !== null) {

                    this.hasCommissions = 'yes';
                } else {
                    this.hasCommissions = 'no';
                }


            } catch (error) {
                const errorMessage = error.response?.data?.message || 'An error occurred while trying to get data';
                this.noData = true;
                this.$router.push('/' + this.$i18n.locale + '/NotFound');
                console.error('error:', error);
                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },
        formatFileSize(size) {
            const kb = size / 1024;
            if (kb < 1024) return `${kb.toFixed(2)} KB`;
            return `${(kb / 1024).toFixed(2)} MB`;
        },
        getIconNameForFileType(mimeType) {
            const type = mimeType.split('/')[1];

            if (type === 'pdf') {
                return 'pdf.png'; // أيقونة ملفات PDF
            } else if (type.includes('wordprocessingml.document')) {
                return 'doc.png'; // أيقونة ملفات Word
            } else if (type.includes('spreadsheetml.sheet')) {
                return 'xls.png'; // أيقونة ملفات Excel
            } else if (type.includes('video')) {
                return 'mp4.png'; // أيقونة ملفات الفيديو
            } else if (type.includes('audio')) {
                return 'mp3.png'; // أيقونة ملفات الصوت
            } else if (type === 'css') {
                return 'css.png'; // أيقونة ملفات CSS
            } else if (type === 'csv') {
                return 'csv.png'; // أيقونة ملفات CSV
            } else if (type === 'dbf') {
                return 'dbf.png'; // أيقونة ملفات DBF
            } else if (type === 'dwg') {
                return 'dwg.png'; // أيقونة ملفات DWG
            } else if (type === 'exe') {
                return 'exe.png'; // أيقونة ملفات EXE
            } else if (type === 'fla') {
                return 'fla.png'; // أيقونة ملفات FLA
            } else if (type === 'html') {
                return 'html.png'; // أيقونة ملفات HTML
            } else if (type === 'iso') {
                return 'iso.png'; // أيقونة ملفات ISO
            } else if (type.includes('javascript')) {
                return 'javascript.png'; // أيقونة ملفات JavaScript
            } else if (type === 'jpeg' || type === 'jpg') {
                return 'jpg.png'; // أيقونة ملفات JPEG
            } else if (type === 'json') {
                return 'json.png'; // أيقونة ملفات JSON
            } else if (type === 'png') {
                return 'png.png'; // أيقونة ملفات PNG
            } else if (type === 'ppt' || type.includes('presentation')) {
                return 'ppt.png'; // أيقونة ملفات PowerPoint
            } else if (type === 'psd') {
                return 'psd.png'; // أيقونة ملفات Photoshop
            } else if (type === 'rtf') {
                return 'rtf.png'; // أيقونة ملفات RTF
            } else if (type === 'svg') {
                return 'svg.png'; // أيقونة ملفات SVG
            } else if (type === 'txt') {
                return 'txt.png'; // أيقونة ملفات TXT
            } else if (type === 'xls' || type.includes('excel')) {
                return 'xls.png'; // أيقونة ملفات Excel
            } else if (type === 'xml') {
                return 'xml.png'; // أيقونة ملفات XML
            } else if (type === 'zip') {
                return 'zip.png'; // أيقونة ملفات ZIP
            }
        },
        openInNewTab(url, type) {

            window.open(url, '_blank');

        },
        handleEditClick(){
            this.$nextTick(() => {
                this.$router.push('/' + this.$i18n.locale + '/associations/edit/' + this.id);
            });
        },
        truncateFileName(fileName) {
            const maxLength = 28; // يمكنك تعديل الحد الأقصى للطول حسب الحاجة
            if (fileName.length > maxLength) {
                return fileName.substring(0, maxLength) + '...';
            }
            return fileName;
        },
        downloadFile(url, fileName) {
            // إنشاء طلب لجلب الملف
            fetch(url)
                .then(response => response.blob()) // تحويل الاستجابة إلى Blob
                .then(blob => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob); // إنشاء رابط URL مؤقت للملف
                    link.download = fileName; // تعيين اسم الملف للتنزيل
                    document.body.appendChild(link); // إضافة الرابط إلى DOM
                    link.click(); // النقر على الرابط لتنزيل الملف
                    document.body.removeChild(link); // إزالة الرابط بعد التنزيل
                    // عرض رسالة النجاح
                    this.$izitoast.success({
                        title: 'Success',
                        message: 'File downloaded successfully',
                        position: 'bottomLeft',
                        timeout: 5000,
                    });
                })
                .catch(error => {
                    // عرض رسالة الخطأ
                    this.$izitoast.error({
                        title: 'Error',
                        message: 'An error occurred while trying to download the file',
                        position: 'bottomLeft',
                        timeout: 5000,
                    });
                    console.error('Error downloading the file:', error);
                });
        }
    }
}


</script>

<style scoped>
.content-card {
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .0);
    margin-top: 80px;
    padding: 15px;
    background-color: #fff;
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: 0.75rem;
    margin-right: 15px;
    margin-left: 15px;
}

.page-header-content {
    padding-right: 20px;
    padding-left: 20px;
}

.page-header-title {
    margin-bottom: .5rem;
}

.title-icon {
    font-size: 15px;
    margin-left: 5px
}

.section-header {
    color: #007AFF;
}

.section-content {
    border: 1px solid #F1F1F1;
    padding: 40px;
    border-radius: 10px;
}

.associationManagement-section {
    border: 1px solid #F1F1F1;
    padding: 20px;
    border-radius: 10px;
}

p {
    margin-bottom: 0px !important;
}

.space {
    margin-bottom: 50px;
}

.attachment-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */

}

.attachment-item:hover {
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */
}

.attachment-link {
    color: #161313;
    text-decoration: none;
    width: 30%;
}

.attachment-link:hover {
    text-decoration: none
}
.attachment-icon{
    width: 24px;
    height: 24px;
    margin-left: 10px;
    cursor: pointer;
}
.download-icon{
    position: absolute;
    left: 11px;
    top: 24px;
    color: #FF0000;
    font-size: medium;
    cursor: pointer;
}
</style>
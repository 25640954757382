<template>
  <div class="login-page">
    <b-container>
      <b-row class="justify-content-center">
        <b-col md="6" class=" background-image d-none d-sm-block d-md-block">
          
        </b-col>
        <b-col md="6" class="d-flex flex-column align-items-center justify-content-center">
          <b-card class="login-card">
            <b-card-header class="text-center">
              <div>
                <img :src="require('@/assets/logo-edarat365.png')" style="height: 78px;width: 217px;" alt="Logo" class="">
              </div>
              <h2 class="login-title fw-bold mb-2 mt-4">
                {{ $t('message.loginTitle') }}
              </h2>
              <div>
                <p class="login-parag"> {{ $t('message.LoginToContainue') }}</p>
              </div>
            </b-card-header>
            <b-card-body>
              <b-form @submit.prevent="login" class="login-form">
                <b-form-group :label="$t('message.emailLabel')">
                  <template #label>
                    <span class="required-label">{{ $t('message.emailLabel') }}</span>
                    <span class="required-star">*</span>
                  </template>
                  <b-form-input class="form-control-sm" v-model="email" type="email" required :placeholder="$t('message.emailPlaceholder')"
                    :state="emailState" @input="validateEmail"></b-form-input>
                  <b-form-invalid-feedback v-if="emailState === false && email.length === 0">
                    <div class="error-message">{{ $t('message.emailRequiredErrorMessage') }}</div>
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback v-if="emailState === false && !isEmailValid">
                    <div class="error-message">{{ $t('message.emailInvalidFormatErrorMessage') }}</div>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group :label="$t('message.passwordLabel')">
                  <template #label>
                    <span class="required-label">{{ $t('message.passwordLabel') }}</span>
                    <span class="required-star">*</span>
                  </template>
                  <b-input-group>
                    <b-form-input class="form-control-sm" v-model="password" :type="passwordReveal ? 'text' : 'password'" required
                      :placeholder="$t('message.passwordPlaceholder')" :state="passwordState"
                      @input="validatePassword"></b-form-input>
                    <b-icon class="toggle-password" :class="[{ 'toggle-rtl': isRTL, 'toggle-ltr': !isRTL }]"
                      @click="togglePasswordVisibility" :icon="passwordReveal ? 'eye-slash' : 'eye'"></b-icon>
                    <!-- <b-input-group-append> -->
                    <!-- <b-button @click="togglePasswordVisibility" :variant="passwordReveal ? 'danger' : 'success'"> -->
                    <!-- <b-icon :icon="passwordReveal ? 'eye-slash' : 'eye'"></b-icon> -->
                    <!-- </b-button> -->
                    <!-- </b-input-group-append> -->
                  </b-input-group>
                  <div v-if="passwordState === false && password.length === 0">
                    <div class="error-message">{{ $t('message.passwordRequiredErrorMessage') }}</div>
                  </div>
                  <!-- <b-form-valid-feedback v-if="passwordState === true">Looks good!</b-form-valid-feedback> -->
                  <div v-if="passwordState === false && !isValidPassword">
                    <div class="error-message">{{ $t('message.passwordLengthErrorMessage') }}</div>
                  </div>
                  <b-form-invalid-feedback v-if="passwordState === false && password.length === 0">
                    <div class="error-message">Please enter a password</div>
                  </b-form-invalid-feedback>
                  <!-- <b-form-valid-feedback v-if="passwordState === true">Looks good!</b-form-valid-feedback> -->
                  <b-form-invalid-feedback v-if="passwordState === false && password.length < 6">
                    <div class="error-message">Password must be at least 8 characters long</div>
                  </b-form-invalid-feedback>
                </b-form-group>
                <div class="forget-password">
                  <RouterLink :to="'/' + this.$i18n.locale + '/forget-password'"> {{ $t('message.ForgetPassword') }} </RouterLink>
                </div>
                <b-button type="submit" block class="login-button btn btn-sm btn-logo" style="width: -webkit-fill-available;"
                :disabled="!passwordState || isSubmitting">{{ $t('message.loginButton') }}</b-button>
              </b-form>

            </b-card-body>
          </b-card>
          <p  class="text-center login-footer text-gray-400">
            {{ $t('message.copyRights') }}
            <span>2024 &copy;</span>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axiosInstance from '../../axios-config';
import { mapActions } from 'vuex';


export default {
  data() {
    return {
      email: '',
      password: '',
      emailState: null,
      passwordState: null,
      isEmailValid: true,
      isValidPassword: true,
      passwordReveal: false,
      isSubmitting: false,
    };
  },
  computed: {
    isRTL() {
      return this.$i18n.locale === 'ar';
    },
    
  },
  methods: {
    ...mapActions(['login']),
    async login() {
      // تحقق إذا كان الطلب قيد التقديم بالفعل
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true; // تعيين حالة التقديم

      try {
        const response = await axiosInstance.post('login', {
          email: this.email,
          password: this.password
        });


        
        const authData = response.data.data;
        
      // استدعاء الأكشن لحفظ البيانات
      await this.$store.dispatch('login', authData); // استخدم dispatch هنا

      this.$izitoast.success({
        title: 'Success',
        message: 'logged in successfully',
        position: 'bottomLeft',
        timeout: 5000, // يمكنك تخصيص مدة العرض
      });


        
        // التحقق إذا كان المستخدم موجود بالفعل على نفس الصفحة قبل إعادة التوجيه
        const locale = this.$i18n.locale;
        const targetRoute = '/' + locale;
        
        if (this.$route.path !== targetRoute) {

            this.$router.push(targetRoute);

        }

      } catch (error) {
        console.error('Login error:', error);
        // Check if the error has a response and show the backend message
        const errorMessage = error.response?.data?.message || 'An error occurred while trying to log in';
                
        this.$izitoast.error({
            title: 'Error',
            message: errorMessage,
            position: 'bottomLeft',
            timeout: 5000,
        });

      }finally {
        this.isSubmitting = false; // إعادة تعيين حالة التقديم بعد الانتهاء
      }
    },

    
    validateEmail() {
      if (this.email.length === 0) {
        this.emailState = false;
        this.isEmailValid = true;
      } else if (!this.validateEmailFormat(this.email)) {
        this.emailState = false;
        this.isEmailValid = false;
      } else {
        this.emailState = true;
        this.isEmailValid = true;
      }
    },
    validateEmailFormat(email) {
      const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return emailFormat.test(email);
    },
    validatePassword() {
      if (this.password.length === 0) {
        this.passwordState = false;
        this.isValidPassword = true;
      } else if (this.password.length < 8) {
        this.passwordState = false;
        this.isValidPassword = false;
      } else {
        this.passwordState = true;
        this.isValidPassword = true;
      }
    },
    togglePasswordVisibility() {
      this.passwordReveal = !this.passwordReveal;
    },
  },
};
</script>

<style scoped>
.login-page {
  /* margin-top: 50px; */
  background-color: #f9f9f9;
  display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login-card {
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  border-radius: 0.475rem !important;
  /* color: #f9f9f9; */
  border: none !important;
  outline: none !important;
  margin: 10px;
  margin-bottom: 0px;
  padding: 0.5rem !important;
  background-color: #FFFFFF;
}

.login-form {
  /* margin-bottom: 20px; */
  max-width: 350px !important;
  /* width: 450px !important; */
  margin-left: auto !important;
  margin-right: auto !important;
}

.login-form .form-input:focus {
  /* border-color: #007bff; */
  /* box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25); */
}

.form-group {
  margin-bottom: 20px;
}

.form-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.login-button {
  font-weight: bold;
  /* background-color: #007bff;
  border-color: #007bff; */
  
}

.login-button:hover {
  /* background-color: #0056b3;
  border-color: #0056b3; */
}

.error-message {
  color: #ff5252 !important;
  margin-top: 5px;
  font-size: 12px;
}

.toggle-password {
  position: absolute;
  top: 9px;
  z-index: 1000;
  cursor: pointer;
  font-size: 17px;
}

.toggle-rtl {
  left: 9px;
}

.toggle-ltr {
  right: 9px;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: transparent !important;
  border-bottom: none !important;
}

.card-body {
  padding: 10px;
}

.background-image {
  background-size: cover;
  background-position: center;
  background-image: url('@/assets/auth-bg.png');
  height: 100vh !important
}
.login-title{
  color: #181C32 !important;
  font-size: 1.5rem !important;
}
.login-parag{
  color: #A1A5B7;
  font-size: 1rem !important;
  margin-bottom: 0.5rem;
}
.col-form-label{
  margin-bottom: 0.5rem !important;
  font-size: 1.05rem !important;
  font-weight: 500 !important;
  color: #3F4254 !important;
} 
.col-form-label:after {
    content: "*";
    position: relative;
    font-size: inherit;
    color: var(--bs-danger);
    padding-right: 0.25rem;
    font-weight: 600;
}
.required-star {
    color: red;
}
.forget-password{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem !important;
}
.forget-password a{
  transition: color 0.2s ease;
  text-decoration: none;
  color: #15364d !important;
  font-size: 1rem !important;
  font-weight: 500;
}
.forget-password a:hover{
  color: #2a71a3 !important;
}
.login-footer{
  margin-top: 10px;
  font-size: 12px
}
.text-gray-400{
    color:#B5B5C3;
}
.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width)* 2));
  padding: .366rem .75rem;
  font-size: 0.875rem;
  border-radius: 0.425rem !important;
}
.form-control:focus {
    color: #5E6278;
    border-color: #B5B5C3;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(#000, 0.25) !important;
}
.form-control.is-invalid:focus {
    border-color: var(--bs-form-invalid-border-color) !important;
    box-shadow: none !important;
}
.form-control.is-invalid{
    padding-right: 0.75rem !important;
}
.form-control{
  color: #5E6278;
  background-color: transparent;
}

@media (min-width: 1200px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 100%;
        height: 100vh;
        overflow: hidden
    }
}
.toast-rtl {
  direction: rtl;
  text-align: right; /* محاذاة النص لليمين في اللغة العربية */
}

.toast-ltr {
  direction: ltr;
  text-align: left; /* محاذاة النص لليسار في اللغة الإنجليزية */
}
</style>

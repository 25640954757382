import Vue from 'vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueI18n from 'vue-i18n'
import enMessages from './locales/en.json'
import arMessages from './locales/ar.json'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap-icons/font/bootstrap-icons.css';

import 'izitoast/dist/css/iziToast.min.css';
import iziToast from 'izitoast';

import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';

import ToggleButton from 'vue-js-toggle-button';
// import 'vue-js-toggle-button/dist/css/index.css';

import App from './App.vue'
import { createRouter } from './router'
import '../src/styles/main.css'


const messages = {
  en: enMessages,
  ar: arMessages
}

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueGoodTablePlugin);
Vue.use(ToggleButton);
Vue.use(VueI18n)

Vue.config.productionTip = false;

Vue.prototype.$izitoast = iziToast;


store.dispatch('loadUser');


const i18n = new VueI18n({
  locale: 'ar', // اللغة الافتراضية
  fallbackLocale: 'en', // اللغة البديلة
  messages: {
    en: enMessages,
    ar: arMessages
  }
})


const vueRouter = createRouter(i18n);

vueRouter.beforeEach((to, from, next) => {
  const navbarVerticalNavMenu = document.getElementById('navbarVerticalNavMenu');
  const sidebarList = document.getElementById('sidebarList');
  const sidebarX = document.getElementById('sidebarX');
  if (navbarVerticalNavMenu && navbarVerticalNavMenu.classList.contains('show')) {
    navbarVerticalNavMenu.classList.remove('show');
    sidebarX.style.display= 'none';
    sidebarList.style.display= 'block';


  }
  next();
});

new Vue({
  router: vueRouter,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

export { i18n };
